import { Model } from '@vuex-orm/core'

export default class ResearchData extends Model {
  static entity = 'researchdatas'

  static fields () {
    return {
      id: this.uid(),
      title: this.string(''),
      icon: this.string(''),
      type: this.string(''),
      security_level: this.string(''),
      status: this.string('locked'),
      description: this.string(''),
    }

  }
}
