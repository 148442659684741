<template>
  <div class="comm desktop:display-flex">
    <div class="wrap-key-panel desktop:margin-right-xs margin-bottom-xs">
      <div class="key-panel with-border with-border-radius padding-xs" style="background-color: var(--black);">
        <div class="inner-key-panel" style="padding-left: 2px;text-align: center; display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 5px;">

          <input v-model="textCode" type="text" style="grid-column: span 3;" class="code-input" maxlength="10" />
          <span class="button" @click="inputValue(1)">1</span>
          <span class="button" @click="inputValue(2)">2</span>
          <span class="button" @click="inputValue(3)">3</span>
          <span class="button" @click="inputValue(4)">4</span>
          <span class="button" @click="inputValue(5)">5</span>
          <span class="button" @click="inputValue(6)">6</span>
          <span class="button" @click="inputValue(7)">7</span>
          <span class="button" @click="inputValue(8)">8</span>
          <span class="button" @click="inputValue(9)">9</span>
          <span class="button" @click="inputValue('C')">C</span>
          <span class="button" @click="inputValue(0)">0</span>
          <span class="button blue" @click="inputValue('#')"><i class="far fa-blender-phone"></i></span>
        </div>
      </div>
    </div>

    <div class="wrap-comm-screen">
      <div class="comm-screen with-border with-border-radius padding-md">
        <div class="output with-border with-border-radius padding-sm text-left">
          <div class="inner-key-panel">

            <div v-for="message in messages" :key="message.id">
              <span v-html="message.text"></span>
            </div>
          </div>

        </div>

        <button @click.prevent="choose('A')" style="margin-top: 5px;" class="small">A</button>
        <button @click.prevent="choose('B')" style="margin-top: 5px;" class="small">B</button>
        <button @click.prevent="choose('C')" style="margin-top: 5px;" class="small">C</button>

      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { getMessagesByType, addCommMessage, clearMessages } from '../utils/message'
import ComMessage from "../store/models/ComMessage"
import ComMessageChoice from '../store/models/ComMessageChoice'

const CONNECTING = '0'
const CONNECTED = '1'
const SHOW_MESSAGE = '2'
const SHOW_CHOICES = '3'
const WAITING_INPUT = 'W'

export default {
  name: "KeyPad",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      eventTimer: '',
      textCode: '',
      connectionStatus: CONNECTING
    }
  },

  computed: {
    messages () {
      return getMessagesByType('comm')
    },

    selectedComMessage () {
      const comMessage = ComMessage.query().with('choices').where((com) => {
          return com.code == this.textCode && com.max_occurrences > com.occurrence_count
        }).first()

      return comMessage
    }
  },

  methods: {
    choose (value) {
      if (this.connectionStatus == WAITING_INPUT) {
        const choice = ComMessageChoice.query().where((c) => {
          return (c.commessage_id == this.selectedComMessage.id && c.selector == value)
        }).first()

        this.connectionStatus = CONNECTING
        addCommMessage("&nbsp;")
        addCommMessage(choice.result_text)
        this.getComMessageReward(choice)

        ComMessage.update({
          where: this.selectedComMessage.id,
          data: {
            occurrence_count: this.selectedComMessage.occurrence_count + 1
          }
        })
      }
    },

    inputValue (value) {
      switch (value) {
        case "C":
          this.reset()
          break

        case "#":
          clearMessages()
          this.connectionStatus = CONNECTING
          addCommMessage(`OPENING CHANNEL ${this.textCode}...`)
          this.eventTimer = setInterval(this.commEvent, 1000)
          break

        default:
          if (this.textCode.length < 10) {
            this.textCode = `${this.textCode}${value}`
          }
      }
    },

    commEvent () {
      if (this.selectedComMessage == null) {
        addCommMessage('COMMUNICATION FAILED.')
        clearInterval(this.eventTimer)
      } else {
        switch (this.connectionStatus) {
          case CONNECTING:
            addCommMessage(`CONNECTION ESTABLISHED.`)
            this.connectionStatus = CONNECTED
            break

          case CONNECTED:
            clearMessages()
            addCommMessage(this.selectedComMessage.welcome_message)
            this.connectionStatus = SHOW_MESSAGE
            break

          case SHOW_MESSAGE:
            addCommMessage(this.selectedComMessage.message)
            this.connectionStatus = SHOW_CHOICES
            break
          case SHOW_CHOICES:
            addCommMessage("&nbsp;")
            this.selectedComMessage.choices.forEach(choice => {
              addCommMessage(`&nbsp;&nbsp;${choice.selector}. ${choice.text}`)
            })

            clearInterval(this.eventTimer)
            this.connectionStatus = WAITING_INPUT
            break
        }
      }
    },

    reset () {
      clearMessages()
      this.textCode = ''
      clearInterval(this.eventTimer)
      this.connectionStatus = CONNECTING
    }

  }
}
</script>
