<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="wind-warning" />
        Air Lock
      </h3>

      <div>
        Approximately {{ daysRemaining }} long days remain until you arrive on <span class="planet-name">{{ this.currentGame.destination_planet }}</span>.
        One could lose their mind staring into the endless abyss of space. They might even come unhinged and do something drastic, like throw a whiny crewmate out of the airlock.
      </div>

      <div v-if="!airlockOperational" class="lose-message">
        Karabast! The airlock seems to be malfunctioning. You should ask your mechanic to repair it.
      </div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">
          <h3 class="supply-heading">Options</h3>
          <ul v-if="airlockOperational" class="fa-ul">
            <li v-for="crew in crewMembers" :key="crew.id">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="throwCrewOut(crew.id)">Throw {{crew.name}} out the airlock</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { unlockAchievement } from '../../utils/achievements'
import { airlockCrewMember } from '../../utils/airlock'
import { getCrewMemberById, getLivingCrewMembers } from '../../utils/crew'
import { updateGame } from '../../utils/game'

import { addMessage, clearMessages } from '../../utils/message'
import { formatCrewPhrase, getRandomPhrase } from '../../utils/phrases'
import settings from '../../utils/settings'

export default {
  name: "Airlock",

  mixins: [
    GameMixin
  ],

  computed: {
    daysRemaining () {
      return Math.floor(this.travelDaysRemaining)
    },

    crewMembers () {
      return getLivingCrewMembers(0, this.currentShip)
    },

    airlockOperational () {
      return this.currentShip.airlock_status == settings.AIRLOCK_STATUS_NORMAL
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    throwCrewOut (crewId) {
      clearMessages()

      const crew = getCrewMemberById(crewId)

      if (crew.name == 'HAL9000') {
        addMessage("HAL9000 says <span class='crew-speech'>\"I am afraid you can't do that Dave.\"</span>")
        updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
      } else if (crew.status == settings.STATUS_RAMPAGE) {
        addMessage(`${crew.name} looks really angry... you better throw someone else out the airlock.`, 'lose-message')
      } else {
        airlockCrewMember(this.currentGame, crew)

        if (crew.species == settings.SPECIES_ROBOT) {
          addMessage(formatCrewPhrase(crew, 'This is going to void my warranty!'))
        }

        unlockAchievement("AIRLOCk")
        const airlockPhrase = getRandomPhrase('airlock').text
        addMessage(`You throw ${crew.name} out the airlock. ${airlockPhrase}`, 'lose-message')
      }
    }
  }
}
</script>
