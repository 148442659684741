import { Model } from '@vuex-orm/core'

export default class MazeRoom extends Model {
  static entity = 'mazerooms'

  static fields () {
    return {
      id: this.string(''),
      name: this.string(''),
      description: this.string(''),
      north: this.string(''),
      east: this.string(''),
      south: this.string(''),
      west: this.string(''),
      item: this.string(''),
      itemName: this.string(''),
      monster: this.string(''),
      action: this.string('')
    }
  }
}
