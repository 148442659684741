<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="tv" />
        Bridge
      </h3>

      <div v-if="displayDaysRemaining">There are approximately <span class="game-info">{{ daysRemaining }} days</span> left in your journey.</div>

      <div v-if="crewNavigator && crewPilot">
        Pilot {{ crewPilot.name}} and Navigator {{ crewNavigator.name }} are standing by awaiting your orders.
      </div>
      <div v-else-if="crewNavigator">
        Navigator {{ crewNavigator.name }} is standing by awaiting your orders.
      </div>
      <div v-else-if="crewPilot">
        Navigator {{ crewPilot.name }} is standing by awaiting your orders.
      </div>

      <br>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('navigationterminal')">Access Navigation Terminal</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('communicator')">Use Communicator</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('adjustspeed')">Change Speed</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('secureddoor')">Examine the Secured Door</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { getCrewMemberByPosition } from '../../../utils/crew'
import settings from '../../../utils/settings'
import { getRandomCaptainGreeting } from '../../../utils/phrases'
import { addMessage } from '../../../utils/message'

export default {
  name: "Bridge",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewNavigator && this.crewPilot) {
      if (Math.floor(Math.random() * 100) > 50) {
        addMessage(getRandomCaptainGreeting(this.crewNavigator), '')
      } else {
        addMessage(getRandomCaptainGreeting(this.crewPilot), '')
      }
    } else {
      if (this.crewNavigator) {
        addMessage(getRandomCaptainGreeting(this.crewNavigator), '')
      }
      if (this.crewPilot) {
        addMessage(getRandomCaptainGreeting(this.crewPilot), '')
      }
    }
  },

  computed: {
    crewPilot () {
      return getCrewMemberByPosition('Pilot', this.currentShip)
    },

    crewNavigator () {
      return getCrewMemberByPosition('Navigator', this.currentShip)
    },

    daysRemaining () {
      return Math.floor(this.travelDaysRemaining)
    },

    displayDaysRemaining () {
      return this.currentGame.difficulty != settings.GAME_DIFFICULTY_FOREVER
    },
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    }

  }
}
</script>
