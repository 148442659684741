<template>
  <div class="crew crew-bio size-sm with-border with-border-radius with-close margin-bottom-sm padding-sm">
    <div class="row align-middle">
      <crew-icon :crew="activeCrewMember" />
      <div>
        <h3 :class="activeCrewMember.status" class="event-title">{{activeCrewMember.name}}</h3>
        {{ title }}
      </div>
    </div>

    <h4 class="skill-header with-hr">
      Status
    </h4>
    <health-bar
      :percentage="activeCrewMember.health"
      :status="activeCrewMember.status"
      :drunkenness="activeCrewMember.drunkenness"
      :morale="activeCrewMember.morale"
      :has_disease="activeCrewMember.has_disease"
    />

    <skills-list :crew="activeCrewMember" />

    <h4 class="skill-header with-hr">Details</h4>
    <div class="skill"><i class="fas fa-dna"></i> Species:</div>
    <div class="">{{ activeCrewMember.species }}</div>
    <br>
    <div class="skill"><i class="fad fa-planet-ringed"></i> Home Planet:</div>
    <div class="">{{ activeCrewMember.home_planet }}</div>
    <br>
    <div class="skill"><i class="fad fa-fork-knife"></i> Favorite Food:</div>
    <div class="">{{ activeCrewMember.favorite_food }}</div>

    <button @click="closeCrewBio()" class="close" aria-label="close">
      <i class="fas fa-circle-xmark size-xl"></i>
    </button>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import HealthBar from './HealthBar.vue'
import SkillsList from './SkillsList.vue'
import CrewIcon from './Ship/Components/CrewIcon.vue'

export default {
  name: "CrewBio",

  mixins: [
    GameMixin
  ],

  components: {
    HealthBar,
    SkillsList,
    CrewIcon
  },

  props: {
    activeCrewMember: {}
  },

  computed: {
    title () {
      return this.activeCrewMember.title ? this.activeCrewMember.title : this.activeCrewMember.position
    }
  },

  methods: {

    closeCrewBio () {
      this.displayCrew(0)
    }
  }
}
</script>
