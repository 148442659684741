
import Disease from '../store/models/Disease'
import { valueLimit, getRandomRecord } from '../utils/helpers'
import settings from './settings'

export function getRandomDisease() {
  const diseases = Disease.query().where(d => {
    return d.special == false
  }).all()
  return getRandomRecord(diseases)
}

export function getDiseaseByName(name) {
  const disease = Disease.query().where((d) => {
    return d.name == name
  }).first()

  return disease
}

export function getDiseaseById(id) {
  const disease = Disease.query().where((d) => {
    return d.id == id
  }).first()

  return disease
}

export function getDiseases(includeSpecial=false) {
  if (includeSpecial) {
    return Disease.query().orderBy('name').all()
  } else {
    return Disease.query().where(d => {
      return d.special == false
    }).orderBy('name').all()
  }
}

export function getIntialHealthLossFromDisease (crew, difficulty) {
  let loss = Math.floor(Math.random() * 20) + difficulty
  let health = crew.health - loss

  if (health <= 0) { health = 5 } // Crew member cannot die from the intial damage of a disease

  return valueLimit(health)
}

export function getHealthLossFromDisease (crew) {
  // TODO: Update to change amount based on disease and hunger/health level
  const loss = Math.floor(Math.random() * 10)
  let health = crew.health - loss
  if (health <= 0) { health = 5 } // You cannot die from a disease

  return valueLimit(health)
}

export function checkDiseaseFatality (crew, game) {
  const chance = Math.floor(Math.random() * 100)
  return (chance < calculateDiseaseFatalityRate(crew.disease, game))
}

export function calculateDiseaseFatalityRate (disease, game) {
  switch (game.difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return disease.fatality_rate * settings.DISEASE_FATALITY_RATE_MULTIPLIER_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return disease.fatality_rate * settings.DISEASE_FATALITY_RATE_MULTIPLIER_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return disease.fatality_rate * settings.DISEASE_FATALITY_RATE_MULTIPLIER_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return disease.fatality_rate * settings.DISEASE_FATALITY_RATE_MULTIPLIER_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return disease.fatality_rate * settings.DISEASE_FATALITY_RATE_MULTIPLIER_FOREVER
  }
}

export function passInfectionCalculation (infectedCrew) {
  if (infectedCrew.has_disease) {
    const disease = getDiseaseById(infectedCrew.disease_id)

    if (disease.infection_rate > 0) {
      // Positive morale will lower infection rate and negative morale will increase infection rate
      const infectionRate = disease.infection_rate - infectedCrew.morale

      //console.log(infectedCrew.name + " infection rate at " + infectionRate)
      if (Math.random() * 100 < infectionRate) {
        return true
      }
    }
  }
  return false
}

export function infectionRateSeverity (infectionRate) {
  if (infectionRate < 8) { return 'Very Low' }
  else if (infectionRate < 15) { return 'Low' }
  else if (infectionRate < 25) { return 'Moderate' }
  else if (infectionRate < 35) { return 'High' }
  else if (infectionRate < 60) { return 'Very High' }
  else if (infectionRate <= 100) { return 'Extreme' }
  return 'Unknown'
}