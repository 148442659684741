import { Model } from '@vuex-orm/core'

export default class Enemy extends Model {
  static entity = 'enemies'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      icon: this.string(''),
      challenge_rating: this.number(0), // 1 - 30
      xp: this.number(0),
      description: this.string(''),
    }

  }
}
