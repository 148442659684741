import { crewDeath } from "./crew";
import { updateGame } from "./game";

export function airlockCrewMember (game, crew, increaseAirlockCount = true) {
  crewDeath(crew, game)

  const airlockCount = game.crew_airlock_count + (increaseAirlockCount ? 1 : 0)

  updateGame(game, {
    airlock_animation: true,
    crew_airlock_count: airlockCount
  })
}