import MazeRoom from '../store/models/MazeRoom'

const EMPTY_SPACE = '&block;'
const LINE_HORIZONTAL = '&#9473;'
const LINE_VERTICAL = '&#9475;'
const CORNER_NORTH_WEST = '&#9487;'
const CORNER_NORTH_EAST = '&#9491;'
const CORNER_SOUTH_WEST = '&#9495;'
const CORNER_SOUTH_EAST = '&#9499;'


export function getMazeRoomById(id) {
  return MazeRoom.query().whereId(id).first()
}

export function updateMazeRoom(mazeRoom, data) {
  return MazeRoom.update({
    where: mazeRoom.id,
    data: data
  })
}

export function drawRoom (roomWidth, roomHeight, northDoor, eastDoor, southDoor, westDoor, item, monster) {
  const room = []

  for (let row = 0;  row <= roomHeight; row++) {
    const lines = []
    for (let cell = 0;  cell <= roomWidth; cell++) {

      if (row == 0) { // North Wall
        if (cell == 0) {
          lines.push(CORNER_NORTH_WEST)
        } else if (cell == roomWidth) {
          lines.push(CORNER_NORTH_EAST)
        } else {
          if (northDoor && cell == Math.ceil(roomWidth / 2)) {
            lines.push(EMPTY_SPACE)
          } else {
            lines.push(LINE_HORIZONTAL)
          }
        }
      } else if (row == roomHeight) { // South Wall
        if (cell == 0) {
          lines.push(CORNER_SOUTH_WEST)
        } else if (cell == roomWidth) {
          lines.push(CORNER_SOUTH_EAST)
        } else {
          if (southDoor && cell == Math.ceil(roomWidth / 2)) {
            lines.push(EMPTY_SPACE)
          } else {
            lines.push(LINE_HORIZONTAL)
          }
        }
      } else {
        if (cell == 0) { //West Wall
          if (westDoor && row == Math.ceil(roomHeight / 2)) {
            lines.push(EMPTY_SPACE)
          } else {
            lines.push(LINE_VERTICAL)
          }
        } else if (cell == roomWidth) { // East Wall
          if (eastDoor && row == Math.ceil(roomHeight / 2)) {
            lines.push(EMPTY_SPACE)
          } else {
            lines.push(LINE_VERTICAL)
          }
        } else { // Room Floor
          if (item != '' && row == Math.ceil(roomHeight / 2) && cell == Math.ceil(roomWidth / 2)) {
            lines.push('item')
          } else if (monster != '' && row == Math.ceil(roomHeight / 2) && cell == Math.ceil(roomWidth / 2)) {
            lines.push('monster')
          } else {
            lines.push(EMPTY_SPACE)
          }
        }
      }
    }
    room.push({row: lines })
  }

  return room
}