<template>
<div class="">
  <div class="console with-border with-border-radius">
    <h3 class="event-title">
      <font-awesome-icon icon="robot" />
      Crew replacement Droids
    </h3>

    <p>
      Dozens of broken droids, spare parts, and piles of junk cover the floor of this small shop.
    </p>

    <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
      <span v-html="message.text"></span>
    </div>

    <div class="choices">
      <ul class="fa-ul" v-if="hasMissingCrew && budget">
        <li v-for="crewType in missingCrewTypes" :key="crewType">
          <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="hire(crewType, 300)">Budget {{ crewType }} droid [300 credits]</a>
        </li>
        <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="toggleBudgetModels()">What a piece of junk! Do you have anything better?</a></li>
      </ul>
      <ul class="fa-ul" v-else-if="hasMissingCrew && !budget">
        <li v-for="crewType in missingCrewTypes" :key="crewType">
          <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="hire(crewType, 500)">{{ crewType }} droid [500 credits]</a>
        </li>
        <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="toggleBudgetModels()">Do you have anything cheaper?</a></li>
      </ul>

      <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>
    </div>
  </div>
</div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import Crew from '../../store/models/Crew'
import { createCrewReplacement, createBudgetCrewReplacement, getMissingCrewTypes, updateCrew, hasFullCrew } from '../../utils/crew'
import { clearMessages, addMessage } from '../../utils/message'
import { getRandomMerchantGreeting, formatNPCPhrase, getRandomPhrase } from '../../utils/phrases'
import { updateShip } from '../../utils/ship'

export default {
  name: "HireCrew",

  mixins: [
    GameMixin
  ],

  data () {
    return {
      position: 'none',
      budget: false,
      crewId: 0,
    }
  },

  created() {
    if (hasFullCrew(this.currentShip)) {
      addMessage(formatNPCPhrase ('The merchant', getRandomPhrase('merchant-fullcrew').text), '')
    } else {
      addMessage(getRandomMerchantGreeting('The merchant'), '')
    }
  },

  computed: {
    missingCrewTypes () {
      const position = getMissingCrewTypes(this.currentShip)
      return position
    },

    hasMissingCrew () {
      return (this.missingCrewTypes.length > 0)
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    toggleBudgetModels () {
      clearMessages()
      this.budget = !this.budget

      if (this.budget) {
        addMessage(formatNPCPhrase('The merchant', 'I got something that will fit your budget...'), '')
      } else {
        addMessage(formatNPCPhrase('The merchant', 'Looking for quality huh... I got just the deal for you.'), '')
      }
    },

    hire (position, cost) {
      clearMessages()

      if (this.currentShip.credits >= cost) {
        const oldCrew = Crew.query().where((crew) => {
          return crew.position == position && crew.ship_id == this.currentShip.id
        }).first()

        if (oldCrew) {
          updateCrew(oldCrew, { ship_id: 0 })
        }

        if (this.budget) {
          createBudgetCrewReplacement(position, this.currentShip)
        } else {
          createCrewReplacement(position, this.currentShip)
        }

        const credits = this.calculateCredits(this.currentShip.credits, -cost)
        updateShip(this.currentShip, { credits: credits })

      } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    }
  }
}
</script>
