<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 v-if='this.currentGame.secured_door_open == true' class="event-title">
        <font-awesome-icon icon="lock-keyhole-open" />
        Unsecured Door
      </h3>
      <h3 v-else class="event-title">
        <font-awesome-icon icon="lock-keyhole" />
        Secured Door
      </h3>

      <door-pad></door-pad>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-if='this.currentGame.secured_door_open == false'><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="hack()">Try hacking the door panel</a></li>
            <li v-if='this.currentGame.secured_door_open == true'><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('secretroom')">Enter Secure Room</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('bridge')">Return to Bridge</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoorPad from '../../../components/DoorPad.vue'
import GameMixin from '../../../mixins/GameMixin'
import { addCommMessage, addMessage } from '../../../utils/message'
import { getRandomPhrase } from '../../../utils/phrases'

export default {
  name: "SecuredDoor",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      step: 0,
      eventTimer: '',
    }
  },

  components: {
    DoorPad
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    hack () {
      clearInterval(this.eventTimer)
      this.clearMessages()
      addMessage(getRandomPhrase('door-hack').text, 'success-message')
      addCommMessage(getRandomPhrase('hack-code').text)
      addCommMessage(' ')
      this.eventTimer = setInterval(this.showResultEvent, 1000)
    },

    showResultEvent () {
      switch (this.step) {
        case 0:
          addCommMessage(getRandomPhrase('hack-result').text)
          this.step = 1
          break
        case 1:
          addCommMessage(getRandomPhrase('hack-detected').text)
          this.step = 2
          break
        case 2:
          if (this.currentGame.secured_door_open == true) {
            addCommMessage('DOOR STATUS: <i class="fa fa-door-open"></i> ')
          } else {
            addCommMessage('DOOR STATUS: <i class="fa fa-door-closed"></i>')
          }

          this.step = 0
          clearInterval(this.eventTimer)
          break
      }
    }
  }
}
</script>
