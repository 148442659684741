import { Model } from '@vuex-orm/core'

export default class MinorEvent extends Model {
  static entity = 'minorevents'

  static fields () {
    return {
      id: this.uid(),
      description: this.string(''),
      event_type: this.string(''),
      special: this.string(''),
      crew_type: this.string(''),
      probability: this.number(0),

      fuel_cost: this.number(),
      hull_cost: this.number(),
      weapons_cost: this.number(),
      engines_cost: this.number(),
      health_cost: this.number(),
      credit_cost: this.number(),
      morale_cost: this.number(),
      repair_kits_cost: this.number(),
      med_kits_cost: this.number(),
      food_cost: this.number(),
    }
  }
}
