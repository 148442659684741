<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="people-arrows-left-right" />
        Convert Robot to Repair Kit
      </h3>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-for="crew in crewMembers" :key="crew.id">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="scrapRobotCrewMember(crew.id)">Scrap {{crew.name}}</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('lab')">Return to the Science Lab</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { crewDeath, getCrewMemberById, getCrewMemberByPosition, getCrewMembersBySpecies, updateCrewHealth } from '../../../utils/crew'
import settings from '../../../utils/settings'
import { addMessage } from '../../../utils/message'
import { robotScrapBonusSucessRate, robotScrapSucessRate } from '../../../utils/science'
import { updateShip } from '../../../utils/ship'
import { formatCrewPhrase, getRandomChatByType } from '../../../utils/phrases'

export default {
  name: "ConvertRobot",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewScientist) {
      addMessage(getRandomChatByType(this.crewScientist, 'robot-disassemble'), '')
    }
  },

  computed: {
    crewScientist () {
      return getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
    },

    crewMembers () {
      return getCrewMembersBySpecies(this.currentShip, settings.SPECIES_ROBOT)
    },

    successRate () {
      return robotScrapSucessRate(this.crewScientist)
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    scrapRobotCrewMember (crewId) {
      this.clearMessages()

      if (crewId == this.crewScientist.id) {
        addMessage(formatCrewPhrase(this.crewScientist, 'NO DISASSEMBLE!'), '')
      } else {
        const crew = getCrewMemberById(crewId)
        const success = (Math.floor(Math.random() * 100) <= this.successRate)

        if (success) {
          const erbiumGained = (Math.floor(Math.random() * 100) <= robotScrapBonusSucessRate(this.crewScientist) ? 1 : 0)
          const kitsGained = Math.floor(Math.random() * 3) + 1

          crewDeath(crew, this.currentGame)

          updateShip(this.currentShip, {
            repair_kits: this.currentShip.repair_kits + kitsGained,
            erbium_crystals: this.currentShip.erbium_crystals + erbiumGained
          })

          addMessage(`${this.crewScientist.name} turns ${crew.name} to scrap metal.`, 'gain-message')
          addMessage(`You gain ${kitsGained} repair kits.`, 'gain-message')

          if (erbiumGained > 0) {
            addMessage(`You gain 1 erbium crystal!`, 'gain-message')
          }
        } else {
          addMessage(`${crew.name} refuses to be turned to scrap metal and punches ${this.crewScientist.name}!`, 'lose-message')
          const result = updateCrewHealth(this.crewScientist, -(Math.floor(Math.random() * 10) + 10))
          addMessage(result, 'lose-message')

          if (this.crewScientist.health == 0) {
            addMessage(`${this.crewScientist.name} died.`, 'gain-message')
            crewDeath(this.crewScientist, this.currentGame)
          }
        }
      }
    }
  }
}
</script>
