<template>
  <div class="resource" >
    <h4 class="resource-label size-sm tooltip"  style="display: flex; justify-content: space-between">
      Health
      <span>
        <font-awesome-icon title="Drunk" icon="face-woozy" v-if="isDrunk" style="font-size: 16px;color: var(--orange); padding-right: 4px;" />
        <font-awesome-icon title="Diseased" icon="virus" v-if="has_disease" style="font-size: 16px;color: var(--red); padding-right: 4px;" />
        <font-awesome-icon title="Panic" icon="face-zany" v-if="isPanic" style="font-size: 16px;color: var(--red); padding-right: 4px;" class="fa-shake" />

        <span class="tooltip">
          <font-awesome-icon title="Morale" :icon="this.moraleIcon" style="font-size: 16px;" />
          <span class="tooltiptext tooltip-top">{{ moraleToolTip }}</span>
        </span>

      </span>

    </h4>
    <div class="bar with-border with-border-radius">
      <div class="bar-amount with-border-radius" :style="barWidth">
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import settings from '../utils/settings'
import { getMoraleDescription } from '../utils/morale'

export default {
  name: "HealthBar",

  mixins: [
    GameMixin
  ],

  props: {
    percentage: Number,
    morale: Number,
    drunkenness: Number,
    status: String,
    has_disease: Boolean
  },

  computed: {
    barWidth () {
      if (this.has_disease) {
        return `width:${this.percentage}%;background-color:var(--yellow);`
      }
      else {
        return `width:${this.percentage}%;background-color:var(--purple);`
      }
    },

    isPanic () {
      return (this.status == settings.STATUS_PANIC)
    },

    isDrunk () {
      return (this.drunkenness >= settings.DRUNKENNESS_LEVEL_DRUNK )
    },

    moraleToolTip () {
      if (this.status == 'dead') {
        return `Morale: unknown`
      } else {
        return `Morale: ${getMoraleDescription(this.morale)} `
      }
    },

    moraleIcon () {
      if (this.status == 'dead') {
        return 'face-dizzy'
      }

      switch (true) {
        case this.morale < -4:
          return 'face-tired'
        case this.morale < 0:
          return 'face-frown'
        case this.morale > 4:
          return 'face-laugh-beam'
        case this.morale > 0:
          return 'face-smile'
        default:
          return 'face-meh'
      }
    }
  }
}
</script>
