import { Model } from '@vuex-orm/core'
import Choice from './Choice'

export default class Result extends Model {
  static entity = 'results'

  static fields () {
    return {
      id: this.uid(),
      result_text: this.string(''), // displayed when this result is given
      probability: this.number(), // A number between 1 and 100d
      result_type: this.string(), // Success or Failure
      xp_gained: this.number(),
      choice_id: this.attr(null),
      choice: this.belongsTo(Choice, 'choice_id'),
      fuel_cost: this.number(),
      hull_cost: this.number(),
      weapons_cost: this.number(),
      engines_cost: this.number(),
      health_cost: this.number(),
      credit_cost: this.number(),
      morale_cost: this.number(),
      repair_kits_cost: this.number(),
      med_kits_cost: this.number(),
      food_cost: this.number(),
      erbiumcrystal_cost: this.number(),
      alien_artifact_cost: this.number(),
      distance_cost: this.number(),
      style: this.string(''),
      special: this.string(''),
      //followup_event: this.belongsTo(Event, 'followup_event_id'),
    }

  }
}
