<template>
  <div class="console game-win with-border with-border-radius text-center">
    <i
      class="far fa-trophy-star fa-4x margin-y-2xs"
      style="--fa-primary-color: var(--yellow); --fa-secondary-color: var(--orange); --fa-secondary-opacity: 1"
    ></i>
    <h1 class="margin-top-0 padding-top-md">A Winner is You!</h1>

    <p
      class="padding-xs"
      style="color: white"
    >
      {{ currentGame.game_over_text }}
    </p>

    <game-stats />
    <achievements />

    <button
      @click.prevent="gotoRoom('gamecredits')"
      class="white margin-right-md"
    >
      Credits
    </button>
    <button
      @click="playAgain()"
      class="green"
    >
      Play Again <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</template>

<script>
  import Achievements from '../../components/Achievements.vue'
  import GameStats from '../../components/GameStats.vue'
  import GameMixin from '../../mixins/GameMixin'
  import { createNewGame } from '../../utils/game'

  export default {
    components: { GameStats, Achievements },
    name: 'GameWin',

    mixins: [GameMixin],

    created() {
      this.$plausible.trackEvent('game-win')
    },

    methods: {
      playAgain() {
        this.$plausible.trackEvent('play-again')
        createNewGame(this.currentShip)
      },

      gotoRoom(room) {
        this.clearMessages()
        this.changeDisplay(room)
      }
    }
  }
</script>
