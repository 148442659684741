<template>
  <div class="mission-status with-border with-border-radius margin-bottom-sm padding-sm">
    <div class="with-hr laptop:display-flex flex-content-center text-center">
      <div class="padding-3xs margin-right-sm">
        <i class="fad fa-solar-system fa-3x solar-system fa-spin" style="--fa-secondary-color: grey;"></i>
      </div>

      <div class="destination">
        <h3 class="label size-xs margin-bottom-0">Destination:</h3>
        <p class="planet-name size-lg desktop:size-2xl margin-top-0">
          <span v-if="this.showTravelDistance">Unknown</span>
          <span v-else>{{ currentGame.destination_planet }}</span>
        </p>
      </div>
    </div>

    <div class="">
      <div class="mission-status-data text-center">
        <div v-if="this.showTravelDistance">
          <h4 class="label">Distance traveled:</h4>
          <p class="stat">{{ distanceTraveled }} parsecs</p>
        </div>
        <div v-else>
          <h4 class="label margin-top-xs margin-bottom-4xs">Distance remaining:</h4>
          <p class="stat size-lg margin-top-0">{{ distanceRemaining }} parsecs</p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="stat size-sm text-center">
        <span class="">Date:</span> {{  starDate }}</div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { currentStarDate } from '../utils/game'
import settings from '../utils/settings'

export default {
  name: "MissionStatus",

  mixins: [
    GameMixin
  ],

  computed: {
    starDate () {
      return currentStarDate(this.currentGame)
    },

    showTravelDistance () {
      return this.currentGame.difficulty == settings.GAME_DIFFICULTY_FOREVER
    },

    distanceTraveled () {
      return this.currentGame.distance_traveled.toLocaleString()
    },

    distanceRemaining () {
      return this.currentGame.distance_to_planet.toLocaleString()
    }
  }
}
</script>
