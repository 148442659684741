import resolveToString from 'es6-template-strings'
import MinorEvent from '../store/models/MinorEvent'
import settings from './settings'

import { getCrewMemberByPosition, getRandomCrewMember, updateCrew, setCrewDisease } from './crew'
import { updateGame } from './game'
import { getRandomName, getRandomRecord } from './helpers'
import { updateShip } from './ship'
import { addMessage } from './message'
import { getDiseaseByName, getRandomDisease } from './disease'


export function getRandomMinorEvent (eventType) {
  const chance = Math.floor(Math.random() * 100)

  const minorEvents = MinorEvent.query().where((event) => {
    return event.event_type == eventType && event.probability > chance
  }).all()

  return getRandomRecord(minorEvents)
}

export function applyMinorEventEffects (minorEvent, ship, crew, game) {
  const fuel_amount = ship.fuel_amount + minorEvent.fuel_cost
  const engine_status = ship.engine_status + minorEvent.engines_cost
  const hull_status = ship.hull_status + minorEvent.hull_cost
  const weapon_status = ship.weapon_status + minorEvent.weapons_cost
  const credits = ship.credits + minorEvent.credit_cost
  const repair_kits = ship.repair_kits + minorEvent.repair_kits_cost
  const med_kits = ship.med_kits + minorEvent.med_kits_cost
  const food = ship.food + minorEvent.food_cost

  switch (minorEvent.special) {
    case 'purplebutton': {
      updateGame(game, { secret_button_active: true })
      break
    }
    case 'electrogonnorhea': {
      const disease = getDiseaseByName('electrogonnorhea')
      setCrewDisease(crew, disease, 15)
      break
    }
    case 'disease': {
      const disease = getRandomDisease()
      setCrewDisease(crew, disease, 15)
      break
    }
  }

  updateShip(ship, {
    'fuel_amount': fuel_amount,
    'engine_status': engine_status,
    'hull_status': hull_status,
    'weapon_status': weapon_status,
    'credits': credits,
    'repair_kits': repair_kits,
    'med_kits': med_kits,
    'food': food
  })

  if (minorEvent.health_cost != 0) {
    updateCrew(crew, { health: crew.health + minorEvent.health_cost})
  }
}

export function getMinorEventCrewMember (minorEvent, ship) {
  let crewMember = null

  if (minorEvent.crew_type == 'random') {
    crewMember = getRandomCrewMember(ship)
  } else {
    crewMember = getCrewMemberByPosition(minorEvent.crew_type, ship)
  }
  return crewMember
}

export function getMinorEventText (minorEvent, crewMember) {
  const messageTokens = {}
  messageTokens.crew_member = crewMember.name
  messageTokens.ship_part = getRandomName('shippart')
  messageTokens.food = getRandomName('food')
  messageTokens.foods = getRandomName('food', true)

  return resolveToString(minorEvent.description, messageTokens)
}

export function cantinaMinorEvent (game, ship) {
  if (Math.floor(Math.random() * 100) < settings.CANTINA_MINOR_EVENT_CHANCE) {
    const minorEvent = getRandomMinorEvent('cantina')
    const crewMember = getMinorEventCrewMember(minorEvent, ship)

    if (crewMember) {
      applyMinorEventEffects(minorEvent, ship, crewMember, game)
      addMessage(getMinorEventText(minorEvent, crewMember))
    }
  }
}