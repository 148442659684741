import { getCrewMemberByPosition, getSpecialTitleByName, updateCrew } from './crew'
import { getRandomName, getRandomNameExcluding } from './helpers'
import settings from './settings'

export function awesomeCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('awesomename', crewNames)
    crewNames.push(name)
    updateCrew(crew, {
      name: name
    })
  })
}

export function familyCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('family', crewNames)
    crewNames.push(name)
    updateCrew(crew, {
      name: name,
      icon: 'cat-space',
      species: 'Space Cat',
      favorite_food: getRandomName('catfood')
    })
  })
}

export function seinfeldCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('seinfeld', crewNames)
    const title = getSpecialTitleByName(name, crew.title)
    crewNames.push(name)
    updateCrew(crew, {
      name: name,
      favorite_food: getRandomName('seinfeldfood'),
      title: title
    })
  })
}

export function lostCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('lost', crewNames)
    const title = getSpecialTitleByName(name, crew.title)
    const icon = title == 'Dog' ? 'dog' : crew.icon
    const species = title == 'Dog' ? 'Dog' : 'Human'
    crewNames.push(name)

    updateCrew(crew, {
      name: name,
      favorite_food: getRandomName('dharmafood'),
      title: title,
      species: species,
      icon: icon
    })
  })
}

export function bttfCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    if (crew.position == settings.POSITION_SCIENTIST) {
      updateCrew(crew, {
        name: 'Dr. Brown'
      })
    } else {
      const name = getRandomNameExcluding('bttf', crewNames)
      const title = getSpecialTitleByName(name, crew.title)
      const icon = title == 'Dog' ? 'dog' : crew.icon
      const species = title == 'Dog' ? 'Dog' : 'Human'

      crewNames.push(name)

      updateCrew(crew, {
        name: name,
        favorite_food: getRandomName('food'),
        title: title,
        species: species,
        icon: icon
      })
    }
  })
}

export function contraCrew(currentShip) {
  const weaponsCrew = getCrewMemberByPosition(settings.POSITION_WEAPONS, currentShip)

  updateCrew(weaponsCrew, {
    name: getRandomName('konami'),
    icon: 'user-hair-mullet'
  })
}
