import { Model } from '@vuex-orm/core'

export default class GameState extends Model {
  static entity = 'gamestates'

  static fields () {
    return {
      id: this.uid(),
      active: this.boolean(true),
      active_display: this.string('events'),
      current_event_id: this.number(0),
      last_event_id: this.number(0),
      event_resolved: this.boolean(true),
      selected_crew_id: this.string(''),
      distance_traveled: this.number(0),
      destination_planet: this.string(''),
      distance_to_planet: this.number(0),
      outpost_name: this.string(''),
      distance_to_outpost: this.number(0),
      game_over_text: this.string(''),
      difficulty: this.string(''),
      score: this.number(0),
      status: this.string(''), // valid values: start, travel, outpost
      food_ration_level: this.number(0),
      captain_dead: this.boolean(false),

      // Cooldowns
      cook_cooldown: this.number(0),
      doctor_cooldown: this.number(0),
      mechanic_cooldown: this.number(0),
      navigator_cooldown: this.number(0),
      pilot_cooldown: this.number(0),
      scientist_cooldown: this.number(0),
      weapons_cooldown: this.number(0),
      spaceball_cooldown: this.number(0),
      lowfuel_warning_cooldown: this.number(0),
      rampage_cooldown: this.number(0),

      year:  this.number(0), // ordinal date - Year.Day
      day:  this.number(0), // 1 to 366

      // Special Flags
      airlock_animation: this.boolean(false), // show airlock animation if true

      // Secrets
      secret_button_active: this.boolean(false),
      secured_door_open: this.boolean(false),
      special_crates: this.number(0),
      crew_droid_available: this.boolean(true),

      // Stats
      event_count: this.number(0),
      crew_death_count: this.number(0),
      crew_airlock_count: this.number(0),
      secrets_found: this.number(0),
      outposts_visited: this.number(0),
      cheats_unlocked: this.number(0),
    }
  }
}
