<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="microscope" />
        Ebrium Crystal Experiments
      </h3>

      <div v-if="crewScientist">
        Scientist {{ crewScientist.name}} is standing by ready to make science.
      </div>

      <br>
      <div>You have <span class="game-info">{{ this.currentShip.erbium_crystals }}</span> Erbium Crystals.</div>
      <br>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="ErbiumCrystalFuelConversion()">Convert to fuel [1 Erbium Crystal]</a></li>
            <li v-if="this.crewCloningEnabled">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('clonecrew')">Clone a crew member</a>
            </li>

            <li v-if="this.crewScientist.title == ''">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="useErbiumCrystal()">Perform sustained fusion experiment [1 Erbium Crystal]</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('lab')">Return to the Science Lab</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { changeToMadScientist, getCrewMemberByPosition } from '../../../utils/crew'
import settings from '../../../utils/settings'
import { addMessage } from '../../../utils/message'
import { updateShip } from '../../../utils/ship'
import { zeroLimit } from '../../../utils/helpers'
import { getRandomCaptainGreeting, getRandomChatByType } from '../../../utils/phrases'
import { erbiumFuelConversion } from '../../../utils/science'
import { unlockResearchData } from '../../../utils/research'

export default {
  name: "Experiments",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewScientist) {
      if (this.crewScientist.title == settings.TITLE_MADSCIENTIST) {
        addMessage(getRandomChatByType(this.crewScientist, 'madscientist-greeting'), '')
      } else {
        addMessage(getRandomCaptainGreeting(this.crewScientist), '')
      }
    }
  },

  computed: {
    crewScientist () {
      return getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
    },

    crewCloningEnabled () {
      return (this.crewScientist.title == settings.TITLE_MADSCIENTIST)
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    ErbiumCrystalFuelConversion () {
      this.clearMessages()
      if (this.currentShip.erbium_crystals > 0) {
        if (this.currentShip.fuel_amount < 100) {
          erbiumFuelConversion(this.crewScientist, this.currentShip)
        } else {
          addMessage('The fuel cells are full.', 'lose-message')
        }
      } else {
        addMessage('You are out of erbium crystals.', 'lose-message')
      }
    },

    useErbiumCrystal () {
      if (this.currentShip.erbium_crystals > 0) {
        updateShip(this.currentShip, { erbium_crystals: zeroLimit(this.currentShip.erbium_crystals - 1)})
        addMessage(`The erbium crystals become unstable and overloads the equipment. ${this.crewScientist.name} is caught in a wave of strange energy!`)

        unlockResearchData('Sustained Fusion Experiment')
        changeToMadScientist(this.crewScientist)
      } else {
        addMessage('You are out of erbium crystals.', 'lose-message')
      }
    }
  }
}
</script>
