import { get } from 'lodash'
import Ship from '../store/models/Ship'
import { mechanicRepairMultipler } from '../utils/formulas'
import { valueLimit, zeroLimit } from '../utils/helpers'
import { isNumber } from 'lodash'
import settings from './settings'
import { mechanicsCheck } from './skills'
import { checkResourceAchievement } from './achievements'

export async function updateShip(ship, data) {
  // Returns a promise - if you need the updated ship record right away use .then((c) => { return c })
  data.credits = zeroLimit(Number.isInteger(data.credits) ? data.credits : ship.credits)
  data.repair_kits = zeroLimit(Number.isInteger(data.repair_kits) ? data.repair_kits : ship.repair_kits)
  data.med_kits = zeroLimit(Number.isInteger(data.med_kits) ? data.med_kits : ship.med_kits)
  data.food = zeroLimit(Number.isInteger(data.food) ? data.food : ship.food)
  data.erbium_crystals = zeroLimit(Number.isInteger(data.erbium_crystals) ? data.erbium_crystals : ship.erbium_crystals)
  data.alien_artifacts = zeroLimit(Number.isInteger(data.alien_artifacts) ? data.alien_artifacts : ship.alien_artifacts)

  data.fuel_amount = valueLimit(isNumber(data.fuel_amount) ? data.fuel_amount : ship.fuel_amount)
  data.weapon_status = valueLimit(isNumber(data.weapon_status) ? data.weapon_status : ship.weapon_status)
  data.engine_status = valueLimit(isNumber(data.engine_status) ? data.engine_status : ship.engine_status)
  data.hull_status = valueLimit(isNumber(data.hull_status) ? data.hull_status : ship.hull_status)

  data.condition = getShipCondition(
    data.hull_status,
    data.engine_status,
    get(data, ['airlock_status'], ship.airlock_status),
    get(data, ['waste_disposal'], ship.waste_disposal),
    get(data, ['weapons_system'], ship.weapons_system),
    get(data, ['navigation_system'], ship.navigation_system)
  )

  const updatedShip = await Ship.update({
    where: ship.id,
    data: data
  })

  checkResourceAchievement(updatedShip)
  return updatedShip
}

function calculateRepairAmount(kitsUsed, crewMechanic) {
  // Each repair kit fixes between 1 and 10
  let repairAmount = 0
  for (var i = 0; i < kitsUsed; i++) {
    repairAmount += Math.floor(Math.random() * 10) + 5
  }

  // Mechanics make repair kits more effective
  if (crewMechanic) {
    const multipler = mechanicRepairMultipler(crewMechanic)
    repairAmount = Math.floor(repairAmount * multipler)
  }
  if (repairAmount > 100) {
    repairAmount = 100
  }

  return repairAmount
}

export function damageShipAirlock(ship) {
  updateShip(ship, {
    airlock_status: settings.AIRLOCK_STATUS_DAMAGED
  })
}

export function damageShipNavigationSystem(ship) {
  updateShip(ship, {
    navigation_system: settings.NAVIGATION_SYSTEM_STATUS_DAMAGED
  })
}

export function damageShipWasteDisposal(ship) {
  updateShip(ship, {
    waste_disposal: settings.WASTE_DISPOSAL_STATUS_DAMAGED
  })
}

export function damageShipWeaponsSystem(ship) {
  updateShip(ship, {
    weapons_system: settings.WEAPONS_SYSTEM_STATUS_DAMAGED
  })
}

export function repairShipAirlock(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const success = mechanicsCheck(crewMechanic, settings.DIFFICULTY_CHECK_REPAIR_AIRLOCK)

  updateShip(ship, {
    airlock_status: success ? settings.AIRLOCK_STATUS_NORMAL : settings.AIRLOCK_STATUS_DAMAGED,
    repair_kits: totalKits
  })

  return success
}

export function repairShipWasteDisposal(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const success = mechanicsCheck(crewMechanic, settings.DIFFICULTY_CHECK_REPAIR_WASTE_DISPOSAL)

  updateShip(ship, {
    waste_disposal: success ? settings.WASTE_DISPOSAL_STATUS_NORMAL : settings.WASTE_DISPOSAL_STATUS_DAMAGED,
    repair_kits: totalKits
  })

  return success
}

export function repairShipWeaponsSystem(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const success = mechanicsCheck(crewMechanic, settings.DIFFICULTY_CHECK_REPAIR_WEAPONS_SYSTEM)

  updateShip(ship, {
    weapons_system: success ? settings.WEAPONS_SYSTEM_STATUS_NORMAL : settings.WEAPONS_SYSTEM_STATUS_DAMAGED,
    repair_kits: totalKits
  })

  return success
}

export function repairShipNavigationSystem(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const success = mechanicsCheck(crewMechanic, settings.DIFFICULTY_CHECK_REPAIR_NAVIGATION_SYSTEM)

  updateShip(ship, {
    navigation_system: success ? settings.NAVIGATION_SYSTEM_STATUS_NORMAL : settings.NAVIGATION_SYSTEM_STATUS_DAMAGED,
    repair_kits: totalKits
  })

  return success
}

export function repairShipHull(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const repairAmount = calculateRepairAmount(kitsUsed, crewMechanic)
  const hull = valueLimit(ship.hull_status + repairAmount)

  updateShip(ship, {
    repair_kits: totalKits,
    hull_status: hull
  })

  return repairAmount
}

export function repairShipEngine(ship, kitsUsed, crewMechanic) {
  const totalKits = valueLimit(ship.repair_kits - kitsUsed)
  const repairAmount = calculateRepairAmount(kitsUsed, crewMechanic)
  const engine = valueLimit(ship.engine_status + repairAmount)

  updateShip(ship, {
    repair_kits: totalKits,
    engine_status: engine
  })

  return repairAmount
}

export function getShipCondition(hull_status, engine_status, airlock_status, waste_disposal, weapons_system, navigation_system) {
  let condition = engine_status + hull_status

  condition += airlock_status == settings.AIRLOCK_STATUS_DAMAGED ? 0 : 25
  condition += waste_disposal == settings.WASTE_DISPOSAL_STATUS_DAMAGED ? 0 : 25
  condition += weapons_system == settings.WEAPONS_SYSTEM_STATUS_DAMAGED ? 0 : 25
  condition += navigation_system == settings.NAVIGATION_SYSTEM_STATUS_DAMAGED ? 0 : 25

  switch (true) {
    case condition > 260:
      return settings.SHIP_CONDITION_EXCELLENT
    case condition > 160:
      return settings.SHIP_CONDITION_GOOD
    case condition > 75:
      return settings.SHIP_CONDITION_POOR
    default:
      return settings.SHIP_CONDITION_CRITICAL
  }
}
