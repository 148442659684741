import Achievement from '../store/models/Achievement'
import Cheat from '../store/models/Cheat'
import ComMessage from '../store/models/ComMessage'
import Disease from '../store/models/Disease'
import Event from '../store/models/Event'
import Enemy from '../store/models/Enemy'
import MazeRoom from '../store/models/MazeRoom'
import MinorEvent from '../store/models/MinorEvent'
import Name from '../store/models/Name'
import Result from '../store/models/Result'
import ResearchData from '../store/models/ResearchData'
import Ship from '../store/models/Ship'
import Phrase from '../store/models/Phrase'
import Planet from '../store/models/Planet'

import json_achievements from '../data/achievements.json'
import json_adjectives from '../data/adjectives-names.json'
import json_bodypartnames from '../data/bodypart-names.json'
import json_commessages from '../data/com-messages.json'
import json_crewnames from '../data/crew-names.json'
import json_disease from '../data/diseases.json'
import json_enemies  from '../data/enemies.json'
import json_events from '../data/events.json'
import json_foodnames from '../data/food-names.json'
import json_minorevents from '../data/minor-events.json'
import json_phrases from '../data/phrases.json'
import json_results from '../data/results.json'
import json_shippartnames from '../data/shippart-names.json'
import json_ships from '../data/ships.json' // --->
import json_shipnames from '../data/spaceship-names.json'
import json_stationnames from '../data/station-names.json'
import json_treatmentnames from '../data/treatment-names.json'
import json_cheats from '../data/cheats.json'
import json_researchdatas from '../data/research-datas.json'
import json_mazerooms from '../data/maze-rooms.json'
import json_planets from '../data/planets.json'
import json_junk from '../data/junk.json'

const loadGameData =  () => {
  Ship.insert(json_ships)
  Event.insert(json_events)
  Enemy.insert(json_enemies)
  Result.insert(json_results)
  Disease.insert(json_disease)
  MinorEvent.insert(json_minorevents)
  Phrase.insert(json_phrases)
  Achievement.insert(json_achievements)
  ComMessage.insert(json_commessages)
  Cheat.insert(json_cheats)
  ResearchData.insert(json_researchdatas)
  MazeRoom.insert(json_mazerooms)
  Planet.insert(json_planets)

  Name.insert(json_junk)
  Name.insert(json_shipnames)
  Name.insert(json_crewnames)
  Name.insert(json_stationnames)
  Name.insert(json_foodnames)
  Name.insert(json_treatmentnames)
  Name.insert(json_bodypartnames)
  Name.insert(json_shippartnames)
  Name.insert(json_adjectives)
}

export default loadGameData