export function winningCombatResults (enemy) {
  const weaponsCost = -Math.floor((Math.floor(Math.random() * (14)) + enemy.challenge_rating/2))
  const hullCost = -Math.floor((Math.floor(Math.random() * (10)) + enemy.challenge_rating/2))
  const creditsGained = Math.floor(Math.random() * (100)) + enemy.challenge_rating * 10

  return {
    "result_text": "You destroy the ${enemy_name}.",
    "xp_gained": "100",
    "fuel_cost": 0,
    "hull_cost": hullCost,
    "weapons_cost": weaponsCost,
    "engines_cost": 0,
    "health_cost": 0,
    "credit_cost": creditsGained,
    "repair_kits_cost": 0,
    "med_kits_cost": 0,
    "food_cost": 0,
    "distance_cost": 0,
    "morale_cost": 1,
    "erbiumcrystal_cost": 0,
    "alien_artifact_cost": 0,
    "style": "success-message"
  }
}

export function losingCombatResults (enemy) {
  const weaponsCost = -Math.floor((Math.floor(Math.random() * (15)) + enemy.challenge_rating/2))
  const hullCost = -Math.floor((Math.floor(Math.random() * (40)) + enemy.challenge_rating/2))
  const engineCost = -Math.floor((Math.floor(Math.random() * (10)) + enemy.challenge_rating/2))

  return {
    "result_text": "You are defeated by the ${enemy_name}.",
    "xp_gained": "100",
    "fuel_cost": 0,
    "hull_cost": hullCost,
    "weapons_cost": weaponsCost,
    "engines_cost": engineCost,
    "health_cost": 0,
    "credit_cost": 0,
    "repair_kits_cost": 0,
    "med_kits_cost": 0,
    "food_cost": 0,
    "distance_cost": 0,
    "morale_cost": -1,
    "erbiumcrystal_cost": 0,
    "alien_artifact_cost": 0,
    "style": "fail-message"
  }
}

export function winningEscapeResults (enemy) {
  const fuelCost = -Math.floor((Math.floor(Math.random() * (10)) + enemy.challenge_rating/2))
  const hullCost = -Math.floor((Math.floor(Math.random() * (12)) + enemy.challenge_rating/2))

  return {
    "result_text": "${pilot} uses superior skill to out run the ${enemy_name}.",
    "result_type": "success",
    "xp_gained": "100",
    "fuel_cost": fuelCost,
    "hull_cost": hullCost,
    "weapons_cost": 0,
    "engines_cost": 0,
    "health_cost": 0,
    "credit_cost": 0,
    "repair_kits_cost": 0,
    "med_kits_cost": 0,
    "food_cost": 0,
    "distance_cost": 0,
    "morale_cost": 1,
    "erbiumcrystal_cost": 0,
    "alien_artifact_cost": 0,
    "style": "success-message"
  }
}


export function losingEscapeResults (enemy) {
  const fuelCost = -Math.floor((Math.floor(Math.random() * (14)) + enemy.challenge_rating/2))
  const hullCost = -Math.floor((Math.floor(Math.random() * (30)) + enemy.challenge_rating/2))
  const engineCost = -Math.floor((Math.floor(Math.random() * (10)) + enemy.challenge_rating/2))

  return {
    "result_text": "${pilot} fails to out run the ${enemy_name}.",
    "xp_gained": "100",
    "fuel_cost": fuelCost,
    "hull_cost": hullCost,
    "weapons_cost": 0,
    "engines_cost": engineCost,
    "health_cost": 0,
    "credit_cost": 0,
    "repair_kits_cost": 0,
    "med_kits_cost": 0,
    "food_cost": 0,
    "distance_cost": 0,
    "morale_cost": -1,
    "erbiumcrystal_cost": 0,
    "alien_artifact_cost": 0,
    "style": "fail-message"
  }
}
