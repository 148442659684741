import Enemy from '../store/models/Enemy'


export function getEnemies() {
  return Enemy.query().orderBy('name').all()
}

export function getEnemyById(id) {
  const enemy = Enemy.query().where((d) => {
    return d.id == id
  }).first()

  return enemy
}
