<template>
  <div>
    <div v-if="this.currentCrewBio">
      <crew-bio :activeCrewMember="this.currentCrewBio" />
    </div>
    <div v-else>
      <div v-for="crew in this.currentShip.crew_members" :key="crew.id" class="with-border with-border-radius crew crew-card"  @click="openCrewBio(crew.id)">
        <crew-icon :crew="crew" />

        <div :class="crew.status" class="info">
          {{ crew.name}}
          <div class="position">{{ title(crew) }}</div>
        </div>

        <div class="status" style="width: 140px;">
          <health-bar
            :percentage="crew.health"
            :status="crew.status"
            :drunkenness="crew.drunkenness"
            :morale="crew.morale"
            :has_disease="crew.has_disease"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import HealthBar from './HealthBar.vue'
import CrewBio from './CrewBio.vue'
import CrewIcon from './Ship/Components/CrewIcon.vue'

export default {
  name: "Crew",

   mixins: [
     GameMixin
  ],

  components: {
    HealthBar,
    CrewBio,
    CrewIcon
  },

  methods: {
    title (crew) {
      return crew.title ? crew.title : crew.position
    },

    openCrewBio (crew_id) {
      this.displayCrew(crew_id)
    }
  }
}
</script>
