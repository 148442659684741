<template>
  <span>
    <div @click.prevent="$emit('switchView')" class="v-bar with-border with-border-radius">
      <div class="v-bar-amount" :class="{ 'critical-level': critical }" :style="barWidth"></div>
      <aside>
        <h3>{{ this.label }}</h3>
      </aside>
    </div>
  </span>
</template>

<script>
import GameMixin from '../mixins/GameMixin'

export default {
  name: "VerticalResourceBar",

  mixins: [
    GameMixin
  ],

  props: {
    label: String,
    percentage: Number,
    color: String
  },

  computed: {
    barWidth () {
      let color = 'var(--green8)'

      if (this.percentage <= 10) { color = 'var(--red8)' }
      else if (this.percentage <= 25) { color = 'var(--red7)' }
      else if (this.percentage <= 50) { color = 'var(--yellow6)' }
      else if (this.percentage <= 75) { color = 'var(--green4)' }

      return `height:${this.percentage}%;background-color:${color};`
    },

    critical () {
      return (this.percentage <= 10)
    }
  }
}
</script>

<style>
.v-bar {
  border: 1px solid;
  background-color: #021206;
  width: 32px;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.v-bar-amount {
  width: 30px;
  text-align: center;
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  transition:height 1.5s ease-in-out;
}

.v-bar aside h3 {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
  font-family: 'silk-screen', sans-serif;
  color: white;
  transform: rotate(-90deg);
  position: absolute;
  transform-origin: 0 0;
  bottom: 0;
  left: calc(50% - .5em);
}
</style>
