import Planet from '../store/models/Planet'
import { getRandomRecord } from './helpers'


export function getPlanets() {
  return Planet.query().orderBy('name').all()
}

export function getRandomPlanet() {
  const planets = Planet.query().orderBy('name').all()
  const planet = getRandomRecord(planets)
  return planet
}

export function getRandomPlanetName() {
  return getRandomPlanet().name
}
