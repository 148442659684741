<template>
  <div>
    <h3 class="terminal-heading">System Info</h3>

    <p><b><i class="fas fa-font-awesome"></i> Awesome Core OS v6.0</b></p>

    <p style="padding-bottom: 35px;">
      <i class="fas fa-caret-right"></i> Processor: <span style="color: var(--purple);">i64-U8000 @ 1.21 Giga-Watts</span><br>
      <i class="fas fa-caret-right"></i> System memory: <span style="color: var(--purple);">1024</span>kB<br>
      <i class="fas fa-caret-right"></i> Terminal ID: <span style="color: var(--purple);">{{ terminalId }}</span><br>
    </p>

    <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
      <i class="fas fa-left"></i> Return to Menu
    </a>
  </div>
</template>

<script>
  export default {
    name: "TerminalInfo",

    props: {
      terminalId: String
    }
  }
</script>