<template>
  <div class="console game-credits with-border with-border-radius text-center" style="text-align: center;color: white;">
    <h1 class="padding-top-xl">Space Awesome</h1>
    <div class="margin-y-2xl">
      <svg version="1.1" class="logo-big" id="space-awesome" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
        <path d="M2,11H0v5h4V8H2V11z M9,0H7v2H5v12h6V2H9V0z M9,6H7V4h2V6z M14,11V8h-2v8h4v-5H14z"/>
      </svg>
    </div>

    <p>Built By</p>
    <h3 style="color: var(--blue);" class="margin-y-md"><a style="text-decoration: none;" href="http://fontawesome.com/" target="_blank"><font-awesome-icon  icon="font-awesome" /> Font Awesome</a></h3>

    <p class="margin-bottom-2xl">
      <span class="display-block margin-bottom-xs" style="color: var(--gray);">Programming by</span>
      Edward Emanuel Jr.
    </p>

    <p class="margin-bottom-2xl">
      <span class="display-block margin-bottom-xs" style="color: var(--gray);">Design by</span>
      Frances Botsford &amp; Edward Emanuel Jr.
    </p>

    <div class="margin-top-3xl">
      <button @click="playAgain()" class="green">Play Again <i class="fas fa-arrow-right"></i> </button>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { createNewGame } from '../../utils/game'

export default {
  name: "GameCredits",

  mixins: [
    GameMixin
  ],

  computed: {

  },

  methods: {
    playAgain () {
      createNewGame(this.currentShip)
    },

    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

  }
}
</script>
