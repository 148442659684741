<template>
  <div
    id="app"
    class="container"
  >
    <div class="row">
      <div class="header column-12 game-title">
        <h1>
          <svg
            version="1.1"
            class="logo"
            id="space-awesome"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            style="enable-background: new 0 0 16 16"
            xml:space="preserve"
          >
            <path d="M2,11H0v5h4V8H2V11z M9,0H7v2H5v12h6V2H9V0z M9,6H7V4h2V6z M14,11V8h-2v8h4v-5H14z" />
          </svg>
          Space Awesome
        </h1>
      </div>
    </div>

    <div
      v-if="!this.currentGame.active && this.currentGame.active_display == 'gamestart'"
      class="row offset-1 column-10 laptop:offset-2 laptop:column-8 desktop:offset-3 desktop:column-6"
    >
      <start-game />
    </div>

    <div
      v-else-if="this.currentGame.active"
      class="row"
    >
      <div class="column-3">
        <crew-members />
      </div>
      <div class="column-6">
        <div v-if="this.currentGame.active_display === 'events'">
          <console />
        </div>
        <div v-else-if="this.currentGame.active_display === 'adjustspeed'">
          <adjust-speed />
        </div>
        <div v-else-if="this.currentGame.active_display === 'airlock'">
          <airlock />
        </div>
        <div v-else-if="this.currentGame.active_display === 'scienceterminal'">
          <science-terminal />
        </div>
        <div v-else-if="this.currentGame.active_display === 'bridge'">
          <bridge />
        </div>
        <div v-else-if="this.currentGame.active_display === 'cargohold'">
          <cargo-hold />
        </div>
        <div v-else-if="this.currentGame.active_display === 'cheats'">
          <cheats />
        </div>
        <div v-else-if="this.currentGame.active_display === 'clonecrew'">
          <clone-crew />
        </div>
        <div v-else-if="this.currentGame.active_display === 'communicator'">
          <communicator />
        </div>
        <div v-else-if="this.currentGame.active_display === 'medicalterminal'">
          <medical-terminal />
        </div>
        <div v-else-if="this.currentGame.active_display === 'divertpower'">
          <divert-power />
        </div>
        <div v-else-if="this.currentGame.active_display === 'experiments'">
          <experiments />
        </div>
        <div v-else-if="this.currentGame.active_display === 'gambling'">
          <gambling />
        </div>

        <div v-else-if="this.currentGame.active_display === 'kitchen'">
          <kitchen />
        </div>
        <div v-else-if="this.currentGame.active_display === 'lab'"><lab /></div>
        <div v-else-if="this.currentGame.active_display === 'rations'">
          <food-rations />
        </div>
        <div v-else-if="this.currentGame.active_display === 'engineroom'">
          <engine-room />
        </div>
        <div v-else-if="this.currentGame.active_display === 'repairengine'">
          <engine-repair />
        </div>
        <div v-else-if="this.currentGame.active_display === 'repairhull'">
          <hull-repair />
        </div>
        <div v-else-if="this.currentGame.active_display === 'sickbay'">
          <sick-bay />
        </div>
        <div v-else-if="this.currentGame.active_display === 'shipmenu'">
          <ship-menu />
        </div>
        <div v-else-if="this.currentGame.active_display === 'travel'">
          <travel />
        </div>
        <div v-else-if="this.currentGame.active_display === 'secretroom'">
          <secret-room />
        </div>
        <div v-else-if="this.currentGame.active_display === 'secureddoor'">
          <secured-door />
        </div>
        <div v-else-if="this.currentGame.active_display === 'cheatcodes'">
          <cheat-codes />
        </div>
        <div v-else-if="this.currentGame.active_display === 'convertrobot'">
          <convert-robot />
        </div>
        <div v-else-if="this.currentGame.active_display === 'navigationterminal'">
          <navigation-terminal />
        </div>

        <div v-else-if="this.currentGame.active_display === 'hire'">
          <hire-crew />
        </div>
        <div v-else-if="this.currentGame.active_display === 'outpost'">
          <outpost />
        </div>
        <div v-else-if="this.currentGame.active_display === 'cantina'">
          <cantina />
        </div>
        <div v-else-if="this.currentGame.active_display === 'infirmary'">
          <infirmary />
        </div>
        <div v-else-if="this.currentGame.active_display === 'shiprepairs'">
          <outpost-repair />
        </div>
        <div v-else-if="this.currentGame.active_display === 'supplies'">
          <purchase-supplies />
        </div>
        <div v-else-if="this.currentGame.active_display === 'refuel'">
          <outpost-refuel />
        </div>
        <div v-else-if="this.currentGame.active_display === 'sellsupplies'">
          <sell-supplies />
        </div>
        <div v-else-if="this.currentGame.active_display === 'tradingpost'">
          <trading-post />
        </div>
      </div>
      <div class="column-3 status-panels">
        <mission-status />
        <ship-status />
      </div>
    </div>
    <div v-else-if="this.currentGame.active_display === 'releasenotes'">
      <div class="row">
        <div class="offset-3 column-6">
          <release-notes />
        </div>
      </div>
    </div>

    <div v-else-if="this.currentGame.active_display === 'gamecredits'">
      <div class="row">
        <div class="offset-3 column-6">
          <game-credits />
        </div>
      </div>
    </div>
    <div
      v-else-if="this.currentGame.active_display === 'gameover'"
      class="gameover"
    >
      <div class="row wrapper">
        <div class="offset-3 column-6">
          <game-over />
        </div>
      </div>
    </div>
    <div
      v-else-if="this.currentGame.active_display === 'win'"
      class="gameover"
    >
      <div class="row wrapper">
        <div class="offset-3 column-6">
          <game-win />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GameMixin from './mixins/GameMixin'
  import loadGameData from './utils/dataloader'
  import { createNewGame } from './utils/game'
  import AdjustSpeed from './views/Ship/Bridge/AdjustSpeed.vue'
  import Airlock from './views/Ship/Airlock.vue'
  import Bridge from './views/Ship/Bridge/Bridge.vue'
  import Cantina from './views/Outpost/Cantina.vue'
  import Cheats from './views/Cheats.vue'
  import CloneCrew from './views/Ship/ScienceLab/CloneCrew.vue'
  import CargoHold from './views/Ship/CargoHold.vue'
  import CrewMembers from './views/CrewMembers.vue'
  import Communicator from './views/Ship/Bridge/Communicator.vue'
  import Console from './views/Console.vue'
  import MedicalTerminal from './views/Ship/SickBay/MedicalTerminal.vue'
  import DivertPower from './views/Ship/EngineRoom/DivertPower.vue'
  import EngineRepair from './views/Ship/EngineRoom/EngineRepair.vue'
  import EngineRoom from './views/Ship/EngineRoom/EngineRoom.vue'
  import Experiments from './views/Ship/ScienceLab/Experiments.vue'
  import FoodRations from './views/Ship/Kitchen/FoodRations.vue'
  import Gambling from './views/Outpost/Gambling.vue'
  import GameCredits from './views/Game/GameCredits.vue'
  import GameOver from './views/Game/GameOver.vue'
  import GameWin from './views/Game/GameWin.vue'
  import HireCrew from './views/Outpost/HireCrew.vue'
  import HullRepair from './views/Ship/EngineRoom/HullRepair.vue'
  import Infirmary from './views/Outpost/Infirmary.vue'
  import Kitchen from './views/Ship/Kitchen/Kitchen.vue'
  import Lab from './views/Ship/ScienceLab/Lab.vue'
  import MissionStatus from './views/MissionStatus.vue'
  import NavigationTerminal from './views/Ship/Bridge/NavigationTerminal.vue'
  import Outpost from './views/Outpost/Outpost.vue'
  import OutpostRefuel from './views/Outpost/OutpostRefuel.vue'
  import OutpostRepair from './views/Outpost/OutpostRepair.vue'
  import PurchaseSupplies from './views/Outpost/PurchaseSupplies.vue'
  import ReleaseNotes from './views/Game/ReleaseNotes.vue'
  import ScienceTerminal from './views/Ship/ScienceLab/ScienceTerminal.vue'
  import SellSupplies from './views/Outpost/SellSupplies.vue'
  import ShipStatus from './views/ShipStatus.vue'
  import ShipMenu from './views/Ship/ShipMenu.vue'
  import SickBay from './views/Ship/SickBay/SickBay.vue'
  import StartGame from './views/Game/StartGame.vue'
  import Travel from './views/Travel.vue'
  import SecretRoom from './views/Ship/Bridge/SecretRoom.vue'
  import CheatCodes from './views/Ship/Bridge/CheatCodes.vue'
  import SecuredDoor from './views/Ship/Bridge/SecuredDoor.vue'
  import ConvertRobot from './views/Ship/ScienceLab/ConvertRobot.vue'
  import TradingPost from './views/Outpost/TradingPost.vue'

  export default {
    name: 'app',

    mixins: [GameMixin],

    components: {
      AdjustSpeed,
      Airlock,
      Bridge,
      Cantina,
      CargoHold,
      Cheats,
      CloneCrew,
      Communicator,
      Console,
      CrewMembers,
      DivertPower,
      EngineRepair,
      EngineRoom,
      Experiments,
      FoodRations,
      Gambling,
      GameCredits,
      GameOver,
      GameWin,
      HireCrew,
      HullRepair,
      Infirmary,
      Kitchen,
      Lab,
      MedicalTerminal,
      MissionStatus,
      NavigationTerminal,
      Outpost,
      OutpostRefuel,
      OutpostRepair,
      PurchaseSupplies,
      ReleaseNotes,
      ScienceTerminal,
      SellSupplies,
      StartGame,
      ShipStatus,
      ShipMenu,
      SickBay,
      Travel,
      SecretRoom,
      SecuredDoor,
      CheatCodes,
      ConvertRobot,
      TradingPost
    },

    created() {
      // this.$cookies.config('30d')
      this.$plausible.trackPageview()

      loadGameData()
      createNewGame(this.currentShip)
    },

    computed: {
      currentActiveDisplay() {
        if (this.currentGame) {
          return this.currentGame.active_display
        } else {
          return 'gamestart'
        }
      }
    }
  }
</script>

<style>
  @import './css/app.css';
  @import './css/animations.css';
</style>
