import { addMessage } from '../utils/message'
import settings from '../utils/settings'
import { getCrewMemberByPosition } from '../utils/crew'
import {
  repairShipHull,
  repairShipEngine,
  updateShip,
  repairShipAirlock,
  repairShipWasteDisposal,
  repairShipWeaponsSystem,
  repairShipNavigationSystem
} from '../utils/ship'

export default {
  methods: {
    repairHull(kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const hullGained = repairShipHull(this.currentShip, kitsUsed, crewMechanic)
      addMessage(`You used ${kitsUsed} repair ${kitsUsed > 1 ? 'kits' : 'kit'}. Hull increased by ${hullGained}%.`, 'gain-message')
    },

    repairEngine(kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const engineGained = repairShipEngine(this.currentShip, kitsUsed, crewMechanic)
      addMessage(`You used ${kitsUsed} repair ${kitsUsed > 1 ? 'kits' : 'kit'}. Engines increased by ${engineGained}%.`, 'gain-message')
    },

    repairAirlock(kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      const success = repairShipAirlock(this.currentShip, kitsUsed, crewMechanic)

      if (success) {
        addMessage(`${crewMechanic.name} repaired the airlock. One repair kit used.`, 'gain-message')
      } else {
        addMessage(`${crewMechanic.name} was unable to repair the airlock. One repair kit used.`, 'lose-message')
      }
    },

    repairSystem(systemName, kitsUsed) {
      const crewMechanic = getCrewMemberByPosition(settings.POSITION_MECHANIC, this.currentShip)
      let success = false

      switch (systemName) {
        case 'airlock':
          success = repairShipAirlock(this.currentShip, kitsUsed, crewMechanic)
          break
        case 'waste disposal':
          success = repairShipWasteDisposal(this.currentShip, kitsUsed, crewMechanic)
          break
        case 'weapons system':
          success = repairShipWeaponsSystem(this.currentShip, kitsUsed, crewMechanic)
          break
        case 'navigation system':
          success = repairShipNavigationSystem(this.currentShip, kitsUsed, crewMechanic)
          break
      }

      if (success) {
        addMessage(`${crewMechanic.name} repaired the ${systemName}. One repair kit used.`, 'gain-message')
      } else {
        addMessage(`${crewMechanic.name} was unable to repair the  ${systemName}. One repair kit used.`, 'lose-message')
      }
    },

    outpostEngineRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { engine_status: 100, credits: credits })
      }
    },

    outpostHullRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { hull_status: 100, credits: credits })
      }
    },

    outpostAirlockRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { airlock_status: settings.AIRLOCK_STATUS_NORMAL, credits: credits })
      }
    },

    outpostWasteDisposalRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL, credits: credits })
      }
    },

    outpostWeaponsSystemRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { weapons_system: settings.WEAPONS_SYSTEM_STATUS_NORMAL, credits: credits })
      }
    },

    outpostNavigationSystemRepair(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { navigation_system: settings.NAVIGATION_SYSTEM_STATUS_NORMAL, credits: credits })
      }
    },

    outpostRepairEverything(cost) {
      if (this.hasCredits(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, {
          engine_status: 100,
          hull_status: 100,
          waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL,
          airlock_status: settings.AIRLOCK_STATUS_NORMAL,
          navigation_system: settings.NAVIGATION_SYSTEM_STATUS_NORMAL,
          weapons_system: settings.WEAPONS_SYSTEM_STATUS_NORMAL,
          credits: credits
        })
      }
    },

    outpostRefuel(cost, fuelAmount) {
      if (this.hasCredits(cost)) {
        addMessage(`Your ship's has been refueled`, 'gain-message')
        const credits = this.calculateCredits(this.currentShip.credits, -cost)
        updateShip(this.currentShip, { fuel_amount: fuelAmount, credits: credits })
      }
    },

    outpostWeaponRecharge(cost) {
      if (this.hasCredits(cost)) {
        addMessage(`Your ship's weapons have been recharged`, 'gain-message')
        const credits = this.calculateCredits(this.currentShip.credits, cost)
        updateShip(this.currentShip, { weapon_status: 100, credits: credits })
      }
    }
  }
}
