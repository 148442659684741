<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="microwave" />
        Kitchen
      </h3>

      <div v-if="crewCook">
        Chef {{ crewCook.name}} is standing by to make meals for the crew.
      </div>
      <br>
      <div>Food rations are currently set at <span class="game-info">{{ rationLevel }}</span>.
      <span v-if="this.currentShip.food > 0">At the current rate, you will run out of food in <span class="game-info">{{ foodDaysRemaining }} days</span>.</span>
      <span v-else>You are out of food.</span>
      There are approximately <span class="game-info">{{ daysRemaining }} days</span> left in your journey.</div>

      <br>
      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="changeRations()">Change food rations</a></li>
            <li v-if="specialReady"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="boostMorale(50)">Boost morale with a feast for the crew [50 Food]</a></li>
            <li v-if="specialReady"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="makeFood(50)">Replicate 50 Food units</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { rationLevelName, foodDays } from '../../../utils/formulas'
import { makeFeast, startFoodReplication } from '../../../utils/cooking'
import { canStartJob, daysToCompleteCurrentJob } from '../../../utils/job'
import { getLivingCrewMembers, getCrewMemberByPosition } from '../../../utils/crew'
import { getRandomCaptainGreeting } from '../../../utils/phrases'
import { addMessage } from '../../../utils/message'

export default {
  name: "Kitchen",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewCook) {
      addMessage(getRandomCaptainGreeting(this.crewCook), '')
    } else {
      addMessage('The kitchen feels empty without a cook.', '')
    }
  },

  computed: {
    specialReady () {
      return this.crewCook && this.currentShip.food > 50 && this.currentGame.cook_cooldown == 0
    },

    crewCook () {
      return getCrewMemberByPosition('Cook', this.currentShip)
    },

    rationLevel () {
      return rationLevelName(this.currentGame.food_ration_level)
    },

    foodDaysRemaining () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      return foodDays(this.currentGame.food_ration_level, crewMembers.length, this.currentShip.food)
    },

    daysRemaining () {
      return Math.floor(this.travelDaysRemaining)
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    changeRations () {
      this.clearMessages()
      this.changeDisplay("rations")
    },

    boostMorale (size) {
      this.clearMessages()
      if (this.crewCook) {
        makeFeast(this.crewCook, this.currentShip, this.currentGame, size)
      }
    },

     makeFood (foodAmount) {
      this.clearMessages()

      if (canStartJob(this.crewCook)) {
        if (this.currentShip.food < foodAmount) {
          addMessage("You don't have enough food to replicate.")
        } else {
          startFoodReplication(this.crewCook, this.currentShip, this.currentGame, foodAmount)
        }
      } else {
        this.currentJobMessage(this.crewCook)
      }
    },

    currentJobMessage () {
      const days = daysToCompleteCurrentJob(this.crewCook)
      addMessage(`${this.crewCook.name} is already working on a job ${days > 1 ? `[${days} days remaining]` : '[ Less than 1 day remaining]'}`)
    }
  }
}
</script>
