import { Model } from '@vuex-orm/core'
import ComMessageChoice from './ComMessageChoice'

export default class ComMessage extends Model {
  static entity = 'commessages'

  static fields () {
    return {
      id: this.uid(),
      code: this.string(''),
      welcome_message: this.string(''),
      message: this.string(''),
      choices: this.hasMany(ComMessageChoice, 'commessage_id'),
      max_occurrences: this.number(1),
      occurrence_count: this.number(0)
    }
  }
}
