<template>
  <div class="with-border with-border-radius game-stats margin-2xl padding-md">
    <div class="row">
      <div class="column-8">
        <div class="stat-label">Game Mode:</div>
        <div class="stat-label">Distance Traveled:</div>
        <div class="stat-label">Events Encountered:</div>
        <div class="stat-label">Crew Members Lost:</div>
        <div class="stat-label">Outposts Visited:</div>
        <div class="stat-label">Secrets Discovered:</div>
        <div class="stat-label">Cheats Entered:</div>
      </div>

      <div class="column-4">
        <div class="stat-data">{{ this.currentGame.difficulty }}</div>
        <div class="stat-data">{{ distanceTraveled }}</div>
        <div class="stat-data">{{ this.currentGame.event_count }}</div>
        <div class="stat-data">{{ this.currentGame.crew_death_count }}</div>
        <div class="stat-data">{{ this.currentGame.outposts_visited }}</div>
        <div class="stat-data">{{ this.currentGame.secrets_found }}</div>
        <div class="stat-data">{{ this.currentGame.cheats_unlocked }}</div>
      </div>

      <canvas id="c" width="500" height="300" style="display: none;"></canvas>
      <div style="text-align: center;"><a :href="imageUrl" download="spaceawesome.png">Download Score Card</a></div>

    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { getTravelDistanceByDifficulty } from '../utils/game'
import Achievement from '../store/models/Achievement'

const CANVAS_WIDTH = 500
const CANVAS_HEIGHT = 300

export default {
  name: "GameStats",

  mixins: [
    GameMixin
  ],

   data() {
    return {
      vueCanvas: null,
      canvasComplete: false
    }
   },

  mounted() {
    var c = document.getElementById("c")
    var ctx = c.getContext("2d")
    this.vueCanvas = ctx

    this.drawRect()
  },

  computed: {
    distanceTraveled () {
      const totalDistance = getTravelDistanceByDifficulty(this.currentGame.difficulty)
      return (totalDistance - this.currentGame.distance_to_planet).toLocaleString()
    },

    imageUrl () {
      if (this.canvasComplete) {
        const tempCanvas = document.getElementById('c')
        const imageURL = tempCanvas.toDataURL('image/png')
        return imageURL
      }
      return ''
    },

    achievements () {
      const achievements = Achievement.query().all()
      return achievements
    }
  },

  methods: {
    drawRect () {
      const LEFT_POSTION = 25
      const RIGHT_POSTION = 450

      this.vueCanvas.font = "36px silk-screen"
      this.vueCanvas.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)

      this.vueCanvas.fillStyle = "rgb(245, 159, 0)"
      this.vueCanvas.textAlign = "center"
      this.vueCanvas.fillText(`Space Awesome`, 250, 30)

      this.vueCanvas.font = "14px silk-screen"
      this.vueCanvas.fillStyle = "rgb(105, 219, 124)"
      this.vueCanvas.fillText(`${this.currentGame.game_over_text}`, 250, 50)

      this.vueCanvas.fillStyle = "rgb(137, 170, 252)"
      this.vueCanvas.fillText(`Game Mode - ${this.currentGame.difficulty}`, 250, 70)

      this.vueCanvas.fillStyle = "white"
      this.vueCanvas.textAlign = "left"
      this.vueCanvas.fillText("Distance Traveled:", LEFT_POSTION, 105)
      this.vueCanvas.fillText("Events Encountered:", LEFT_POSTION, 130)
      this.vueCanvas.fillText("Crew Members Lost:", LEFT_POSTION, 155)
      this.vueCanvas.fillText("Outposts Visited:", LEFT_POSTION, 180)
      this.vueCanvas.fillText("Secrets Discovered:", LEFT_POSTION, 205)
      this.vueCanvas.fillText("Cheats Entered:", LEFT_POSTION, 230)

      this.vueCanvas.textAlign = "right"
      this.vueCanvas.fillStyle = "rgb(105, 219, 124)"
      this.vueCanvas.fillText(`${this.distanceTraveled}`, RIGHT_POSTION, 105)
      this.vueCanvas.fillText(`${this.currentGame.event_count}`, RIGHT_POSTION, 130)
      this.vueCanvas.fillText(`${this.currentGame.crew_death_count}`, RIGHT_POSTION, 155)
      this.vueCanvas.fillText(`${this.currentGame.outposts_visited}`, RIGHT_POSTION, 180)
      this.vueCanvas.fillText(`${this.currentGame.secrets_found}`, RIGHT_POSTION, 205)
      this.vueCanvas.fillText(`${this.currentGame.cheats_unlocked}`, RIGHT_POSTION, 230)

      const today = new Date()
      this.vueCanvas.textAlign = "center"
      this.vueCanvas.fillText(`${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()}`, 250, 270)

      this.vueCanvas.font = "16px silk-screen"
      this.vueCanvas.textAlign = "center"
      this.vueCanvas.fillStyle = "rgb(137, 170, 252)"
      this.vueCanvas.fillText(`Built by Font Awesome`, 250, 295)

      this.canvasComplete = true
    }
  }
}
</script>

<style>
  .game-stats {
    width: 90%;
    display: inline-block;
  }

  .stat-label {
    padding: 4px;
    color: white;
    text-align: left;
  }

  .stat-data {
    padding: 4px;
    text-align: right;
  }

</style>
