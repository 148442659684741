import Job from '../store/models/Job'
import settings from './settings'

export function startJob (crew, jobType, distanceTilComplete, value=0) {
  Job.insert({
    data: {
      job_type: jobType,
      crew_id: crew.id,
      distance_til_complete: distanceTilComplete,
      value: value
    }
  })
}

export function getActiveJobs () {
  const jobs = Job.query().with('crew').where((job) => {
    return job.active
  }).all()

  return jobs
}

export function getCrewActiveJob (crew) {
  const job = Job.query().with('crew').where((job) => {
    return job.active && job.crew_id == crew.id
  }).first()

  return job
}

export function updateJob (job, data) {
  Job.update({
    where: job.id,
    data: data
  })
}

export function canStartJob (crew) {
  const jobRunning = Job.query().where((job) => {
    return job.active && job.crew_id == crew.id
  }).exists()

  return !jobRunning
}

export function daysToCompleteCurrentJob (crew) {
  const job = getCrewActiveJob(crew)

  if (job != null) {
    return Math.floor(job.distance_til_complete / settings.DISTANCE_PER_DAY)
  } else {
    return null
  }
}

export function scientistJobStatus (scientist) {
  const job = getCrewActiveJob(scientist)

  if (job != null) {
    const days = daysToCompleteCurrentJob(scientist)

    switch (job.job_type) {
      case settings.JOB_MEDKIT:
        return `The med kit synthesizing process will be complete in ${days > 1 ? `approximately ${days} days` : 'less than a day'}. `

      case settings.JOB_FOOD:
        return `The food replication process will be complete in ${days > 1 ? `approximately ${days} days` : 'less than a day'}. `

      case settings.JOB_CRYSTAL:
        return `The scan for erbium crystals will be complete in ${days > 1 ? `approximately ${days} days` : 'less than a day'}. `
    }
  }

  return ''
}

export function mechanicJobStatus (mechanic) {
  const job = getCrewActiveJob(mechanic)

  if (job != null) {
    const days = daysToCompleteCurrentJob(mechanic)

    if (job.job_type == settings.JOB_REPAIRKIT) {
      return `Building the repair kit will be complete in ${days > 1 ? `approximately ${days} days` : 'less than a day'}. `
    }
  }
  return ''
}
