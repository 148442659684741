<template>
  <div class="" style="width: 140px; margin: 2px; padding: 2px; text-align:center; display: flex; align-items: center;">
    <span class="tooltip" style="color: var(--white);margin-right: 6px;cursor: pointer;" @click.prevent="gotoAdjustSpeed()">
      <i class="fa fa-gauge" />
      <span class="tooltiptext tooltip-top">Update Speed</span>
    </span>
    <span v-for="n in parseInt(8)" :key="n" class="verticalPanelButton tooltip" :class="indicatorColor(n)">
      <span class="tooltiptext tooltip-top">Current Speed</span>
    </span>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'

export default {
  name: "SpeedBar",

  mixins: [
    GameMixin
  ],

  props: {
    speed: Number
  },

  methods: {
    indicatorColor (count) {
      if (count <= (this.speed * 4)) {
        return "panelbutton-blue"
      } else {
        return "panelbutton-black"
      }
    },

    gotoAdjustSpeed () {
      if (this.currentGame.active_display != 'events' && this.currentGame.active_display != 'outpost') {
        this.changeDisplay('adjustspeed')
      }
    }
  }
}
</script>
