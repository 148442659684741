import { addMessage } from '../utils/message'
import { updateShip } from '../utils/ship'
import { lockAchievement } from '../utils/achievements'

export default {
  methods: {
    purchaseCrates(amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const crates = this.calculateResourceChange('crate', this.currentShip.crates, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          crates: crates,
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseRepairKits(amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          repair_kits: repairKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseMedKits(amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          med_kits: medKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseFood(amount, cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const food = this.calculateResourceChange('food', this.currentShip.food, amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          food: food,
          credits: credits
        })

        lockAchievement('HUNGRY')
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    purchaseMoraleBoost(cost) {
      if (this.currentShip.credits >= Math.abs(cost)) {
        const credits = this.calculateCredits(this.currentShip.credits, cost)

        updateShip(this.currentShip, {
          credits: credits
        })
      } else {
        addMessage(`You don't have enough credits to purchase that.`, 'lose-message')
      }
    },

    sellRepairKits(amount, price) {
      if (this.currentShip.repair_kits >= amount) {
        const repairKits = this.calculateResourceChange('repair kit', this.currentShip.repair_kits, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          repair_kits: repairKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have a repair kit to sell.`, 'lose-message')
      }
    },

    sellMedKits(amount, price) {
      if (this.currentShip.med_kits >= amount) {
        const medKits = this.calculateResourceChange('med kit', this.currentShip.med_kits, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          med_kits: medKits,
          credits: credits
        })
      } else {
        addMessage(`You don't have a med kit to sell.`, 'lose-message')
      }
    },

    sellFood(amount, price) {
      if (this.currentShip.food >= amount) {
        const food = this.calculateResourceChange('food', this.currentShip.food, -amount, true)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          food: food,
          credits: credits
        })
      } else {
        addMessage(`You don't have that much food.`, 'lose-message')
      }
    },

    sellErbiumCrystal(amount, price) {
      if (this.currentShip.erbium_crystals >= amount) {
        const crystals = this.calculateResourceChange('erbium crystal', this.currentShip.erbium_crystals, -amount)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          erbium_crystals: crystals,
          credits: credits
        })
      } else {
        addMessage(`You don't have a erbium crystal to sell.`, 'lose-message')
      }
    },

    sellAlienArtifact(amount, price) {
      if (this.currentShip.alien_artifacts >= amount) {
        const artifacts = this.calculateResourceChange('alien artifact', this.currentShip.alien_artifacts, -amount)
        const credits = this.calculateCredits(this.currentShip.credits, price)

        updateShip(this.currentShip, {
          alien_artifacts: artifacts,
          credits: credits
        })
      } else {
        addMessage(`You don't have a alien artifacts to sell.`, 'lose-message')
      }
    }
  }
}
