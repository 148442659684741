import { updateShip } from './ship'
import { mechanicsCheck, scienceCheck } from './skills'
import { updateCrewHealth, updateCrewMorale } from './crew'
import { addMessage, addStatusMessage } from './message'
import settings from './settings'
import { updateGame } from './game'
import { formatCrewPhrase, getRandomPhrase } from './phrases'
import { gameModeDifficultyAdjustment } from './formulas'
import { unlockResearchData } from './research'


export function synthesizeMedKits (scientist, ship, game) {
  const difficulty = settings.DIFFICULTY_CHECK_SCIENCE_SYNTHESIZE + gameModeDifficultyAdjustment(game)
  const successful = scienceCheck(scientist, difficulty)
  const hadComplications = (Math.floor(Math.random() * 20) > scientist.morale + scientist.science)

  const complications = (hadComplications ? `During the process, ${scientist.name} is hurt.` : '')
  const medKits = Math.floor(Math.random() * 2) + 1 // A number between 1 and 3
  const result = (successful ? `${scientist.name} creates ${medKits} medkits.` : `${scientist.name} fails to create any medkits.`)

  if (successful) {
    updateShip(ship, { med_kits: ship.med_kits + medKits })
  }

  if (hadComplications) {
    const damage = -(Math.floor(Math.random() * 20))
    updateCrewHealth(scientist, damage)
  }

  addStatusMessage(`${result} ${complications}`)
}

export function buildRepairKit (mechanic, ship, repairKits, game) {
  const difficulty = settings.DIFFICULTY_CHECK_BUILD_REPAIR_KIT + gameModeDifficultyAdjustment(game)
  const successful = mechanicsCheck(mechanic, difficulty)

  if (successful) {
    updateShip(ship, { repair_kits: ship.repair_kits + repairKits })
  }

  addStatusMessage(`${mechanic.name} ${(successful ? 'successfully built' : 'failed to build')} the repair kit.`)
}

export function crystalScan (scientist, ship, game) {
  const difficulty = settings.DIFFICULTY_CHECK_SCIENCE_SCAN + gameModeDifficultyAdjustment(game)
  const successful = scienceCheck(scientist, difficulty)

  if (successful) {
    const bonusCrystal = (Math.floor(Math.random() * 100) < settings.BONUS_CRYSTAL_DROP_RATE - gameModeDifficultyAdjustment(game))
    const crystalsGained = (bonusCrystal ? 2 : 1)

    updateShip(ship, { erbium_crystals: ship.erbium_crystals + crystalsGained })
    addStatusMessage(`${scientist.name} reports that the scanners found and retrieved ${crystalsGained == 1 ? 'an erbium crystal' : 'two erbium crystals'}.`)
  } else {
    addStatusMessage(`${scientist.name} reports that the scanners were unable to find any erbium crystals.`)
  }
}

export function erbiumFuelConversion (scientist, ship) {
  const erbium_crystals = ship.erbium_crystals - 1
  const fuelGained = Math.floor(Math.random() * 20) + scientist.science + 6
  const fuel = ship.fuel_amount + fuelGained

  updateShip(ship, { fuel_amount: fuel, erbium_crystals: erbium_crystals })

  addMessage(`${scientist.name} converts an erbium crystal into fuel cells. Gain ${fuelGained}% fuel.`, 'gain-message')
}

export function cloningSuccessRate (scientist) {
  return settings.CLONING_CHANCE + scientist.science + scientist.morale
}

export function cloningResults () {
  const success = (Math.floor(Math.random() * 20) > cloningSuccessRate)
  return success
}

export function robotScrapSucessRate (scientist) {
  return settings.ROBOT_SCRAP_CHANCE + scientist.science + scientist.morale
}

export function robotScrapBonusSucessRate (scientist) {
  return settings.BONUS_MATERIAL_ROBOT_SCRAP_CHANCE + scientist.science + scientist.morale
}

export function analyseArtifact (game, scientist) {
  if (game.scientist_cooldown == 0) {
    updateGame(game, { scientist_cooldown: settings.SCIENTIST_COOLDOWN_DISTANCE })

    const success = scienceCheck(scientist, settings.DIFFICULTY_CHECK_ALIEN_SYMBOLS)

    const results = getRandomPhrase(success ? 'artifact-investigation-success' : 'artifact-investigation-failure').text
    addMessage(`${scientist.name} does a detailed analysis of the alien artifact.`, '')
    addMessage(formatCrewPhrase(scientist, results))

    if (success) {
      const result = updateCrewMorale(scientist, 1)
      unlockResearchData('Alien Symbols')

      if (result != '') {
        addMessage(result, '')
      }
    }

  } else {
    addMessage(`You already analysed the alien artifacts.`, 'lose-message')
  }
}
