import settings from "./settings"

export function getMoraleDescription (morale) {
  switch (true) {
    case morale < -4:
      return settings.MORALE_DESCRIPTION_DISPAIR
    case morale < 0:
      return settings.MORALE_DESCRIPTION_LOW
    case morale > 4:
      return settings.MORALE_DESCRIPTION_EXCITED
    case morale > 0:
      return settings.MORALE_DESCRIPTION_HIGH
    default:
      return settings.MORALE_DESCRIPTION_NEUTRAL
  }
}