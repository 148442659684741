import { Model } from '@vuex-orm/core'

export default class Message extends Model {
  static entity = 'messages'

  static fields () {
    return {
      id: this.uid(),
      text: this.string(''),
      style: this.string(''),
      type: this.string('')
    }
  }
}
