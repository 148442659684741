<template>
  <div class="database column-12">
    <div class="wrap-comm-screen">
      <div class="comm-screen with-border with-border-radius padding-md">
        <div class="output with-border with-border-radius padding-sm text-left">
          <div class="inner-key-panel">

            <div v-if="this.display == 'menu'">
              <h3 class="terminal-heading">Science Terminal</h3>

              <ul>
                <li><a href="#" @click.prevent="switchView('alien')" class="terminal-menu">Alien Database</a></li>
                <li><a href="#" @click.prevent="switchView('research')" class="terminal-menu">Research</a></li>
                <li><a href="#" @click.prevent="switchView('system')" class="terminal-menu">System Info</a></li>
              </ul>

            </div>
            <div v-else-if="this.display == 'alien'">
              <alien-database @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'research'">
              <science-research @switchView="switchView" />
            </div>

              <div v-else-if="this.display == 'system'">
              <terminal-info @switchView="switchView" terminal-id="1030" />
              <icon-footer icon1="transporter" icon2="dna" icon3="alien" />
            </div>

          </div>
        </div>

        <div class="button-panel text-center">
          <span class="panelbutton panelbutton-yellow"></span>
          <span class="panelbutton panelbutton-white"></span>
          <span class="panelbutton panelbutton-green flashing"></span>
          <span class="panelbutton panelbutton-red "></span>
          <span class="panelbutton panelbutton-blue flashing-slow"></span>
          <span class="panelbutton panelbutton-green"></span>
          <span class="panelbutton panelbutton-yellow flashing-fast"></span>
          <span class="panelbutton panelbutton-white flashing-slow"></span>
          <span class="panelbutton panelbutton-blue"></span>
          <span class="panelbutton panelbutton-red"></span>
        </div>

        <span style="color: var(--white);">- Teletraan IV -</span>
      </div>
    </div>
  </div>
</template>

<script>
  import TerminalInfo from './TerminalInfo.vue'
  import AlienDatabase from './AlienDatabase.vue'
  import ScienceResearch from './ScienceResearch.vue'
  import IconFooter from './IconFooter.vue'

  export default {
    name: "ScienceMenu",

    components: {
      TerminalInfo,
      AlienDatabase,
      ScienceResearch,
        IconFooter
    },

    data () {
      return {
        display: 'menu'
      }
    },

    methods: {
      switchView (view) {
        this.display = view
      },

      returnToMenu () {
        this.switchView('menu')
      }
    }
  }
</script>
