<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="boxes-packing" />
        Cargo Hold
      </h3>

      <div>
        All of the ships equipment and supplies are stored here. There is an old spaceball hoop hanging on one wall.
        <span v-if="currentShip.crates > 0">{{ crateCount }} unopened supply crates sit along the wall.</span>


      <div v-if="!wasteDisposalOperational" class="lose-message">
        Karabast! The waste disposal system seems to be malfunctioning. There is foul smelling rubbish everywhere. You should ask your mechanic to repair it.
      </div>

        <p>Current Inventory</p>
        <ul>
          <li>Food: <span class="resource-label">{{ currentShip.food }}</span></li>
          <li>Med Kits: <span class="resource-label">{{ currentShip.med_kits }}</span></li>
          <li>Repair Kits: <span class="resource-label">{{ currentShip.repair_kits }}</span></li>
          <li>Erbium Crystals: <span class="resource-label">{{ currentShip.erbium_crystals }}</span></li>
          <li>Alien Artifacts: <span class="resource-label">{{ currentShip.alien_artifacts }}</span></li>
          <li>Supply Crates: <span class="resource-label">{{ currentShip.crates }}</span></li>
        </ul>
      </div>
      <br>
      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul v-if="wasteDisposalOperational" class="fa-ul">
            <li v-if="currentShip.crates > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="openSupplyCrate()">Open a supply crate</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="spaceBall()">Challenge the Crew to a game of Space Ball</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { applySpaceBallInjury, calculationSpaceBallMoraleBoost, updateCrewMorale, getNumberOfRandomCrewMember, calculateSpaceBallInjuryChance } from '../../utils/crew'
import { updateGame } from '../../utils/game'
import { getRandomRecord } from '../../utils/helpers'
import { getRandomLoot } from '../../utils/loot'
import { addMessage } from '../../utils/message'
import settings from '../../utils/settings'
import { updateShip } from '../../utils/ship'

var converter = require('number-to-words')

export default {
  name: "CargoHold",

  mixins: [
    GameMixin
  ],

  computed: {
    crateCount () {
      return converter.toWords(this.currentShip.crates)
    },

    wasteDisposalOperational () {
      return this.currentShip.waste_disposal == settings.WASTE_DISPOSAL_STATUS_NORMAL
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    openSupplyCrate () {
      this.clearMessages()

      if (this.currentShip.crates > 0) {
        updateShip(this.currentShip, { crates: this.currentShip.crates - 1 })
        addMessage('You pry open one of the crates and look inside.')
        getRandomLoot(this.currentShip, true)

      } else {
        addMessage("All the crates have been opened!", 'fail-message')
      }
    },

    spaceBall () {
      this.clearMessages()

      if (this.currentGame.spaceball_cooldown == 0) {
        const moraleBoost = calculationSpaceBallMoraleBoost()
        const crewMembers = getNumberOfRandomCrewMember(this.currentShip, 3)
        const crewNames = crewMembers.map(crew => { return crew.name }).join(", ").replace(/, ([^,]*)$/, ' and $1')

        addMessage(`You challenge ${crewNames} to a game of spaceball`, '')

        crewMembers.forEach(crew => addMessage(updateCrewMorale(crew, moraleBoost), 'success-message'))

        if (Math.floor(Math.random() * 100 ) < calculateSpaceBallInjuryChance(this.currentGame)) {
          applySpaceBallInjury(getRandomRecord(crewMembers))
        }

        updateGame(this.currentGame, { spaceball_cooldown: settings.SPACEBALL_COOLDOWN_DISTANCE})
      } else {
        addMessage(`Nobody wants to play Spaceball with you...`, 'lose-message')
      }
    }
  }
}
</script>
