<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="fork-knife" />
        Food Rations
      </h3>

      <div>Food rations are currently set at <span class="game-info">{{ rationLevel }}</span>.
      <span v-if="this.currentShip.food > 0">At the current rate, you will run out of food in <span class="game-info">{{ foodDaysRemaining }} days</span>.</span>
      <span v-else>You are out of food.</span>
        There are approximately <span class="game-info">{{ daysRemaining }} days</span> left in your journey.</div>

      <div id="output">
        <div class="choices">
          <div style="padding-bottom: 10px;"><span class="game-info">Filling</span> - Meals are large and generous. Higher rate of morale bonus when performing a success skill check.</div>
          <div style="padding-bottom: 10px;"><span class="game-info">Meager</span> - Meals are small but adequate. No morale bonuses.</div>
          <div style="padding-bottom: 10px;"><span class="game-info">Bare bones</span> - Meals are very small. Crew will slowly lose morale.
          </div>

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="setFoodRations(3)">Set ration level to Filling</a>
            </li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="setFoodRations(2)">Set ration level to Meager</a>
            </li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="setFoodRations(1)">Set ration level to Bare bones</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('kitchen')">Return to the Kitchen</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { rationLevelName, foodDays } from '../../../utils/formulas'
import { getLivingCrewMembers } from '../../../utils/crew'

export default {
  name: "FoodRations",

  mixins: [
    GameMixin
  ],

  computed: {
    rationLevel () {
      return rationLevelName(this.currentGame.food_ration_level)
    },

    foodDaysRemaining () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      return foodDays(this.currentGame.food_ration_level, crewMembers.length, this.currentShip.food)
    },

    daysRemaining () {
      return Math.floor(this.travelDaysRemaining)
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    setFoodRations (level) {
      this.updateFoodRationLevel(level)
      this.changeDisplay("kitchen")
    }
  }
}
</script>
