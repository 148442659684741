import { Model } from '@vuex-orm/core'

export default class Phrase extends Model {
  static entity = 'phrases'

  static fields () {
    return {
      id: this.uid(),
      phrase_type: this.string(''),
      text: this.string(''),
    }
  }
}
