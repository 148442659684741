<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="computer-classic" />
        Science Terminal
      </h3>

      <div class="row">
        <science-menu />
      </div>

      <div class="row">
        <div class="column-5">
          <div id="output">
            <div class="choices">
              <div><a href="#" @click.prevent="returnToLab()">Return to Lab</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { getCrewMemberByPosition } from '../../../utils/crew'
import ScienceMenu from '../../../components/Terminal/ScienceMenu.vue'

export default
  {
  name: "ScienceTerminal",

  components: {
    ScienceMenu
   },

  mixins: [
    GameMixin
  ],

  data () {
    return {
      display: 'alien',
      selectedItem: null
    }
  },

  computed: {
    crewDoctor () {
      return getCrewMemberByPosition('Scientist', this.currentShip)
    },
  },

  methods: {
    returnToLab () {
      this.changeDisplay("lab")
    }
  }
}
</script>
