<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="user-secret" />
        Game Djinn - Cheat Codes
      </h3>

      <cheat-codes-panel ref="ccPanel"></cheat-codes-panel>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-if='this.showRead == true'><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="readInstructions()">{{ warningLabel }}</a></li>
            <li v-if='this.showTaunt == true'><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="taunt()">Taunt Game Djinn</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('secretroom')">Return to Secure Room</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheatCodesPanel from '../../../components/CheatCodesPanel.vue'
import GameMixin from '../../../mixins/GameMixin'
import { unlockAchievement } from '../../../utils/achievements'
import { getLivingCrewMembers, setCrewDisease } from '../../../utils/crew'
import { getDiseaseByName } from '../../../utils/disease'
import { addMessage } from '../../../utils/message'

export default {
  name: "CheatCodes",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      triggerSkulls: false,
      showRead: true,
      showTaunt: false,
      totalTaunts: 0,
      warningNum: 0,
      warningLabel: 'read instructions'
    }
  },

  components: {
    CheatCodesPanel
  },

  created () {

  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    readInstructions () {
      this.clearMessages()

      switch (this.warningNum) {
        case 0:
          this.warningLabel = 'Read warning label'
          addMessage("The Game Djinn is a reality enhancing device. It can create matter from nothing, transform crew into other lifeforms, and so much more.")
          break

        case 1:
          this.warningLabel = 'Continue reading warnings'
          addMessage(
            `Warning: Pregnant women, the elderly, and children under 10 should avoid prolonged exposure to Game Djinn.`,
            'fail-message'
          )
          break

        case 2:
          addMessage(
            `Warning: Game Djinn should be returned to its special container and kept under refrigeration.
            Failure to do so relieves the makers of Game Djinn, Metacortex Inc., of any and all liability.`,
            'fail-message'
          )
          break
        case 3:
          addMessage(
            `Discontinue use of Game Djinn if any of the following occurs:
            Itching, vertigo, dizziness, tingling in extremites, loss of balance and coordination, slurred speech,
            temporary blindness, profuse sweating, or heart palpitations.`,
            'fail-message'
          )
          break

        case 4:
          addMessage(
            `Warning: If Game Djinn begins to smoke, get away immediately. Seek shelter and cover head.`,
            'fail-message'
          )
          break

        case 5:
          this.warningLabel = 'Read final warning'
          addMessage(
            `Warning: Game Djinn may accelerate to dangerous speeds.`,
            'fail-message'
          )
          break

        case 6:
          this.warningLabel = 'Read instructions'
          this.showTaunt = true
          this.showRead = false
          addMessage(
            `Warning: Do not taunt Game Djinn.`,
            'fail-message'
          )
          break
      }

      this.warningNum++
    },

    taunt () {
      this.clearMessages()
      this.totalTaunts++

      const disease = getDiseaseByName('djinns curse')
      getLivingCrewMembers(0, this.currentShip).forEach((crew) => {
        setCrewDisease(crew, disease, 0)
      })

      if (this.totalTaunts === 1) {
        this.$refs.ccPanel.showSkulls()
        unlockAchievement('DJINN')
        addMessage("Oh you did it now...")
      } else if (this.totalTaunts > 3){
        this.$refs.ccPanel.showDjinnCode()
        addMessage("Whaaaa?!?!?")
      } else {
        this.$refs.ccPanel.showSkulls()
        addMessage("Isn't there another way?? He's just a boy!")
      }
    }
  }
}
</script>
