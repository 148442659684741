import { Model } from '@vuex-orm/core'

export default class Name extends Model {
  static entity = 'names'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      type: this.string(''),
      is_plural: this.boolean(false)
    }
  }
}
