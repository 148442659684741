import { Model } from '@vuex-orm/core'
import Crew from './Crew'

export default class Ship extends Model {
  static entity = 'ships'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      fuel_capacity: this.attr(0), // Number of fuel cells.
      fuel_amount: this.attr(0), // Percentage of fuel in the fuel cells.
      engine_power: this.attr(0), // Max speed of the engine. A number between 0 and 10 (can be a fraction). Higher numbers mean a faster speed. Faster engines typically have a higher burn rate.
      engine_efficiency: this.attr(0), // A fractional number between 0 and 1. Higher numbers mean better efficiency (burn less fuel).
      engine_status: this.attr(0), // Current engine health (percentage)
      hull_strength: this.attr(0), // Hull Armor strength - higher is better
      hull_status: this.attr(100), // Current hull health (percentage)
      weapon_power: this.attr(0), // Strength of the weapon systems. A number between 0 and 20. Higher numbers mean more powerful weapons.
      weapon_status: this.attr(100), // Current health of the weapons systems (percentage)
      crew_capacity: this.attr(0), // Max number of crew members the ship can handle. A number between 1 and 20.
      passenger_capacity: this.attr(0), // Max number of passengers the ship can hold. A number between 0 and 100.
      passenger_count: this.attr(0),
      cargo_capacity: this.attr(0), // Max amount of cargo units the ship can carry. A number between 0 and 1000.
      cargo_count: this.attr(0),
      speed: this.attr(0), // How fast the ship is currently moving. A number between 0 and 10 (can be a fraction).

      // systems
      airlock_status: this.string(''),
      waste_disposal: this.string(''),
      weapons_system: this.string(''),
      navigation_system: this.string(''),

      // Resources
      credits: this.number(0), // Monies
      repair_kits: this.number(0), // Used to repair the engine and hull
      med_kits: this.number(0),
      food: this.number(0),
      erbium_crystals: this.number(0),
      alien_artifacts: this.number(0), // sellable loot
      crates: this.number(0),

      condition: this.string('Good'),
      icon: this.string('rocket'),
      crew_members: this.hasMany(Crew, 'ship_id')
    }
  }
}

// Other fields (maybe)
// Fuel Consumption - How fast the ship is burning fuel.
// Fuel consumption per day =  Speed + (Speed x Engine Burn Rate) 2
