<template>
  <div class="row">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon :icon="this.currentEvent.icon" /> {{ this.currentEvent.title }}
      </h3>
      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output" v-if="this.result === null">
        <div class="choices">
          <ul v-for="choice in this.eventChoices" :key="choice.id" class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span>
              <a href="#" @click.prevent="chooseAction(choice)">{{ getChoiceDescription(choice) }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div v-else class="console-continue">

        <button @click="accessComputer()" class="margin-right-sm"><i class="fas fa-computer-classic"></i> Access Computer</button>
        <button @click="continueGame()" class="green">Continue <i class="fas fa-arrow-right"></i> </button>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { createNewGame } from '../utils/game'
import { clearMessages } from '../utils/message'

export default {
  name: "Console",

  mixins: [
    GameMixin
  ],

  methods: {
    chooseAction (choice) {
      this.getResult(choice)
    },

    playAgain () {
      createNewGame(this.currentShip)
    },

    continueGame () {
      clearMessages()

      if (!this.isGameOver()) {
        this.endEvent()
        this.changeDisplay("travel")
      }
    },

    accessComputer () {
      this.clearMessages()
      if (!this.isGameOver()) {
        this.changeDisplay("shipmenu")
      }
     }
  }
}
</script>
