import { Model } from '@vuex-orm/core'
import Choice from './Choice'

export default class Event extends Model {
  static entity = 'events'

  static fields () {
    return {
      id: this.uid(),
      title: this.string(''),
      warning_title: this.string(''),
      description: this.string(''),
      choices: this.hasMany(Choice, 'event_id'),
      event_type: this.string(''),
      probability: this.number(0),
      icon: this.string(''),
      difficulty: this.number(0)
    }
  }
}
