import { addMessage } from './message'
import { updateShip } from './ship'
import { getRandomName } from '../utils/helpers'

var pluralize = require('pluralize')

// random loot table - example: food drop 0 to 30 out of 100
const FOOD = 35
const CREDITS = 55
const REPAIR_KIT = 70
const MED_KIT = 85
const CRYSTAL = 92
const ARTIFACTS = 100

const LEVEL_RARE = 75
const LEVEL_EPIC = 90

export function generateRandomLoot () {
  const lootType = Math.floor(Math.random() * 100)

  switch (true) {
    case lootType < FOOD:
      return foodLoot()
    case lootType < CREDITS:
      return creditLoot()
    case lootType < REPAIR_KIT:
      return repairKitLoot()
    case lootType < MED_KIT:
      return medKitLoot()
    case lootType < CRYSTAL:
      return erbiumCrystalLoot()
    case lootType < ARTIFACTS:
      return alienArtifactLoot()
  }
}

function lootAmount(level, lootType, min, max) {
  return { level: level, lootType: lootType, amount: Math.floor(Math.random() * (max - min + 1) + min) }
}

function foodLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'food', 150, 300)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'food', 50, 150)
    default:
      return lootAmount('common', 'food', 150, 300)
  }
}

function medKitLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'med kit', 3, 6)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'med kit', 2, 4)
    default:
      return lootAmount('common', 'med kit', 1, 2)
  }
}

function repairKitLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'repair kit', 3, 6)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'repair kit', 2, 4)
    default:
      return lootAmount('common', 'repair kit', 1, 2)
  }
}

function erbiumCrystalLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'erbium crystal', 3, 5)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'erbium crystal', 2, 3)
    default:
      return lootAmount('common', 'erbium crystal', 1, 2)
  }
}

function alienArtifactLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'alien artifact', 3, 5)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'alien artifact', 2, 3)
    default:
      return lootAmount('common', 'alien artifact', 1, 2)
  }
}

function creditLoot () {
  const lootLevel = Math.floor(Math.random() * 100)

  switch (true) {
    case lootLevel > LEVEL_EPIC:
      return lootAmount('epic', 'credits', 1000, 3000)
    case lootLevel > LEVEL_RARE:
      return lootAmount('rare', 'credits', 300, 800)
    default:
      return lootAmount('common', 'credits', 100, 400)
  }
}

export function getRandomLoot (ship, includeJunk = false) {
  const loot = generateRandomLoot()

  switch (loot.lootType)
  {
    case 'food':
      updateShip(ship, { food: ship.food + loot.amount })
      break
    case 'med kit':
      updateShip(ship, { med_kits: ship.med_kits + loot.amount })
      break
    case 'repair kit':
      updateShip(ship, { repair_kits: ship.repair_kits + loot.amount })
      break
    case 'erbium crystal':
      updateShip(ship, { erbium_crystals: ship.erbium_crystals + loot.amount })
      break
    case 'alien artifact':
      updateShip(ship, { alien_artifacts: ship.alien_artifacts + loot.amount })
      break
    case 'credits':
      updateShip(ship, { credits: ship.credits + loot.amount })
      break
  }

  const lootTypeLabel = loot.lootType == 'food' ? 'food' : pluralize(loot.lootType, loot.amount)
  const junk = includeJunk ? `${getRandomName('junk')} and ` : ''
  addMessage(`${loot.level == 'epic' ? 'Epic! ' : ''}You found ${junk}${loot.amount} ${lootTypeLabel}!`, 'success-message')
}
