<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title"><font-awesome-icon icon="screwdriver-wrench" /> Engine Room</h3>

      <div v-if="crewMechanic">Mechanic {{ crewMechanic.name }} is standing by ready to make repairs the ship.</div>
      <br />

      <div>
        The <span class="ship-name">{{ currentShip.name }}</span> is in <span class="game-info">{{ currentShip.condition }}</span> condition.
      </div>
      <div>
        Your ship's hull is at <span class="game-info">{{ this.currentShip.hull_status }}%</span>
      </div>
      <div>
        Your ship's engine is at <span class="game-info">{{ this.currentShip.engine_status }}%</span>
      </div>

      <div>
        Airlock: <span :class="airlockCssClass">{{ airlockStatus }}</span>
      </div>
      <div>
        Waste Disposal: <span :class="wasteDisposalCssClass">{{ wasteDisposalStatus }}</span>
      </div>
      <div>
        Weapons System: <span :class="weaponsSystemCssClass">{{ weaponsSystemStatus }}</span>
      </div>
      <div>
        Navigation System: <span :class="navigationSystemCssClass">{{ navigationSystemStatus }}</span>
      </div>

      <br />
      <div>
        You have <span class="game-info">{{ this.currentShip.repair_kits }}</span> repair kits.
      </div>

      <br />
      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">
          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('repairhull')">Repair the hull</a>
            </li>
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('repairengine')">Repair the engine</a>
            </li>
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('divertpower')">Divert weapon power to gain fuel</a>
            </li>
            <li v-if="crewMechanic">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="buildRepairKit()">Piece together a Repair Kit</a>
            </li>
            <li v-if="showAirlockRepair">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="systemRepair('airlock')">Repair the airlock</a>
            </li>
            <li v-if="showWasteDisposalRepair">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="systemRepair('waste disposal')">Repair Waste Disposal</a>
            </li>
            <li v-if="showWeaponsSystemRepair">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="systemRepair('weapons system')">Repair Weapons System</a>
            </li>
            <li v-if="showNavigationSystemRepair">
              <span class="fa-li"><i class="fas fa-square"></i></span
              ><a href="#" @click.prevent="systemRepair('navigation system')">Repair Nagivation System</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GameMixin from '../../../mixins/GameMixin'
  import RepairMixin from '../../../mixins/RepairMixin'
  import { getCrewMemberByPosition } from '../../../utils/crew'
  import { canStartJob, daysToCompleteCurrentJob, mechanicJobStatus, startJob } from '../../../utils/job'
  import { addMessage } from '../../../utils/message'
  import { getRandomCaptainGreeting } from '../../../utils/phrases'
  import settings from '../../../utils/settings'

  export default {
    name: 'EngineRoom',

    mixins: [GameMixin, RepairMixin],

    created() {
      if (this.crewMechanic) {
        this.mechanicGreeting()
      } else {
        addMessage('The engine room feels empty without a mechanic.', '')
      }
    },

    computed: {
      crewMechanic() {
        return getCrewMemberByPosition('Mechanic', this.currentShip)
      },

      wasteDisposalCssClass() {
        return this.currentShip.waste_disposal === 'online' ? 'game-info' : 'damaged-system'
      },

      airlockCssClass() {
        return this.currentShip.airlock_status === 'online' ? 'game-info' : 'damaged-system'
      },

      weaponsSystemCssClass() {
        return this.currentShip.weapons_system === 'online' ? 'game-info' : 'damaged-system'
      },

      navigationSystemCssClass() {
        return this.currentShip.navigation_system === 'online' ? 'game-info' : 'damaged-system'
      },

      airlockStatus() {
        return this.currentShip.airlock_status
      },

      wasteDisposalStatus() {
        return this.currentShip.waste_disposal
      },

      weaponsSystemStatus() {
        return this.currentShip.weapons_system
      },

      navigationSystemStatus() {
        return this.currentShip.navigation_system
      },

      showAirlockRepair() {
        return this.crewMechanic && this.currentShip.airlock_status == settings.AIRLOCK_STATUS_DAMAGED && this.currentShip.repair_kits > 0
      },

      showWasteDisposalRepair() {
        return this.crewMechanic && this.currentShip.waste_disposal == settings.WASTE_DISPOSAL_STATUS_DAMAGED && this.currentShip.repair_kits > 0
      },

      showWeaponsSystemRepair() {
        return this.crewMechanic && this.currentShip.weapons_system == settings.WEAPONS_SYSTEM_STATUS_DAMAGED && this.currentShip.repair_kits > 0
      },

      showNavigationSystemRepair() {
        return this.crewMechanic && this.currentShip.navigation_system == settings.NAVIGATION_SYSTEM_STATUS_DAMAGED && this.currentShip.repair_kits > 0
      }
    },

    methods: {
      gotoRoom(room) {
        this.clearMessages()
        this.changeDisplay(room)
      },

      buildRepairKit() {
        this.clearMessages()

        if (this.crewMechanic) {
          if (canStartJob(this.crewMechanic)) {
            const scanTime = Math.floor(Math.random() * 4000 + settings.CRAFTING_TIME_REPAIR_KIT)
            startJob(this.crewMechanic, settings.JOB_REPAIRKIT, scanTime, 1)
            addMessage(`${this.crewMechanic.name} starts trying to construct a repair kit out of spare parts.`)
          } else {
            this.currentJobMessage(this.crewMechanic)
          }
        }
      },

      airlockRepair() {
        this.clearMessages()
        this.repairAirlock(1)
      },

      systemRepair(systemName) {
        if (this.crewMechanic) {
          this.clearMessages()
          this.repairSystem(systemName, 1)
        }
      },

      mechanicGreeting() {
        const jobStatus = mechanicJobStatus(this.crewMechanic)
        addMessage(getRandomCaptainGreeting(this.crewMechanic, jobStatus), '')
      },

      currentJobMessage() {
        const days = daysToCompleteCurrentJob(this.crewMechanic)
        addMessage(`${this.crewMechanic.name} is already working on a job ${days > 1 ? `[${days} days remaining]` : '[ Less than 1 day remaining]'}`)
      }
    }
  }
</script>
