<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="people-arrows-left-right" />
        Crew Cloning
      </h3>

      <div v-if="crewScientist">
        Scientist {{ crewScientist.name}} laughs maniacally and says <span class='crew-speech'>"I am {{ successRate }}% sure this will work!"</span>
      </div>
      <br>
      <div>You have <span class="game-info">{{ this.currentShip.erbium_crystals }}</span> Erbium Crystals.</div>
      <br>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-for="crew in crewMembers" :key="crew.id">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="cloneCrewMember(crew.id)">Clone {{crew.name}} [1 Erbium Crystal]</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('lab')">Return to the Science Lab</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { addCrew, getCrewMemberById, getCrewMemberByPosition, getLivingCrewMembers, transformCrewIntoMonkey, updateCrewHealth } from '../../../utils/crew'
import settings from '../../../utils/settings'
import { addMessage } from '../../../utils/message'
import { cloneCrewMember } from '../../../utils/crew'
import { cloningSuccessRate } from '../../../utils/science'
import { updateShip } from '../../../utils/ship'
import { zeroLimit } from '../../../utils/helpers'

export default {
  name: "Experiments",

  mixins: [
    GameMixin
  ],

  created () {},

  computed: {
    crewScientist () {
      return getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
    },

    crewMembers () {
      return getLivingCrewMembers(0, this.currentShip)
    },

    successRate () {
      return cloningSuccessRate(this.crewScientist)
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    cloneCrewMember (crewId) {
      this.clearMessages()
      const crew = getCrewMemberById(crewId)

      if (this.currentShip.erbium_crystals > 0) {

        const success = (Math.floor(Math.random() * 100) <= this.successRate)

        updateShip(this.currentShip, { erbium_crystals: zeroLimit(this.currentShip.erbium_crystals - 1)})

        if (success == true) {
          addCrew(cloneCrewMember(crew, this.currentShip.id))
          addMessage(`${this.crewScientist.name} successfully clones ${crew.name}!`, 'gain-message')
        } else {

          const failure = Math.floor(Math.random() * 3)

          switch (failure) {
            case 0:
              transformCrewIntoMonkey(crew)
              addMessage(`The experiment fails and your erbium crystal is destroyed. ${crew.name} is transformed into a Moon Monkey during the experiment.`, 'lose-message')
              break

            default:
              addMessage(`The experiment fails and your erbium crystal is destroyed. ${crew.name} is injured during the experiment.`, 'lose-message')
              addMessage(updateCrewHealth(crew, -Math.floor(Math.random() * 15) + 5), 'lose-message')
              break
          }

        }

        addMessage(`Lose 1 erbium crystal`, 'lose-message')
      } else {
        addMessage(`You don't have enough erbium crystals for that.`, 'lose-message')
      }
    }
  }
}
</script>
