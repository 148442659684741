<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="battery-bolt" /> Divert Power
      </h3>

      <div>Fuel is at {{this.currentShip.fuel_amount}}% and weapons are at {{this.currentShip.weapon_status}}%.</div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div style="margin-bottom: 10px;">You can covert weapon power into fuel at 50% efficiency.</div>
      <div id="output">
        <ul class="fa-ul">
          <li v-if="this.currentShip.weapon_status >= 20"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="divertPower(20)">Divert 20% weapon power</a></li>
          <li v-if="this.currentShip.weapon_status >= 40"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="divertPower(40)">Divert 40% weapon power</a></li>
          <li v-if="this.currentShip.weapon_status >= 60"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="divertPower(60)">Divert 60% weapon power</a></li>
          <li v-if="this.currentShip.weapon_status >= 80"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="divertPower(80)">Divert 80% weapon power</a></li>
        </ul>

        <div><a href="#" @click.prevent="returnToEngineRoom()">Return to Engine Room</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { clearMessages } from '../../../utils/message'

export default {
  name: "DivertPower",

  mixins: [
    GameMixin
  ],

  methods: {
    returnToEngineRoom () {
      clearMessages()
      this.changeDisplay("engineroom")
    },

    divertPower (amount) {
      this.divertWeaponPower(amount)
      this.changeDisplay("engineroom")
    }
  }
}
</script>
