<template>
  <p style="border-top: 1px solid;">
    <font-awesome-icon class="fa-fw" icon="user" />
    <font-awesome-icon class="fa-fw" :icon="icon1" />
    <font-awesome-icon class="fa-fw" :icon="icon2" />
    <font-awesome-icon class="fa-fw" :icon="icon3" />
  </p>
</template>

<script>
  export default {
    name: "IconFooter",

    props: {
      icon1: String,
      icon2: String,
      icon3: String
    }
  }
</script>