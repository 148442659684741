import Achievement from '../store/models/Achievement'
import settings from './settings'

export function checkResourceAchievement (ship) {
  if (ship.credits > settings.CREDIT_ACHIEVEMENT_AMOUNT) {
    unlockAchievement('BIGBANK')
  }

  if (ship.alien_artifacts > settings.ARTIFACT_ACHIEVEMENT_AMOUNT) {
    unlockAchievement('ARTIFACTS')
  }

  if (ship.food > settings.FOOD_ACHIEVEMENT_AMOUNT) {
    unlockAchievement('FOOD')
  }

  if (ship.erbium_crystals > settings.CRYSTAL_ACHIEVEMENT_AMOUNT) {
    unlockAchievement('CRYSTALS')
  }
}

export function unlockAchievement (code) {
  Achievement.update({
    where: (achievement) => {
      return achievement.code === code
    },
    data: { unlocked: true }
  })
}

export function lockAchievement (code) {
  Achievement.update({
    where: (achievement) => {
      return achievement.code === code
    },
    data: { unlocked: false }
  })
}

export function resetAchievements () {
  Achievement.query().all().forEach(achievement => {
    Achievement.update({
      where: achievement.id,
      data: { unlocked: achievement.initial_state === 'unlocked' ? true : false }
    })
  })
}