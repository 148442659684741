<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="notes-medical" />
        Sick Bay
      </h3>

      <div v-if="crewDoctor">
        {{ crewDoctor.name}} is standing by to treat crew injuries.
      </div>
      <br>
      <div>
        You have <span class="game-info">{{this.currentShip.med_kits}} med kits</span>.
        Having a doctor on the crew improves the effectiveness of a Med Kit (based on medical ability skill).
      </div>

      <br>
      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div class="" v-if="sickCrewMembers.length == 0">
        <br>All crew members are healthy!
      </div>

      <div id="output">
        <div class="choices">
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('medicalterminal')">Access Medical Terminal</a></li>
            <li v-for="crew in sickCrewMembers" :key="crew.id">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a v-if="crew.disease_duration > 0" href="#" @click.prevent="treatCrewMember(crew.id)">Treat {{crew.name}} for {{ crew.disease.name }}</a>
              <a v-else href="#" @click.prevent="treatCrewMember(crew.id)">Treat {{crew.name}}'s injuries</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { getSickCrewMembers, getCrewMemberByPosition } from '../../../utils/crew'
import { addMessage, clearMessages } from '../../../utils/message'
import { getRandomCaptainGreeting } from '../../../utils/phrases'

export default {
  name: "SickBay",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewDoctor) {
      addMessage(getRandomCaptainGreeting(this.crewDoctor), '')
    } else {
      addMessage('Sick bay feels empty without a doctor.', '')
    }
  },

  computed: {
    crewDoctor () {
      return getCrewMemberByPosition('Doctor', this.currentShip)
    },

    sickCrewMembers () {
      return getSickCrewMembers(this.currentShip)
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    treatCrewMember (crewId) {
      clearMessages()

      if (this.currentShip.med_kits > 0) {
        this.useMedKit(crewId)
      } else {
        addMessage('You have run out of med kits.', 'lose-message')
      }
    }
  }
}
</script>
