import settings from "./settings"

export function doctorHealingMultipler (crewDoctor) {
  return crewDoctor.medical * .2 + 1
}

export function mechanicRepairMultipler (crewMechanic) {
  return crewMechanic.mechanics * .2 + 1
}

export function hullRepairCostCalculation (shipCondition, hullStatus) {
  if (hullStatus == 100) {
    return 0
  } else {
    switch (shipCondition) {
      case "excellent":
        return (100 - hullStatus) * 10 + 150
      case "good":
        return (100 - hullStatus) * 10 + 250
      case "poor":
        return (100 - hullStatus) * 10 + 450
      case "critical":
        return (100 - hullStatus) * 10 + 650
      default:
        return 0
    }
  }
}

export function engineRepairCostCalculation (shipCondition, engineStatus) {
  if (engineStatus == 100) {
    return 0
  } else {
    switch (shipCondition) {
      case "excellent":
        return (100 - engineStatus) * 11 + 100
      case "good":
        return (100 - engineStatus) * 11 + 200
      case "poor":
        return (100 - engineStatus) * 11 + 400
      case "critical":
        return (100 - engineStatus) * 11 + 600
      default:
        return 0
    }
  }
}

export function airlockRepairCostCalculation (ship) {
  return (ship.airlock_status == settings.AIRLOCK_STATUS_NORMAL ? 0 : settings.ITEM_BASE_PRICE_AIRLOCK_REPAIR)
}

export function wasteDisposalRepairCostCalculation (ship) {
  return (ship.waste_disposal == settings.WASTE_DISPOSAL_STATUS_NORMAL ? 0 : settings.ITEM_BASE_PRICE_WASTE_DISPOSAL_REPAIR)
}

export function weaponsSystemRepairCostCalculation (ship) {
  return (ship.weapons_system == settings.WEAPONS_SYSTEM_STATUS_NORMAL ? 0 : settings.ITEM_BASE_PRICE_WEAPONS_SYSTEM_REPAIR)
}

export function navigationSystemRepairCostCalculation (ship) {
  return (ship.navigation_system == settings.NAVIGATION_SYSTEM_STATUS_NORMAL ? 0 : settings.ITEM_BASE_PRICE_NAVIGATION_SYSTEM_REPAIR)
}

export function infirmaryCostCalculation (crew) {
  return Math.floor((100 - crew.health) * 5) + (crew.has_disease ? 250 : 0) + (crew.status == settings.STATUS_PANIC ? 200 : 0)
}

export function refuelCostCalculation (fuelStatus) {
  return Math.floor((100 - fuelStatus) * settings.FUEL_PRICE_PER_PERCENT)
}

export function fuelCostCalculation (refuelAmount) {
  return Math.floor(refuelAmount * settings.FUEL_PRICE_PER_PERCENT)
}

export function weaponRechargeCostCalculation (weaponStatus) {
  return Math.floor((100 - weaponStatus) * settings.WEAPON_PRICE_PER_PERCENT)
}

export function fuelConsumptionCalculation (ship) {
  return (ship.speed - ship.engine_efficiency) / ship.engine_efficiency
}

export function distanceTraveledCalculation (speed, travelTime) {
  return speed * travelTime
}

export function foodDays (rationLevel, crewCount, totalFood) {
  const foodConsumedPerDay = rationLevel * crewCount
  return Math.floor(totalFood / foodConsumedPerDay)
}

export function rationLevelName (foodRationLevel) {
  switch (foodRationLevel)
  {
    case 1:
      return 'Bare bones'
    case 2:
      return 'Meager'
    case 3:
      return 'Filling'
  }

  return 'Unknown'
}

export function fuelDaysRemaining (ship) {
  const fuelBurnedPerDay = fuelConsumptionCalculation(ship)
  return ship.fuel_amount / fuelBurnedPerDay
}

function getSellPriceMultiplier (difficulty) {
  switch (difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.GAME_DIFFICULTY_SELL_MULTIPLIER_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.GAME_DIFFICULTY_SELL_MULTIPLIER_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.GAME_DIFFICULTY_SELL_MULTIPLIER_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.GAME_DIFFICULTY_SELL_MULTIPLIER_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.GAME_DIFFICULTY_SELL_MULTIPLIER_FOREVER
  }
}

export function itemPurchasePriceCalculation (item) {
  switch (item) {
    case 'repairkit':
      return settings.ITEM_BASE_PRICE_REPAIRKIT

    case 'medkit':
      return settings.ITEM_BASE_PRICE_MEDKIT

    case 'food':
      return settings.ITEM_BASE_PRICE_FOOD

    case 'erbium':
      return settings.ITEM_BASE_PRICE_ERBIUM

    case 'artifact':
      return settings.ITEM_BASE_PRICE_ARTIFACT

      case 'crate':
      return settings.ITEM_BASE_PRICE_CRATE
  }
}

export function itemSellAmountCalculation (item, quantity, game) {
  switch (item) {
    case 'repairkit':
      return settings.ITEM_BASE_PRICE_REPAIRKIT * quantity * getSellPriceMultiplier(game.difficulty)

    case 'medkit':
      return settings.ITEM_BASE_PRICE_MEDKIT * quantity * getSellPriceMultiplier(game.difficulty)

    case 'food':
      return settings.ITEM_BASE_PRICE_FOOD * quantity * getSellPriceMultiplier(game.difficulty)

    case 'erbium':
      return settings.ITEM_BASE_PRICE_ERBIUM * quantity * getSellPriceMultiplier(game.difficulty)

    case 'artifact':
      return settings.ITEM_BASE_PRICE_ARTIFACT * quantity * getSellPriceMultiplier(game.difficulty)

    case 'crate':
      return settings.ITEM_BASE_PRICE_CRATE * quantity * getSellPriceMultiplier(game.difficulty)
  }
}

export function hungerStrikeThreshold (crewCount) {
  return crewCount * 10 + (Math.random() * 100)
}

export function gameModeDifficultyAdjustment (game) {
  switch (game.difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.DIFFICULTY_ADJUSTMENT_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.DIFFICULTY_ADJUSTMENT_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.DIFFICULTY_ADJUSTMENT_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.DIFFICULTY_ADJUSTMENT_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.DIFFICULTY_ADJUSTMENT_FOREVER
  }
}