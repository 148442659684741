import ResearchData from '../store/models/ResearchData'

export function getResearchDataByType (researchType) {
  return ResearchData.query().where((d) => {
    return d.type == researchType && d.status == 'unlocked'
  }).orderBy('title').all()
}

export function unlockResearchData (title) {
  const researchData = ResearchData.query().where((d) => {
    return d.title == title
  }).first()

  return ResearchData.update({
    where: researchData.id,
    data: { status: 'unlocked' }
  })
}

export function resetResearchData () {
  ResearchData.update({
    where: (research) => {
      return research.status === true
    },
    data: { unlocked: false }
  })
}
