import Plausible from 'plausible-tracker'

export default {
  install: (app, options) => {
    const plausible = Plausible(options)

    // Add plausible to global properties
    app.config.globalProperties.$plausible = plausible
    app.provide('plausible')
  }
}
