<template>
  <div class="cheatcode desktop:display-flex">
    <div class="wrap-key-panel desktop:margin-right-xs margin-bottom-xs">
      <div class="key-panel with-border with-border-radius padding-xs" style="background-color: var(--black);">
        <div class="cheatcode-choices">
          <span class="choice card" :class="codeComplete()">
            <font-awesome-icon v-if="this.choiceIcon1" :icon="['fad', this.choiceIcon1]" class="size-4xl"  />
          </span>
          <span class="choice card" :class="codeComplete()">
            <font-awesome-icon v-if="this.choiceIcon2" :icon="['fad', this.choiceIcon2]" class="size-4xl" />
          </span>
          <span class="choice card" :class="codeComplete()">
            <font-awesome-icon v-if="this.choiceIcon3" :icon="['fad', this.choiceIcon3]" class="size-4xl" />
          </span>
          <span class="choice card" :class="codeComplete()">
            <font-awesome-icon v-if="this.choiceIcon4" :icon="['fad', this.choiceIcon4]" class="size-4xl" />
          </span>
        </div>
        <div class="inner-key-panel">
          <span class="button" @click="inputValue('meat')"><i class="far fa-lg fa-meat"></i></span>
          <span class="button" @click="inputValue('robot')"><i class="far fa-lg fa-robot"></i></span>
          <span class="button" @click="inputValue('user-chef')"><i class="far fa-lg fa-user-chef"></i></span>
          <span class="button" @click="inputValue('bolt')"><i class="fas fa-lg fa-bolt"></i></span>
          <span class="button" @click="inputValue('kit-medical')"><i class="far fa-lg fa-kit-medical"></i></span>
          <span class="button" @click="inputValue('screwdriver-wrench')"><i class="far fa-lg fa-screwdriver-wrench"></i></span>
          <span class="button" @click="inputValue('rocket')"><i class="far fa-lg fa-rocket"></i></span>
          <span class="button" @click="inputValue('user-doctor')"><i class="far fa-lg fa-user-doctor"></i></span>

          <span class="button" @click="inputValue('dna')"><i class="far fa-lg fa-dna"></i></span>
          <span class="button" @click="inputValue('user-visor')"><i class="far fa-lg fa-user-visor"></i></span>
          <span class="button" @click="inputValue('fire')"><i class="far fa-lg fa-fire"></i></span>
          <span class="button" @click="inputValue('radiation')"><i class="far fa-lg fa-radiation"></i></span>
          <span class="button" @click="inputValue('moon')"><i class="far fa-lg fa-moon"></i></span>
          <span class="button" @click="inputValue('gift')"><i class="far fa-lg fa-gift"></i></span>
          <span class="button" @click="inputValue('skull')"><i class="far fa-lg fa-skull"></i></span>
          <span class="button" @click="inputValue('battery-bolt')"><i class="far fa-lg fa-battery-bolt"></i></span>

          <span class="button" @click="inputValue('user-bounty-hunter')"><i class="far fa-lg fa-user-bounty-hunter"></i></span>
          <span class="button" @click="inputValue('ufo')"><i class="far fa-lg fa-ufo"></i></span>
          <span class="button" @click="inputValue('money-bill-1')"><i class="far fa-lg fa-money-bill-1"></i></span>
          <span class="button" @click="inputValue('alien')"><i class="far fa-lg fa-alien"></i></span>
          <span class="button" @click="inputValue('transporter')"><i class="far fa-lg fa-transporter"></i></span>
          <span class="button" @click="inputValue('tablets')"><i class="far fa-lg fa-tablets"></i></span>
          <span class="button" @click="inputValue('user-helmet-safety')"><i class="far fa-lg fa-user-helmet-safety"></i></span>
          <span class="button" @click="inputValue('crystal-ball')"><i class="far fa-lg fa-crystal-ball"></i></span>

          <span class="button" @click="inputValue('cake-candles')"><i class="far fa-lg fa-cake-candles"></i></span>
          <span class="button" @click="inputValue('cat-space')"><i class="far fa-lg fa-cat-space"></i></span>
          <span class="button" @click="inputValue('vial')"><i class="far fa-lg fa-vial"></i></span>
          <span class="button" @click="inputValue('badge-sheriff')"><i class="far fa-lg fa-badge-sheriff"></i></span>
          <span class="button" @click="inputValue('user-astronaut')"><i class="far fa-lg fa-user-astronaut"></i></span>
          <span class="button" @click="inputValue('gauge-max')"><i class="far fa-lg fa-gauge-max"></i></span>
          <span class="button" @click="inputValue('monkey')"><i class="far fa-lg fa-monkey"></i></span>
          <span class="button" @click="inputValue('user-pilot')"><i class="far fa-lg fa-user-pilot"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { clearMessages, addMessage } from '../utils/message'
import { unlockCheat } from '../utils/cheats'
import Cheat from "../store/models/Cheat"
import { getRandomPhrase } from '../utils/phrases'
import { unlockResearchData } from '../utils/research'

export default {
  name: "CheatCodesPanel",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      inputLocked: false,
      eventTimer: '',
      choiceIcon1: '',
      choiceIcon2: '',
      choiceIcon3: '',
      choiceIcon4: ''
    }
  },

  computed: {
    isValidCheat () {
      const cheat = this.getCheat()
      return (cheat != null)
    }
  },

  created () {
    unlockResearchData('Game Djinn')
  },

  methods: {
    codeComplete () {
      if (this.choiceIcon4 != '') {
        return this.isValidCheat ? 'fa-flip cheatcode-success' : 'fa-fade cheatcode-failure'
      } else {
        return ''
      }
    },

    inputValue (value) {
      if (!this.inputLocked) {
        if (this.choiceIcon1 == '') {
          this.choiceIcon1 = value
        } else if (this.choiceIcon2 == '') {
          this.choiceIcon2 = value
        } else if (this.choiceIcon3 == '') {
          this.choiceIcon3 = value
        } else  {
          this.inputLocked = true
          this.choiceIcon4 = value
          clearMessages()
          this.eventTimer = setInterval(this.commEvent, 2000)
        }
      }
    },

    getCheat() {
      const cheat = Cheat.query().where((c) => {
          return (c.icon1 == this.choiceIcon1 && c.icon2 == this.choiceIcon2 && c.icon3 == this.choiceIcon3 && c.icon4 == this.choiceIcon4)
        }).first()

      return cheat
    },

    commEvent () {
      if (this.isValidCheat) {
        const cheat = this.getCheat()
        unlockCheat(this.currentGame, this.currentShip, cheat)
      } else {
        addMessage(getRandomPhrase('invalid-cheat').text, 'lose-message')
      }

      this.choiceIcon1 = ''
      this.choiceIcon2 = ''
      this.choiceIcon3 = ''
      this.choiceIcon4 = ''
      clearInterval(this.eventTimer)

      this.inputLocked = false
    },

    showDjinnCode () {
      this.choiceIcon1 = 'question'
      this.choiceIcon2 = 'transporter'
      this.choiceIcon3 = 'vial'
      this.choiceIcon4 = 'monkey'

      this.eventTimer = setInterval(this.clear, 4000)
    },

    showSkulls () {
      this.choiceIcon1 = 'skull'
      this.choiceIcon2 = 'skull'
      this.choiceIcon3 = 'skull'
      this.choiceIcon4 = 'skull'

      this.eventTimer = setInterval(this.clear, 2000)
    },

    clear () {
      this.choiceIcon1 = ''
      this.choiceIcon2 = ''
      this.choiceIcon3 = ''
      this.choiceIcon4 = ''
      clearInterval(this.eventTimer)
    }
  }
}
</script>
