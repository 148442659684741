<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="flask" />
        Lab
      </h3>

      <div v-if="crewScientist">
        Scientist {{ crewScientist.name}} is standing by ready to make science.
      </div>
      <br>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('scienceterminal')">Access Science Terminal</a></li>
            <li v-if="crewScientist"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="makeMedKits()">Synthesize Med Kit</a></li>
            <li v-if="crewScientist"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="scanForCrystal()">Scan for erbium crystals</a></li>
            <li v-if="crewScientist && this.currentShip.erbium_crystals > 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('experiments')">Experiment with Erbium Crystals</a>
            </li>
            <li v-if="this.canConvertRobot">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('convertrobot')">Scrap robot crew for parts</a>
            </li>
            <li v-if="crewScientist && this.currentShip.alien_artifacts > 0 && this.currentGame.scientist_cooldown === 0">
              <span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="artifactAnalysis()">Analyse the Alien Artifacts</a>
            </li>

          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { getCrewMemberByPosition, getCrewMembersBySpecies } from '../../../utils/crew'
import { canStartJob, startJob, daysToCompleteCurrentJob, scientistJobStatus } from '../../../utils/job'
import settings from '../../../utils/settings'
import { addMessage } from '../../../utils/message'
import { getRandomChatByType, getRandomCaptainGreeting } from '../../../utils/phrases'
import { analyseArtifact } from '../../../utils/science'

export default {
  name: "Lab",

  mixins: [
    GameMixin
  ],

  created () {
    if (this.crewScientist) {
      if (this.crewScientist.title == 'Mad Scientist') {
        addMessage(getRandomChatByType(this.crewScientist, 'madscientist-greeting'), '')
      } else {
        this.scientistGreeting()
      }
    } else {
      addMessage('The lab feels empty without a scientist.', '')
    }
  },

  computed: {
    crewScientist () {
      return getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)
    },

    canConvertRobot () {
      if (this.crewScientist) {
        const robotCrewMembers = getCrewMembersBySpecies(this.currentShip, settings.SPECIES_ROBOT)
        return (this.crewScientist.title == 'Mad Scientist' && robotCrewMembers.length > 0)
      } else {
        return false
      }
    }
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    makeMedKits () {
      this.clearMessages()

      if (canStartJob(this.crewScientist)) {
        startJob(this.crewScientist, settings.JOB_MEDKIT, settings.CRAFTING_TIME_MEDKIT)
        addMessage(`${this.crewScientist.name} starts the difficult process of synthesizing a medkit.`)
      } else {
        this.currentJobMessage(this.crewScientist)
      }
    },

    scanForCrystal () {
      this.clearMessages()

      if (canStartJob(this.crewScientist)) {
        const scanTime = Math.floor(Math.random() * 5000 + settings.CRAFTING_TIME_CRYSTAL)
        startJob(this.crewScientist, settings.JOB_CRYSTAL, scanTime)
        addMessage(`${this.crewScientist.name} sends out scanners in search of erbium crystals.`)
      } else {
        this.currentJobMessage(this.crewScientist)
      }
    },

    artifactAnalysis () {
      this.clearMessages()
      analyseArtifact(this.currentGame, this.crewScientist)
    },

    scientistGreeting () {
      const jobStatus = scientistJobStatus(this.crewScientist)
      addMessage(getRandomCaptainGreeting(this.crewScientist, jobStatus), '')
    },

    currentJobMessage () {
      const days = daysToCompleteCurrentJob(this.crewScientist)
      addMessage(`${this.crewScientist.name} is already working on a job ${days > 1 ? `[${days} days remaining]` : '[ Less than 1 day remaining]'}`)
    }

  }
}
</script>
