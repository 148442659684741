<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="satellite-dish" />
        Communicator
      </h3>

      <key-pad></key-pad>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">

          </ul>

          <div><a href="#" @click.prevent="gotoRoom('bridge')">Return to Bridge</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KeyPad from '../../../components/KeyPad.vue'
import GameMixin from '../../../mixins/GameMixin'

export default {
  name: "Communicator",

  mixins: [
    GameMixin
  ],

  components: {
    KeyPad
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    }

  }
}
</script>
