import { Model } from '@vuex-orm/core'

export default class Achievement extends Model {
  static entity = 'achievements'

  static fields () {
    return {
      id: this.uid(),
      code: this.string(''),
      icon: this.string(''),
      text: this.string(''),
      unlocked: this.boolean(false),
      initial_state: this.string('locked')
    }
  }
}
