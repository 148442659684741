import { Model } from '@vuex-orm/core'
import ComMessage from './ComMessage'

export default class ComMessageChoice extends Model {
  static entity = 'commessagechoices'

  static fields () {
    return {
      id: this.uid(),
      selector: this.string(''),
      text: this.string(''),
      result_text: this.string(''),
      commessage_id: this.attr(null),
      commessage: this.belongsTo(ComMessage, 'commessage_id'),
      fuel_cost: this.number(),
      hull_cost: this.number(),
      weapons_cost: this.number(),
      engines_cost: this.number(),
      credit_cost: this.number(),
      repair_kits_cost: this.number(),
      med_kits_cost: this.number(),
      food_cost: this.number(),
      erbiumcrystal_cost: this.number(),
      special: this.string(''),
    }
  }
}
