<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="computer-classic" /> Ship Menu
      </h3>

      <div>
        <span v-if="displayDaysRemaining">Approximately {{ daysRemaining }} days remain until you arrive on <span class="planet-name">{{ this.currentGame.destination_planet }}</span>.</span>

        At your current speed, you have enough fuel for <span class="game-info">{{ fuelRemaining }}</span> days of travel.
        The <span class="ship-name">{{ currentShip.name }}</span> is in {{ currentShip.condition }} condition.
        Food ration level set to <span class="game-info">{{ rationLevel }}</span>. At the current rate, you will run out of food in <span class="game-info">{{ foodDaysRemaining }} days</span>
      </div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices" v-if="this.currentGame.status == 'travel'">
          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">

            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('airlock')">Airlock</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('bridge')">Bridge</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('cargohold')">Cargo Hold</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('engineroom')">Engine Room</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('kitchen')">Kitchen</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('lab')">Science Lab</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('sickbay')">Sick Bay</a></li>

          </ul>

          <div><a href="#" @click.prevent="continueJourney()">Continue Journey</a></div>

        </div>
        <div class="choices lose-message" v-else>
          I’m sorry Dave, I’m afraid I can’t do that. You are currently at an outpost.
          <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to the Outpost</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { rationLevelName, foodDays, fuelDaysRemaining } from '../../utils/formulas'
import { getLivingCrewMembers } from '../../utils/crew'
import settings from '../../utils/settings'

export default {
  name: "ShipMenu",

  mixins: [
    GameMixin
  ],

  computed: {
    rationLevel () {
      return rationLevelName(this.currentGame.food_ration_level)
    },

    foodDaysRemaining () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      return foodDays(this.currentGame.food_ration_level, crewMembers.length, this.currentShip.food)
    },

    daysRemaining () {
      return Math.floor(this.travelDaysRemaining)
    },

    fuelRemaining () {
      return Math.floor(fuelDaysRemaining(this.currentShip))
    },

    displayDaysRemaining () {
      return this.currentGame.difficulty != settings.GAME_DIFFICULTY_FOREVER
    },
  },

  methods: {
    continueJourney () {
      this.travel()
      this.changeDisplay("travel")
    },

    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    }
  }

}
</script>
