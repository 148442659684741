import { Model } from '@vuex-orm/core'
import Event from './Event'
import Result from './Result'

export default class Choice extends Model {
  static entity = 'choices'

  static fields () {
    return {
      id: this.uid(),
      choice_text: this.string(''),
      skill_type: this.string(''),
      required_credits: this.number(0),
      required_food: this.number(0),
      required_repairkits: this.number(0),
      required_medkits: this.number(0),
      required_crew: this.string(''),
      required_airlock: this.boolean(false),
      required_weapons_system: this.boolean(false),
      required_navigation_system: this.boolean(false),
      required_waste_disposal: this.boolean(false),
      event_id: this.attr(null),
      achievement: this.string(''),
      event: this.belongsTo(Event, 'event_id'),
      results: this.hasMany(Result, 'choice_id')
    }
  }
}
