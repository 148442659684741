<template>
  <div>
    <h3 class="terminal-heading">
      <span style="width: 30%;">Thaze Maze</span>
      <span style='width: 70%;display: inline-block;text-align: right;'>
        <span v-for="(item, index) in inventory" :key="index" class="inventory-item">
          <font-awesome-icon :icon="item.name" />
        </span>
      </span>
    </h3>

    <div class="maze-container">
      <div class="maze-room" style="line-height: 1;">
        <div v-for="(line, index) in getRoom()" :key="`row-${index}`">
          <span v-for="(row, index) in line.row" :key="`cell-${index}`" :class="`${ row == '&block;' ? 'maze-floor' : 'maze-wall'}`">

            <span v-if="row == 'item'" class="maze-item" style="width: 16px;display: inline-block;">
              <font-awesome-icon :icon="mazeRoom.item" />
            </span>
            <span v-else-if="row == 'monster'" class="maze-item" style="width: 16px;display: inline-block;">
              <font-awesome-icon :icon="mazeRoom.monster" />
            </span>
            <span v-else v-html="row"></span>

          </span>
        </div>
      </div>

      <div class="maze-detail">
        <h4 class="maze-room-name">{{ mazeRoom.name }}</h4>
        <p v-html="roomDescription"></p>
      </div>
    </div>

    <ul class="fa-ul">
      <li v-if="canGetItem()">
        <span class="fa-li"><i class="fas fa-hand"></i></span>
        <a href="#" @click.prevent="getItem(mazeRoom.item)" class="disease-list-name">Get {{ mazeRoom.itemName }}</a>
      </li>
      <li v-if="canMoveDirection('north')">
        <span class="fa-li"><i class="fas fa-up"></i></span>
        <a href="#" @click.prevent="gotoMazeRoom(mazeRoom.north)" class="disease-list-name">North</a>
      </li>
      <li v-if="canMoveDirection('east')">
        <span class="fa-li"><i class="fas fa-right"></i></span>
        <a href="#" @click.prevent="gotoMazeRoom(mazeRoom.east)" class="disease-list-name">East</a>
      </li>
      <li v-if="canMoveDirection('south')">
        <span class="fa-li"><i class="fas fa-down"></i></span>
        <a href="#" @click.prevent="gotoMazeRoom(mazeRoom.south)" class="disease-list-name">South</a>
      </li>
      <li v-if="canMoveDirection('west')">
        <span class="fa-li"><i class="fas fa-left"></i></span>
        <a href="#" @click.prevent="gotoMazeRoom(mazeRoom.west)" class="disease-list-name">West</a>
      </li>
    </ul>

    <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
      <i class="fas fa-left"></i> Exit Game
    </a>
  </div>
</template>

<script>
  import { drawRoom, getMazeRoomById, updateMazeRoom } from '../../utils/maze'
  export default {
    name: "TerminalInfo",

    data () {
      return {
        mazeRoom: null,
        lastRoomId: '',
        inventory: []
      }
    },

    props: {
      terminalId: String
    },

    computed: {
      roomDescription () {
        return `${this.mazeRoom.description} ${(this.mazeRoom.monster != '' ? `A ${this.mazeRoom.monster} blocks the way.` : '')}`
      }
    },

    created () {
      this.getMazeRoom('1-1')

      document.addEventListener("keydown", event => {
        if (event.isComposing) {
          return
        }

        if (this.mazeRoom) {
          switch(event.code) {
            case 'ArrowUp':
              if (this.canMoveDirection('north')) {
                this.gotoMazeRoom(this.mazeRoom.north)
              }
              break

            case 'ArrowRight':
              if (this.canMoveDirection('east')) {
                this.gotoMazeRoom(this.mazeRoom.east)
              }
              break

            case 'ArrowDown':
              if (this.canMoveDirection('south')) {
                this.gotoMazeRoom(this.mazeRoom.south)
              }
              break

            case 'ArrowLeft':
              if (this.canMoveDirection('west')) {
                this.gotoMazeRoom(this.mazeRoom.west)
              }
              break
          }
        }
      })
    },

    methods: {
      getMazeRoom (id) {
        const maze = getMazeRoomById(id)
        this.mazeRoom = maze
      },

      getRoom () {
        return drawRoom(6,6,
          (this.mazeRoom.north != ""),
          (this.mazeRoom.east != ""),
          (this.mazeRoom.south != ""),
          (this.mazeRoom.west != ""),
          this.mazeRoom.item,
          this.mazeRoom.monster
        )
      },

      getItem (item) {
        this.inventory.push({ 'name': item })
        this.mazeRoom.item = ''

        updateMazeRoom(this.mazeRoom, { item: '' })
      },

      gotoMazeRoom (id) {
        this.lastRoomId = this.mazeRoom.id
        this.getMazeRoom(id)
      },

      canGetItem () {
        return (this.mazeRoom.monster == '' && this.mazeRoom.item != '' ? true : false)
      },

      canMoveDirection (direction) {
         switch(direction) {
            case 'north':
              return (this.mazeRoom.north && !this.blockedByMonster('north') ? true : false)
            case 'east':
              return (this.mazeRoom.east && !this.blockedByMonster('east') ? true : false)
            case 'south':
              return (this.mazeRoom.south && !this.blockedByMonster('south') ? true : false)
            case 'west':
              return (this.mazeRoom.west && !this.blockedByMonster('west') ? true : false)
          }
      },

      blockedByMonster (direction) {
        if (this.mazeRoom.monster != '') {
          switch(direction) {
            case 'north':
              return (this.mazeRoom.north != this.lastRoomId ? true : false)
            case 'east':
              return (this.mazeRoom.east != this.lastRoomId ? true : false)
            case 'south':
              return (this.mazeRoom.south != this.lastRoomId ? true : false)
            case 'west':
              return (this.mazeRoom.west != this.lastRoomId ? true : false)
          }
        }
      }
    }
  }
</script>