import { Model } from '@vuex-orm/core'

export default class Planet extends Model {
  static entity = 'planets'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      icon: this.string(''),
      moons: this.number(),
      average_temp: this.number(),
      rotational_period: this.number(),
      orbital_period: this.number(),
      gravity: this.number()
    }
  }
}

 
