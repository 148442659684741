import { updateShip } from './ship'
import { cookingCheck } from './skills'
import { getLivingCrewMembers, updateCrewMorale } from './crew'
import { getRandomName, zeroLimit } from '../utils/helpers'
import { addMessage, addStatusMessage } from './message'
import settings from './settings'
import { updateGame } from './game'
import { startJob } from './job'
import { gameModeDifficultyAdjustment } from './formulas'


export function makeFeast(cook, ship, game, foodUsed) {
  if (game.cook_cooldown == 0) {
    const food = ship.food - foodUsed
    updateShip(ship, { food: food })
    updateGame(game, { cook_cooldown: settings.COOK_COOLDOWN_DISTANCE})

    const moraleBonus = Math.floor(Math.random() * 2) + (cookingCheck(cook, settings.DIFFICULTY_CHECK_COOKING_FEAST) ? 2 : 1) // A number between 1 and 5
    const foodPrepared = getRandomName('food', true)

    addMessage(`Used ${foodUsed} food`, 'lose-message')
    addMessage(`${cook.name} spends the day preparing a huge feast of ${foodPrepared}. The crew gives it ${moraleBonus} ${moraleBonus > 1 ? 'stars' : 'star' }!`)

    getLivingCrewMembers(0, ship).forEach((crew) => {
      const result = updateCrewMorale(crew, (crew.favorite_food == foodPrepared ? moraleBonus + 1 : moraleBonus))

      if (result != '') {
        addMessage(result, '')
      }
    })
  } else {
    addMessage(`It's too soon to make another feast!`, 'lose-message')
  }
}

export function startFoodReplication (cook, ship, game, amount) {
  if (game.cook_cooldown == 0) {
    const food = zeroLimit(ship.food - amount)
    updateShip(ship, { food: food })
    updateGame(game, { cook_cooldown: settings.COOK_COOLDOWN_DISTANCE})
    startJob(cook, settings.JOB_FOOD, settings.CRAFTING_TIME_FOOD, amount * 2)

    addMessage(`${cook.name} starts the difficult process of replicating food.`)
  }
}

export function replicateFood (cook, ship, foodAmount, game) {
  const difficulty = settings.DIFFICULTY_CHECK_SCIENCE_REPLICATE + gameModeDifficultyAdjustment(game)
  const successful = cookingCheck(cook, difficulty)

  if (successful) {
    updateShip(ship, { food: ship.food + foodAmount })
  }

  addStatusMessage(`${cook.name} ${(successful ? 'successfully replicated' : 'failed to replicate')} the food.`)
}