import Ship from '../store/models/Ship'
import GameState from '../store/models/GameState'

import settings from '../utils/settings'
import { getRandomName } from '../utils/helpers'
import { clearMessages } from '../utils/message'
import { hireCrew } from '../utils/crew'
import Job from '../store/models/Job'
import { resetAchievements } from './achievements'
import { getRandomPlanetName } from './planets'
import { resetResearchData } from './research'

export async function updateGame (game, data) {
  return await GameState.update({
    where: game.id,
    data: data
  })
}

export function createNewGame (ship, difficulty=settings.GAME_DIFFICULTY_NORMAL) {
  const planetName = getRandomPlanetName()
  const shipName = getRandomName('shipname')
  const outpostName = getRandomName('station')

  const distanceToPlanet = getTravelDistanceByDifficulty(difficulty)
  const distanceToOutpost = calculateDistanceToOutpost(difficulty)
  const special_crates = getCratesByDifficulty(difficulty)

  // Clean up data from previous game
  clearMessages()
  resetAchievements()
  resetResearchData()
  Job.deleteAll()
  GameState.deleteAll()

  // Create new game state
  GameState.insert({
    data: {
      active: false,
      active_display: "gamestart",
      current_event_id: 0,
      selected_crew: 0,
      status: 'start',
      destination_planet: planetName,
      distance_to_planet: distanceToPlanet,
      difficulty: difficulty,
      outpost_name: outpostName,
      distance_to_outpost: distanceToOutpost,
      food_ration_level: settings.DEFAULT_FOOD_RATION_LEVEL,
      crew_airlock_count: 0,
      crew_death_count: 0,
      secrets_found: 0,
      outposts_visited: 0,
      special_crates: special_crates,
      year: settings.STARTING_YEAR,
      day: settings.STARTING_DAY
    }
  })

  const passengers = Math.floor(Math.random() * 20) + 10
  const cargo = Math.floor(Math.random() * 30) + 10

  Ship.update({
    where: ship.id,
    data: {
      name: shipName,
      passenger_count: passengers,
      cargo_count: cargo,
      fuel_amount: 100,
      engine_status: 100,
      engine_efficiency: settings.STARTING_ENGINE_EFFICIENCY,
      speed: settings.STARTING_SPEED,
      hull_status: 100,
      weapon_status: 100,
      credits: 0,
      repair_kits: settings.STARTING_REPAIR_KITS,
      med_kits: settings.STARTING_MED_KITS,
      condition: settings.STARTING_CONDITION,
      food: settings.STARTING_FOOD,
      erbium_crystals: 0,
      alien_artifacts: 0,
      airlock_status: settings.AIRLOCK_STATUS_NORMAL,
      waste_disposal: settings.WASTE_DISPOSAL_STATUS_NORMAL,
      weapons_system: settings.WEAPONS_SYSTEM_STATUS_NORMAL,
      navigation_system: settings.NAVIGATION_SYSTEM_STATUS_NORMAL,
      icon: 'rocket'
     }
  })

  hireCrew(ship.id)
  // generateCrewForHire()
}

export function endGame (winner, reason) {
  const currentGame = GameState.query().first()

  GameState.update({
    where: currentGame.id,
    data: {
      active: false,
      game_over_text: reason,
      active_display: (winner ? 'win' : 'gameover') }
  })
}

export function getTravelDistanceByDifficulty (difficulty) {
  switch (difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.TRAVEL_DISTANCE_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.TRAVEL_DISTANCE_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.TRAVEL_DISTANCE_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.TRAVEL_DISTANCE_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.TRAVEL_DISTANCE_FOREVER
  }
}

export function getCratesByDifficulty (difficulty) {
  switch (difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.STARTING_CRATES_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.STARTING_CRATES_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.STARTING_CRATES_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.STARTING_CRATES_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.STARTING_CRATES_FOREVER
  }
}

export function calculateDistanceToOutpost (difficulty) {
  switch (difficulty) {
    case (settings.GAME_DIFFICULTY_EASY):
      // Easy (75,000) -> number between 25,000 and 35,000
      return Math.floor(Math.random() * 10000 + (25000))

    case (settings.GAME_DIFFICULTY_NORMAL):
      // Normal (100,000) -> number between 40,000 and 60,000
      return Math.floor(Math.random() * 20000 + (40000))

    case (settings.GAME_DIFFICULTY_HARD):
      // Hard (200,000) -> number between 55,000 and 75,000
      return Math.floor(Math.random() * 20000 + (55000))

    case (settings.GAME_DIFFICULTY_EXPERT):
      // Expert (500,000) -> number between 60,000 and 85,000
      return Math.floor(Math.random() * 25000 + (60000))

    case (settings.GAME_DIFFICULTY_FOREVER):
      // Forever (No limit) -> number between 60,000 and 85,000
      return Math.floor(Math.random() * 25000 + (60000))
  }
}

export function setNextOutpost (gameState) {
  const outpostName = getRandomName('station')
  const distanceToOutpost = calculateDistanceToOutpost(gameState.difficulty)

  GameState.update({
    where: gameState.id,
    data: {
      outpost_name: outpostName,
      distance_to_outpost: distanceToOutpost,
  }})
}

export function getStartingCredits (difficulty) {
  switch (difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.STARTING_CREDITS_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.STARTING_CREDITS_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.STARTING_CREDITS_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.STARTING_CREDITS_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.STARTING_CREDITS_FOREVER
  }
}

export function currentStarDate (game) {
  // Star date format: Year . Day (padded with zeros)
  return `${game.year}.${game.day.toString().padStart(3, '0')}`
}
