<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="hospital" />
        Outpost Infirmary
      </h3>

      <p>
        The busy infirmary is full of sick and injured people. You have <span class="game-info">{{ credits }}</span> credits.
      </p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">
          <div v-if="sickCrewMembers.length == 0">All crew members are healthy.</div>

          <ul class="fa-ul">
            <li v-for="crew in sickCrewMembers" :key="crew.id">
              <span class="fa-li"><i class="fas fa-square"></i></span>
              <a v-if="crew.disease_duration > 0" href="#" @click.prevent="treatCrewMember(crew)">Treat {{crew.name}}'s for {{ crew.disease.name }} [{{calculateCost(crew)}}]</a>
              <a v-else-if="hasDeepSpacePanic(crew)" href="#" @click.prevent="treatCrewMember(crew)">Treat {{crew.name}} for Deep Space Panic [{{calculateCost(crew)}}]</a>
              <a v-else href="#" @click.prevent="treatCrewMember(crew)">
                Treat {{crew.name}}'s injuries [{{calculateCost(crew)}}]</a>
            </li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { cureCrewDisease, endCrewPanic, getSickCrewMembers, updateCrewHealth } from '../../utils/crew'
import { infirmaryCostCalculation } from '../../utils/formulas'
import { addMessage, clearMessages } from '../../utils/message'
import settings from '../../utils/settings'
import { updateShip } from '../../utils/ship'

export default {
  name: "SickBay",

  mixins: [
    GameMixin
  ],

  computed: {
    sickCrewMembers () {
      return getSickCrewMembers(this.currentShip)
    },

    credits () {
      return this.currentShip.credits.toLocaleString()
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    calculateCost (crew) {
      return infirmaryCostCalculation(crew)
    },

    hasDeepSpacePanic (crew) {
      return crew.status == settings.STATUS_PANIC
    },

    treatCrewMember (crew) {
      const cost = infirmaryCostCalculation(crew)

      if (this.currentShip.credits < cost) {
        addMessage(`You don't have enough credits.`, 'lose-message')
      } else {
        clearMessages()
        endCrewPanic(crew)
        cureCrewDisease(crew)
        updateShip(this.currentShip, { credits: this.currentShip.credits - cost })
        updateCrewHealth(crew, settings.MAX_HEALTH)
        addMessage(`${crew.name} is feeling much better.`, 'success-message')
      }
    }
  }
}
</script>
