<template>
  <div class="">
    <div class="wrap-ship-status" v-if="this.currentShip">
      <div class="ship-status with-border with-border-radius margin-y-sm padding-sm">
        <h3 class="ship-name with-hr" style="display: flex;">
          <div style="text-align: left;">{{this.currentShip.name}}</div>
        </h3>

        <div>
          <div class="desktop:display-flex flex-content-between margin-y-sm">
            <div class="ship-resource-label">
              <span class="panelbutton" :class="shipconditionIndicator()"></span>
              Ship Condition:
            </div>
            <div class="ship-resource">{{ currentShip.condition }}</div>
          </div>

          <div class="shipinfo desktop:display-flex flex-content-between" v-if="this.currentShip">
            <div class="status-bars display-flex flex-content-between margin-bottom-sm desktop:margin-right-md" style="flex-grow: 1;">
              <div>
                <vertical-resource-bar
                  @switchView="gotoRoom('divertpower')"
                  label="Fuel"
                  color="008D32"
                  :percentage="this.currentShip.fuel_amount"
                />
              </div>
              <div>
                <vertical-resource-bar
                  @switchView="gotoRoom('repairhull')"
                  label="Hull"
                  color="008D32"
                  :percentage="this.currentShip.hull_status"
                />
              </div>
              <div>
                <vertical-resource-bar
                  label="Weapons"
                  color="008D32"
                  :percentage="this.currentShip.weapon_status"
                />
              </div>
              <div>
                <vertical-resource-bar
                  @switchView="gotoRoom('repairengine')"
                  label="Engines"
                  color="008D32"
                  :percentage="this.currentShip.engine_status"
                />
              </div>
            </div>

            <div class="">
              <button :disabled="computerDisabled" style="width: 100%;" @click="gotoRoom('shipmenu')" class="display-block size-xs"><i class="fas fa-computer-classic"></i> Computer</button>
              <button :disabled="computerDisabled" style="width: 100%;" @click="gotoRoom('sickbay')" class="display-block size-xs"><i class="fas fa-kit-medical"></i> Sick Bay</button>
              <button :disabled="computerDisabled" style="width: 100%;" @click="gotoRoom('engineroom')" class="display-block size-xs"><i class="fas fa-screwdriver-wrench"></i> Repairs</button>

              <speed-bar :speed="this.currentShip.speed" />

              <div class="button-panel text-center" style="width: 150px; line-height: 1;">
                <div>
                  <span class="panelbutton panelbutton-red flashing"></span>
                  <span class="panelbutton panelbutton-green" @click="buttonClicker(1)"></span>
                  <span class="panelbutton panelbutton-white" @click="buttonClicker(2)"></span>
                  <span class="panelbutton panelbutton-blue flashing-slow"  @click="buttonClicker(3)"></span>
                  <span class="panelbutton panelbutton-yellow"></span>
                  <span class="panelbutton panelbutton-white "></span>
                </div>
                <div>
                  <span class="panelbutton panelbutton-yellow"></span>

                  <span v-if="this.currentGame.secret_button_active === true" class="panelbutton panelbutton-purple flashing-fast" @click="gotoRoom('cheats')"></span>
                  <span v-else class="panelbutton panelbutton-blue" @click="gotoRoom('cheats')"></span>

                  <span class="panelbutton panelbutton-blue flashing-fast"></span>
                  <span class="panelbutton panelbutton-green"></span>
                  <span class="panelbutton panelbutton-red "></span>
                  <span class="panelbutton panelbutton-white flashing-slow"></span>
                </div>
              </div>

              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="resource-status with-border with-border-radius margin-bottom-sm padding-sm">
      <h3 class="with-hr">Resources</h3>
      <div class="ship-resources">

        <div class="ship-resource-label tooltip">
          <span class="panelbutton panelbutton-blue" :class="repairkitIndicator()"></span>
          <span class="tooltiptext tooltip-top">Repair kits can be used to repair the engines or hull of your ship.</span>
          Repair Kits:
        </div>
        <div class="ship-resource"> {{this.currentShip.repair_kits}}</div>

        <div class="ship-resource-label tooltip">
          <span class="tooltiptext tooltip-top">Med kits can be used to patch up the crew if injured or sick.</span>
          <span class="panelbutton panelbutton-red" :class="medkitIndicator()"></span>
          Med Kits:
        </div>
        <div class="ship-resource"> {{this.currentShip.med_kits}}</div>

        <div class="ship-resource-label tooltip">
          <span class="tooltiptext tooltip-top">Each crew member requires at least 1 food unit per day.</span>
          <span class="panelbutton panelbutton-orange" :class="foodIndicator()"></span>
          Food:
        </div>

        <div class="ship-resource">{{this.currentShip.food}}</div>

        <div class="ship-resource-label tooltip">
          <span class="tooltiptext tooltip-top">Erbium crystals are rare and can be used in science experiments.</span>
          <span class="panelbutton panelbutton-white"></span>
          Crystals:
        </div>
        <div class="ship-resource">{{ this.currentShip.erbium_crystals }}</div>

        <div class="ship-resource-label tooltip">
          <span class="tooltiptext tooltip-top">Alien Artifacts can be sold at outposts.</span>
          <span class="panelbutton panelbutton-green"></span>
          Alien Artifacts:
        </div>
        <div class="ship-resource">{{ this.currentShip.alien_artifacts }}</div>

        <div class="ship-resource-label tooltip">
          <span class="tooltiptext tooltip-top">Amount of food allocated per day. Can be adjusted in the kitchen.</span>
          <span class="panelbutton panelbutton-yellow"></span>
          Food Rations:
        </div>
        <div class="ship-resource">{{ rationLevel }}</div>

        <div class="ship-resource-label">
          <span class="panelbutton panelbutton-white"></span>
          Credits:
        </div>
        <div class="ship-resource">{{ credits }}</div>

      </div>
    </div>
  </div>
</template>

<script>
import SpeedBar from '../components/SpeedBar.vue'
import GameMixin from '../mixins/GameMixin'
import { getLivingCrewMembers } from '../utils/crew'
import { rationLevelName } from '../utils/formulas'
import settings from '../utils/settings'
import VerticalResourceBar from './VerticalResourceBar.vue'

export default {
  name: "ShipStatus",

  mixins: [
    GameMixin
  ],

  components: {
    VerticalResourceBar,
    SpeedBar
  },

  data() {
    return {
      clickSequence: 0
    }
  },

  computed: {
    crewCount () {
      return getLivingCrewMembers(0, this.currentShip).length
    },

    rationLevel () {
      return rationLevelName(this.currentGame.food_ration_level)
    },

    credits () {
      return this.currentShip.credits.toLocaleString()
    },

    computerDisabled () {
      return this.currentGame.active_display == 'events'
        || this.currentGame.active_display == 'outpost'
        || this.currentGame.active_display == 'supplies'
        || this.currentGame.active_display == 'refuel'
        || this.currentGame.active_display == 'shiprepairs'
        || this.currentGame.active_display == 'hire'
        || this.currentGame.active_display == 'sellsupplies'
        || this.currentGame.active_display == 'infirmary'
        || this.currentGame.active_display == 'gambling'
        || this.currentGame.active_display == 'cantina'
        || this.currentGame.active_display == 'tradingpost'
    }
  },

  methods: {
    gotoRoom (room, checkComputerDisabled=true) {
      if (checkComputerDisabled && this.computerDisabled) {
        return null // computer is disabled - do nothing
      }

      this.clearMessages()
      if (!this.isGameOver()) {
        this.changeDisplay(room)
      }
    },

    repairkitIndicator () {
      if (this.currentShip.repair_kits == 0) {
        return 'flashing'
      }

      return ''
    },

    foodIndicator () {
      if (this.currentShip.food == 0) {
        return 'flashing'
      }

      return ''
    },

    medkitIndicator () {
      if (this.currentShip.med_kits == 0) {
        return 'flashing'
      }

      return ''
    },

    buttonClicker(btnNum) {
      if (btnNum - 1 === this.clickSequence) {
        this.clickSequence = btnNum
      } else {
        this.clickSequence = 0
      }

      if (this.clickSequence === 3) {
        console.log('Secret')
      }
    },

    shipconditionIndicator () {
      switch (this.currentShip.condition.toLowerCase()) {
        case settings.SHIP_CONDITION_EXCELLENT:
          return 'panelbutton-green'
        case settings.SHIP_CONDITION_GOOD:
          return 'panelbutton-yellow'
        case settings.SHIP_CONDITION_POOR:
          return 'panelbutton-yellow flashing'
        case settings.SHIP_CONDITION_CRITICAL:
          return 'panelbutton-red flashing'
        default:
          return 'panelbutton-green'
      }
    }
  }
}
</script>

<style scoped>
.ShipInfo {
  text-align: left;
  padding: 10px;
}
</style>
