import { gameModeDifficultyAdjustment } from "./formulas"
import settings from './settings'

export function getRandomDifficultyLevel (game) {
  // Roll 3d10 + difficulty adjustment
  return getRandomBetween(1, 10) + getRandomBetween(1, 10) + getRandomBetween(1, 10) + gameModeDifficultyAdjustment(game)
}

export function getDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "a very easy"
    case difficult_level <= 15:
      return "an easy"
    case difficult_level <= 19:
      return "an average"
    case difficult_level <= 25:
      return "a difficult"
    case difficult_level <= 28:
      return "a very difficult"
    case difficult_level <= 30:
      return "an impossible"
  }
}

export function getSimpleDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "very easy"
    case difficult_level <= 15:
      return "easy"
    case difficult_level <= 19:
      return "tough"
    case difficult_level <= 25:
      return "very tough"
    case difficult_level <= 30:
      return "impossible"
  }
}

export function getMedicalDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "a very mild"
    case difficult_level <= 15:
      return "a mild"
    case difficult_level <= 19:
      return "a serious"
    case difficult_level <= 25:
      return "a severe"
    case difficult_level <= 28:
      return "a very severe"
      case difficult_level <= 30:
      return "a deadly"
  }
}

export function getCollisionDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "very easy"
    case difficult_level <= 15:
      return "easy"
    case difficult_level <= 19:
      return "hard"
    case difficult_level <= 25:
      return "difficult"
    case difficult_level <= 28:
      return "very difficult"
      case difficult_level <= 30:
      return "impossible"
  }
}

export function getCombatDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "very easy"
    case difficult_level <= 15:
      return "easy"
    case difficult_level <= 19:
      return "hard"
    case difficult_level <= 25:
      return "difficult"
    case difficult_level <= 28:
      return "very difficult"
      case difficult_level <= 30:
      return "impossible"
  }
}


export function getPlanetDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "peaceful"
    case difficult_level <= 15:
      return "nice"
    case difficult_level <= 22:
      return "dangerous"
    case difficult_level <= 30:
      return "deadly"
  }
}

export function getWormholeDifficultyDescription (difficult_level) {
  switch (true) {
    case difficult_level <= 10:
      return "very safe"
    case difficult_level <= 15:
      return "safe"
    case difficult_level <= 25:
      return "dangerous"
    case difficult_level <= 28:
      return "deadly"
    case difficult_level <= 30:
      return "very deadly"
  }
}

export function pilotingCheck (crew, difficultyLevel) {
  return skillCheck(crew.piloting, difficultyLevel, crew.morale, crew.status)
}

export function navigationCheck (crew, difficultyLevel) {
  return skillCheck(crew.navigation, difficultyLevel, crew.morale, crew.status)
}

export function mechanicsCheck (crew, difficultyLevel) {
  return skillCheck(crew.mechanics, difficultyLevel, crew.morale, crew.status)
}

export function medicalCheck (crew, difficultyLevel) {
  return skillCheck(crew.medical, difficultyLevel, crew.morale, crew.status)
}

export function weaponsCheck (crew, difficultyLevel) {
  return skillCheck(crew.weapons, difficultyLevel, crew.morale, crew.status)
}

export function cookingCheck (crew, difficultyLevel) {
  return skillCheck(crew.cooking, difficultyLevel, crew.morale, crew.status)
}

export function scienceCheck (crew, difficultyLevel) {
  return skillCheck(crew.science, difficultyLevel, crew.morale, crew.status)
}

export function skillCheck (skillLevel, difficultyLevel, moraleBonus, status) {
  // 2d10 + skillLevel (4 to 10) + morale (-5 to 5) + status pentaly (0 to -3) = Range 1 to 35 -- primary skills are between 4 & 10

  var statusPenalty = 0

  switch (status) {
    case settings.STATUS_PANIC:
      statusPenalty = settings.STATUS_PANIC_SKILL_PENALTY
      break
    case settings.STATUS_RAMPAGE:
      statusPenalty = settings.STATUS_RAMPAGE_SKILL_PENALTY
      break
  }

  const roll = Math.floor(getRandomBetween(1, 10) + getRandomBetween(1, 10) + skillLevel + moraleBonus + statusPenalty)
  // console.log(`Skill check (${difficultyLevel} difficulty) - Rolling 2d10 + ${skillLevel} skill + ${moraleBonus} morale + ${statusPenalty} statusPenalty. Result: ${roll} > ${difficultyLevel} = ${roll >= difficultyLevel ? 'success' : 'failure' }`)

  return roll >= difficultyLevel
}

function getRandomBetween(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}
