<template>
  <div>
    <h3 class="terminal-heading">Scientific Research</h3>

    <div v-if="this.selectedItem == null">
      <ul class="fa-ul">
        <li v-for="research in researchDatas" :key="research.id">
          <span class="fa-li"><i class="fas fa-file"></i></span>
          <a href="#" @click.prevent="viewItem(research)" class="terminal-menu">{{ research.title }}</a>
        </li>
      </ul>

      <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Menu
      </a>
    </div>

    <div v-else class="column-12">
      <div class="row align-middle" style="padding-top: 10px;">
        <div>
          <font-awesome-icon class="fa-fw" :icon="this.selectedItem.icon" style="font-size: 60px;" />
        </div>
        <div>
          <h2 class="alien-name">{{ selectedItem.title }}</h2>
          <span class="alien-hostility">Security Level: <span style="color: var(--red);">{{ selectedItem.security_level }}</span></span>
        </div>
      </div>
      <div class="alien-description" style="padding: 16px 0px 20px 16px;" v-html="selectedItem.description" />

      <a href="#" @click.prevent="returnToList()" class="terminal-menu">
        <i class="fas fa-left"></i> Return to List
      </a>
    </div>
  </div>
</template>

<script>
  import { getResearchDataByType } from '../../utils/research'

  export default {
    name: "ScienceResearch",

    data () {
      return {
        selectedItem: null
      }
    },

    computed: {
      researchDatas () {
        return getResearchDataByType('science')
      }
    },

    methods: {
      viewItem (item) {
        this.selectedItem = item
      },

      returnToList () {
        this.selectedItem = null
      }
    }
  }
</script>