import { Model } from '@vuex-orm/core'

export default class Cheat extends Model {
  static entity = 'cheats'

  static fields () {
    return {
      id: this.uid(),
      code: this.string(''),
      value: this.number(0),
      name: this.string(''),
      icon1: this.string(''),
      icon2: this.string(''),
      icon3: this.string(''),
      icon4: this.string(''),
      description: this.string('')
    }
  }
}