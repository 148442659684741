import { Model } from '@vuex-orm/core'

export default class Disease extends Model {
  static entity = 'diseases'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      icon: this.string('virus'),
      description: this.string(''),
      infection_rate: this.number(),
      fatality_rate: this.number(),
      special: this.boolean(false),
      duration: this.number()
    }
  }
}