<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="rectangle-vertical-history" />
        Money Making Game
      </h3>

      <p v-if="this.gameRunning">Find the rocket to win {{ this.amount * 3 }} Credits.</p>
      <p v-else>An old man wearing a red robe and sitting at a small table invites you to play his money making game.
        <span class='crew-speech'>"You have a chance to triple your credits!"</span> he says.</p>

      <div v-if="this.gameRunning" class="row">
        <div v-for="card in this.deck" :key="card.id" class="column-2">
          <div class="key-panel with-border with-border-radius" style="margin: 4px;width: 75px;height:100px;background-color: var(--white);">
            <div v-if="card.showing == true" class="inner-key-panel" style="padding: 28px 5px 0px 2px;text-align: center;" :style="card.style" >
              <font-awesome-icon class="fa-3x" :icon="card.icon" />
            </div>
            <div v-else class="inner-key-panel" style="padding: 3px 4px 4px 3px;text-align: center;" :style="card.style" @click.prevent="flip(card)">
              <div class="with-border with-border-radius" style="padding: 14px 0px 0px 0px;width: 65px; height: 90px;background-color: var(--red);color:var(--white);">
                <font-awesome-icon class="" icon="stroopwafel" style="font-size: 60px;" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">
          <ul class="fa-ul">
            <li v-if="this.gameRunning == false"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="showRules()">Tell me the rules</a></li>
            <li v-if="this.gameRunning == false && this.currentShip.credits >= 100"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(100)">Play [100 credits]</a></li>
            <li v-if="this.gameRunning == false && this.currentShip.credits >= 200"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(200)">Play [200 credits]</a></li>
            <li v-if="this.gameRunning == false && this.currentShip.credits >= 500"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(500)">Play [500 credits]</a></li>

            <li v-if="this.cardFlipped && this.currentShip.credits >= 100"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(100)">Play again [100 credits]</a></li>
            <li v-if="this.cardFlipped && this.currentShip.credits >= 200"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(200)">Play again [200 credits]</a></li>
            <li v-if="this.cardFlipped && this.currentShip.credits >= 500"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="newCardGame(500)">Play again [500 credits]</a></li>

            <li v-if="this.cardFlipped && this.losses > 10 && this.riggedFlag == true"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="rigged()">This game is rigged, man!</a></li>
          </ul>

          <div  v-if="this.gameRunning == false || this.cardFlipped"><a href="#" @click.prevent="gotoRoom('outpost')">Return to Outpost</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { updateGame } from '../../utils/game'
import { addMessage, clearMessages } from '../../utils/message'
import { formatNPCPhrase, getRandomPhrase } from '../../utils/phrases'
import { updateShip } from '../../utils/ship'

export default {
  name: "Gambling",

  mixins: [
    GameMixin
  ],

  data () {
    return {
      rulesExplainedCount: 0,
      gameRunning: false,
      cardFlipped: false,
      amount: 0,
      wins: 0,
      losses: 0,
      riggedFlag: true,
      rows: 1,
      cardsPerRow: 4,
      cards: [],
      deck: [
        { id: `c1`, icon: 'rocket', showing: false, style: 'color: var(--green);' },
        { id: `c2`, icon: 'alien', showing: false, style: 'color: var(--red);' },
        { id: `c3`, icon: 'alien', showing: false, style: 'color: var(--red);' },
        { id: `c4`, icon: 'alien', showing: false, style: 'color: var(--red);' }
      ]
    }
  },
  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    showRules () {
      clearMessages()
      this.rulesExplainedCount += 1

      if (this.rulesExplainedCount >= 3) {
        addMessage(formatNPCPhrase("The Old Man", `${getRandomPhrase('old-man-rules').text} You pick one of four cards. If you find the rocket, you win three times your wager.`), "")
      } else {
        addMessage(formatNPCPhrase("The Old Man", "You pick one of four cards. If you find the rocket, you win three times your wager."), "")
      }
    },

    newCardGame (amount) {
      clearMessages()

      if (this.currentShip.credits >= amount) {
        this.gameRunning = true
        this.cardFlipped = false
        this.amount = amount
        updateShip(this.currentShip, { credits: this.currentShip.credits - this.amount })
        this.deck.forEach(card => card.showing = false)
        this.shuffleCards(this.deck)
       } else {
        addMessage("You don't have enough credits.", "lose-message")
      }
    },

    flip (card) {
      if (this.cardFlipped == false) {
        this.cardFlipped = true

        if (card.showing == false) {
          card.showing = true
        }

        if (card.icon == 'rocket') {
          const winnings = this.amount * 3
          updateShip(this.currentShip, { credits: this.currentShip.credits + winnings })
          const phrase = getRandomPhrase('money-game-win')

          addMessage(`You win! You gain ${winnings} credits.`, 'gain-message')
          addMessage(formatNPCPhrase('The old man', phrase.text), '')
          this.wins++
        } else {
          const phrase = getRandomPhrase('money-game-lose')

          addMessage(`You lose!`, 'lose-message')
          addMessage(formatNPCPhrase('The old man', phrase.text), '')
          this.losses++
        }
      }
    },

    shuffleCards (deck) {
      for (var i = deck.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1))
          var temp = deck[i]
          deck[i] = deck[j]
          deck[j] = temp
      }
    },

    rigged () {
      clearMessages()

      this.riggedFlag = false
      addMessage(formatNPCPhrase('The old man', `You have had a run of bad luck. Here's a few credits so you can keep playing.`), '')
      addMessage(`You gain 400 credits.`, 'gain-message')

      updateGame(this.currentGame, { secrets_found: this.currentGame.secrets_found + 1 })
      updateShip(this.currentShip, { credits: this.currentShip.credits + 400 })
    }
  }
}
</script>
