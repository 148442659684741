<template>
  <div>
    <h3 class="terminal-heading">Planet Database</h3>

    <div v-if="this.selectedPlanet == null">
      <ul class="fa-ul">
        <li v-for="planet in planets" :key="planet.id">
          <span class="fa-li"><i class="fas fa-file"></i></span>
          <a href="#" @click.prevent="viewPlanet(planet)" class="terminal-menu">{{ planet.name }}</a>
        </li>
      </ul>

      <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Menu
      </a>
    </div>

    <div v-else class="column-12">
      <div class="row align-middle" style="padding-top: 10px;">
        <div>
          <font-awesome-icon class="fa-fw" :icon="selectedPlanet.icon" style="font-size: 40px;" />
        </div>
        <div>
          <h2>{{ selectedPlanet.name }}</h2>
        </div>
      </div>

      <div class="row">
        <p style="padding-bottom: 25px;">
          <i class="fas fa-caret-right"></i> Moons: <span style="color: var(--purple);">{{ selectedPlanet.moons }}</span><br>
          <i class="fas fa-caret-right"></i> Average Temperature: <span style="color: var(--purple);">{{ selectedPlanet.average_temp }}K</span><br>
          <i class="fas fa-caret-right"></i> Rotational period: <span style="color: var(--purple);">{{ selectedPlanet.rotational_period }} hours</span><br>
          <i class="fas fa-caret-right"></i> Orbital Period: <span style="color: var(--purple);">{{ selectedPlanet.orbital_period }} earth days</span><br>
          <i class="fas fa-caret-right"></i> Gravity: <span style="color: var(--purple);">{{ selectedPlanet.gravity }}m/s<sup>2</sup></span>
        </p>
      </div>

      <a href="#" @click.prevent="returnToList()" class="terminal-menu">
        <i class="fas fa-left"></i> Return to List
      </a>

    </div>
  </div>
</template>

<script>
  import { getPlanets } from '../../utils/planets'

  export default {
    name: "planetDatabase",

    data () {
      return {
        selectedPlanet: null
      }
    },

    computed: {
      planets () {
        return getPlanets()
      }
    },

    methods: {
      viewPlanet (planet) {
        this.selectedPlanet = planet
      },

      returnToList () {
        this.selectedPlanet = null
      }
    }
  }
</script>