<template>
  <div>
    <h3 class="terminal-heading">Mission Status</h3>

    <p>
      Star Date: <b>{{ starDate }}</b><br>
      Destination: <b>{{ destinationPlanet }}</b>
    </p>

    <p>
      <i class="fas fa-caret-right"></i> Ship Condition: <span style="color: var(--purple);">{{ currentShip.condition }}</span><br>
      <i class="fas fa-caret-right"></i> Distance Traveled: <span style="color: var(--purple);">{{ distanceTraveled }} parsecs</span><br>
      <i class="fas fa-caret-right"></i> Casualties: <span style="color: var(--purple);">{{ gameState.crew_death_count }}</span>
    </p>
    <h5 style="padding-bottom: 10px;">
      Odds of survival: <span style="color: var(--purple);">{{ survivalOdds }}%</span>
    </h5>

    <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
      <i class="fas fa-left"></i> Return to Menu
    </a>
  </div>
</template>

<script>
import GameState from '../../store/models/GameState'
import Ship from '../../store/models/Ship'
import { getTravelDistanceByDifficulty, currentStarDate } from '../../utils/game'
import settings from '../../utils/settings'

  export default {
    name: "MissionInfo",

    computed: {
      gameState () {
        return GameState.query().first()
      },

      currentShip () {
        return Ship.query().with('crew_members').first()
      },

      starDate () {
        return currentStarDate(this.gameState)
      },

      destinationPlanet () {
        const gameState = GameState.query().first()
        return gameState.destination_planet
      },

      distanceTraveled () {
        const totalDistance = getTravelDistanceByDifficulty(this.gameState.difficulty)
        return (totalDistance - this.gameState.distance_to_planet).toLocaleString()
      },

      survivalOdds () {
        var odds = 100
        odds = odds - (this.gameState.crew_death_count * 6)

        const totalDistance = getTravelDistanceByDifficulty(this.gameState.difficulty)
        const percentTraveled = (this.gameState.distance_to_planet / totalDistance) * 100 // High value lower odds

        odds = odds - (percentTraveled / 2)
        odds = odds - (Math.random() * 10 + 5)

        switch (this.currentShip.condition) {
          case settings.SHIP_CONDITION_EXCELLENT:
            odds = odds + 5
            break

          case settings.SHIP_CONDITION_POOR:
            odds = odds - 20
            break

          case settings.SHIP_CONDITION_CRITICAL:
            odds = odds - 30
            break
        }

        if (odds <= 0) {
          odds = 1
        }

        return odds.toLocaleString()
      }
    },

    methods: {

    }
  }
</script>