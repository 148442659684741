import Name from '../store/models/Name'

export function getRandomRecord (records) {
  return records[Math.floor(Math.random() * records.length)]
}

export function makeNamePlural (name) {
  return `${name.name}${name.is_plural ? '' : 's'}`
}

export function getRandomName (nameType, plural = false) {
  const names = Name.query().where((name) => {
    return name.type == nameType
  }).all()

  const name = getRandomRecord(names)
  return (plural ? makeNamePlural(name) : name.name)
}

export function getRandomNameExcluding (nameType, excluding) {
  let name = ''
  let count = 0
  do {
    name = getRandomName(nameType)
    count++
  } while (excluding.includes(name) || count > 20)

  return name
}

export function valueLimit (val) {
  return val > 100 ? 100 : val < 0 ? 0 : val
}

export function zeroLimit (val) {
  return val < 0 ? 0 : val
}
