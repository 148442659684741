<template>
  <div class="skill">
    <h4 class="skill-header with-hr">Skills</h4>

    <div class="skills-list tablet:4-grid">
      <div class="skill tooltip">
        <span :class="skillClass('Cooking')">Cooking:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of cooking and amount of morale gained from meals.</span>
      </div>
      <div class="skill-value">{{ crew.cooking }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Mechanics')">Mechanics:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of repairs to hull and engine.</span>
      </div>
      <div class="skill-value">{{ crew.mechanics }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Medical')">Medical:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of medical skill checks and healing from Med Kits.</span>
      </div>
      <div class="skill-value">{{ crew.medical }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Piloting')">Piloting:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of piloting checks.</span>
      </div>
      <div class="skill-value">{{ crew.piloting }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Science')">Science:</span>
        <span class="tooltiptext tooltip-top">Yeah! Science bitches!</span>
      </div>
      <div class="skill-value">{{ crew.science }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Navigation')">Navigation:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of navigation checks.</span>
      </div>
      <div class="skill-value">{{ crew.navigation }}</div>

      <div class="skill tooltip">
        <span :class="skillClass('Weapons')">Weapons:</span>
        <span class="tooltiptext tooltip-top">Increases effectiveness of weapons in combat.</span>
      </div>
      <div class="skill-value">{{ crew.weapons }}</div>

      <div class="skill tooltip">
        Morale:
        <span class="tooltiptext tooltip-top">Provides a bonus or penalty to skill checks.</span>
      </div>
      <div class="skill-value">{{ crew.morale }}</div>
    </div>
  </div>
</template>

<script>
  import { primarySkillByPosition } from '../utils/crew'

  export default {
    name: 'SkillList',

    props: {
      crew: Object
    },

    methods: {
      skillClass(skill) {
        return skill.toLowerCase() === primarySkillByPosition(this.crew.position) ? 'primary-skill' : ''
      }
    }
  }
</script>
