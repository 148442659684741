<template>
  <div class="comm desktop:display-flex">
    <div class="wrap-key-panel desktop:margin-right-xs margin-bottom-xs">
      <div class="key-panel with-border with-border-radius padding-xs" style="background-color: var(--black);">
        <div class="inner-key-panel" style="padding-left: 2px;text-align: center; display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 5px;">

          <input v-model="textCode" type="text" style="grid-column: span 3;" class="code-input" maxlength="4" />
          <span class="button" @click="inputValue(1)">1</span>
          <span class="button" @click="inputValue(2)">2</span>
          <span class="button" @click="inputValue(3)">3</span>
          <span class="button" @click="inputValue(4)">4</span>
          <span class="button" @click="inputValue(5)">5</span>
          <span class="button" @click="inputValue(6)">6</span>
          <span class="button" @click="inputValue(7)">7</span>
          <span class="button" @click="inputValue(8)">8</span>
          <span class="button" @click="inputValue(9)">9</span>
          <span class="button" @click="inputValue('C')">C</span>
          <span class="button" @click="inputValue(0)">0</span>
          <span class="button blue" @click="inputValue('#')"><i class="far fa-unlock-keyhole"></i></span>
        </div>
      </div>
    </div>

    <div class="wrap-comm-screen">
      <div class="comm-screen with-border with-border-radius padding-md">
        <div class="output with-border with-border-radius padding-sm text-left">
          <div class="inner-key-panel">

            <div v-for="message in messages" :key="message.id">
              <span v-html="message.text"></span>
            </div>
          </div>

        </div>

        <span style="color: var(--white);">- Pinkwater Security -</span>

      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { getMessagesByType, addCommMessage, clearMessages, addMessage } from '../utils/message'
import ComMessage from "../store/models/ComMessage"
import { updateGame } from '../utils/game'
import { getRandomPhrase } from '../utils/phrases'

const INITIALIZING = '0'
const ACCESS = '1'
const CODE = '3668' // FONT

export default {
  name: "DoorPad",

  mixins: [
    GameMixin
  ],

  data() {
    return {
      eventTimer: '',
      textCode: '',
      connectionStatus: INITIALIZING
    }
  },

  computed: {
    messages () {
      return getMessagesByType('comm')
    },

    selectedComMessage () {
      const comMessage = ComMessage.query().with('choices').where((com) => {
          return com.code == this.textCode && com.max_occurrences > com.occurrence_count
        }).first()

      return comMessage
    }
  },

  created () {
    if (this.currentGame.secured_door_open == true) {
      addCommMessage('DOOR STATUS: <i class="fa fa-door-open"></i> ')
    } else {
      addCommMessage('DOOR STATUS: <i class="fa fa-door-closed"></i>')
      addCommMessage('ENTER SECURITY CODE')
    }
  },

  methods: {
    inputValue (value) {
      switch (value) {
        case "C":
          this.reset()
          break

        case "#":
          clearInterval(this.eventTimer)
          clearMessages()
          this.connectionStatus = INITIALIZING

          if (this.textCode.length == 4) {
            addCommMessage(`DECODING SECURITY CODE ${this.textCode}...`)
            this.eventTimer = setInterval(this.commEvent, 1000)
          } else {
            addCommMessage(`PLEASE ENTER 4 DIGIT SECURITY CODE.`)
          }
          break

        default:
          if (this.textCode.length < 4) {
            this.textCode = `${this.textCode}${value}`
          }
      }
    },

    commEvent () {
      switch (this.connectionStatus) {
        case INITIALIZING:
          addCommMessage(`INITIALIZING SECURITY PROTOCOL.`)
          this.connectionStatus = ACCESS
          break

        case ACCESS:
          if (this.textCode == CODE) {
            addCommMessage(`ACCESS GRANTED!`)
            updateGame(this.currentGame, { secured_door_open: true })
          } else {
            addCommMessage(`ACCESS DENIED!`)
            addCommMessage(`INVALID SECURITY CODE`)

            addMessage(getRandomPhrase('secured-door-fail').text, '')
            this.textCode = ''
          }

          this.connectionStatus = INITIALIZING
          clearInterval(this.eventTimer)
          break
      }

    },

    reset () {
      this.textCode = ''
      clearInterval(this.eventTimer)
      this.connectionStatus = INITIALIZING
    }

  }
}
</script>
