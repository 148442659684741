<template>
  <div class="margin-2xl text-center">
    <h3>Achievements Wall of Fame</h3>
    <div class="awards margin-top-xl margin-bottom-6xl">
      <div v-for="achievement in this.achievements" :key="achievement.code" class="award display-flex flex-items-center padding-3xs">
        <font-awesome-icon :icon="['fal', achievement.icon]" class="fa-2x fa-fw award-icon padding-3xs margin-right-4xs" />
        <p class="margin-top-0">{{ achievement.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import Achievement from '../store/models/Achievement'

export default {
  name: "Achievements",

  mixins: [
    GameMixin
  ],

  computed: {
    achievements () {
      //const achievements = Achievement.query().all()
      const achievements = Achievement.query().where((achievement) => {
            return achievement.unlocked == true
          }).all()

      return achievements
    }
  }
}
</script>
