<template>
  <div>
    <h3 class="terminal-heading">Disease Database</h3>

    <div v-if="this.selectedDisease == null">

      <ul class="fa-ul">
        <li v-for="disease in diseases" :key="disease.id">
          <span class="fa-li"><i class="fas fa-file"></i></span>
          <a href="#" @click.prevent="viewItem(disease)" class="terminal-menu">{{ disease.name }}</a>
        </li>
      </ul>


      <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Menu
      </a>
    </div>

    <div v-else class="column-12">
      <div class="row align-middle" style="padding-top: 10px;">
        <div>
          <font-awesome-icon class="fa-fw" :icon="this.selectedDisease.icon" style="font-size: 60px;" />
        </div>
        <div>
          <h2 class="disease-name">{{ selectedDisease.name }}</h2>
          <span class="disease-infection">Infection Rate: <span style="color: var(--purple);">{{ infectionRate }}</span></span>
          <div class="disease-infection" style="width: 100%;">Fatality Rate: <span style="color: var(--purple);text-align: right;">{{ fatalityRate }}%</span></div>
        </div>
      </div>
      <div class="disease-description" style="padding: 16px 0px 20px 16px;">{{ selectedDisease.description }}</div>

      <a href="#" @click.prevent="returnToList()" class="terminal-menu">
        <i class="fas fa-left"></i> Return to List
      </a>

    </div>
  </div>
</template>

<script>
  import GameMixin from '../../mixins/GameMixin'
  import { calculateDiseaseFatalityRate, getDiseases, infectionRateSeverity } from '../../utils/disease'

  export default {
    name: "DiseaseDatabase",

    mixins: [
      GameMixin
    ],

    data () {
      return {
        selectedDisease: null
      }
    },

    computed: {
      diseases () {
        return getDiseases()
      },

      fatalityRate () {
        return calculateDiseaseFatalityRate(this.selectedDisease, this.currentGame)
      },

      infectionRate () {
        if (this.selectedDisease) {
          return infectionRateSeverity(this.selectedDisease.infection_rate)
        }
        return ''
      }
    },

    methods: {
      viewItem (disease) {
        this.selectedDisease = disease
      },

      returnToList () {
        this.selectedDisease = null
      }
    }
  }
</script>