<template>

<div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="treasure-chest" />
        Secure Supply Room
      </h3>

      <p>You enter a small dusty supply room full of boxes and junk.</p>
      <p v-if="currentGame.crew_droid_available == true">An old crew replacement droid stands motionless in the corner.</p>
      <p v-if="currentGame.special_crates > 0">{{ currentGame.special_crates }} sealed crates sit along the wall under a sign that says "Emergency Supplies".</p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Options</h3>
          <ul class="fa-ul">
            <li v-if="currentGame.special_crates > 0"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="openSpecialCrate()">Open a crate</a></li>
            <li v-if="currentGame.crew_droid_available == true"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="activateDroid()">Activate Crew Replacement Droid</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('cheatcodes')">Pickup the Game Djinn</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('bridge')">Return to Bridge</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import { updateGame } from '../../../utils/game'
import { getRandomLoot } from '../../../utils/loot'
import { addMessage } from '../../../utils/message'
import { getRandomRecord } from '../../../utils/helpers'
import { createBudgetCrewReplacement, crewDeath, getMissingCrewTypes, getRandomCrewMember } from '../../../utils/crew'

export default {
  name: "SecretRoom",

  mixins: [
    GameMixin
  ],

  computed: {
    missingCrewTypes () {
      const position = getMissingCrewTypes(this.currentShip)
      return position
    },
  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    openSpecialCrate () {
      this.clearMessages()

      if (this.currentGame.special_crates > 0) {
        updateGame(this.currentGame, { special_crates: this.currentGame.special_crates - 1 })
        addMessage('You pry open one of the crates and look inside.')
        getRandomLoot(this.currentShip)
      } else {
        addMessage("All the crates have been opened!", 'fail-message')
      }
    },

    activateDroid () {
      this.clearMessages()
      updateGame(this.currentGame, { crew_droid_available: false })

      const missingCrew = this.missingCrewTypes

      if (missingCrew.length > 0) {
        const position = getRandomRecord(missingCrew)
        addMessage(`You activate the droid. It springs to life and takes their place among the crew.`)
        createBudgetCrewReplacement(position, this.currentShip, false)
      } else {
        const crew = getRandomCrewMember(this.currentShip)
        crewDeath(crew, this.currentGame)
        addMessage(`You activate the droid. It springs to life and promptly executes your ${crew.position}, ${crew.name} before taking their place among the crew.`, 'fail-message')
        createBudgetCrewReplacement(crew.position, this.currentShip, false)
      }
    }
  }
}
</script>
