import { Model } from '@vuex-orm/core'
import Crew from './Crew'

export default class Job extends Model {
  static entity = 'jobs'

  static fields () {
    return {
      id: this.uid(),
      active: this.boolean('true'),
      job_type: this.string(''),
      distance_til_complete: this.number(0),
      crew_id: this.attr(null),
      crew: this.belongsTo(Crew, 'crew_id'),
      value: this.number(0), // Generic amount - Used for certain job types
    }
  }
}
