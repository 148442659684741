<template>

<div class="">
    <div class="console with-border with-border-radius">
      <div class="mini-scene space-station">
        <i class="fas fa-sparkles size-lg" data-fa-transform="up-5 right-30 rotate-20"></i>
        <i class="fas fa-planet-ringed size-2xl" data-fa-transform="down-10 right-65 rotate-70"></i>
        <i class="fas fa-mountains size-3xl" data-fa-transform="down-28 right-60"></i>
        <i class="fas fa-mountains size-4xl" data-fa-transform="down-23 right-0"></i>
        <i class="fas fa-star-christmas size-lg" data-fa-transform="down-2 right-95"></i>
        <i class="fas fa-industry-windows size-2xl" data-fa-transform="down-33 right-130"></i>
        <i class="far fa-rocket size-xl" data-fa-transform="down-45 right-125 rotate-315"></i>
        <i class="fas fa-meteor size-xl" data-fa-transform="up-0 right-175 rotate-70"></i>
        <i class="fas fa-sparkles size-md" data-fa-transform="up-9 right-475 rotate-200"></i>
        <i class="fas fa-star-christmas size-md" data-fa-transform="down-8 right-375"></i>
        <i class="fas fa-space-station-moon size-lg" data-fa-transform="down-15 right-370"></i>
        <i class="fas fa-mountains size-2xl" data-fa-transform="down-34 right-145"></i>
        <i class="fas fa-mountains size-4xl" data-fa-transform="down-23 right-100"></i>
        <i class="fas fa-wagon-covered size-sm" data-fa-transform="down-75 right-350"></i>
      </div>

      <h3 v-if="this.currentGame.status == 'start'" class="event-title">Space Station</h3>
      <h3 v-else class="event-title">{{ currentGame.outpost_name }}</h3>

      <p v-if="this.currentGame.status == 'start'">
        <span v-if="showPlanetIntro == true">
          You are about to embark on a long journey to the planet of {{ currentGame.destination_planet }}.
          You will be traveling {{ distance }} parsecs.
          This journey will take approximately <span class="game-info">{{ this.travelDaysRemaining }} days</span>.
        </span>
        <span v-else>
          You are about to embark on a long journey into the unknown.
        </span>

        Before leaving you should buy equipment and supplies. You have <span class="game-info">{{ credits }}</span> credits, but you don't have to spend it all now.
      </p>
      <p v-else>
        You have <span class="game-info">{{ credits }}</span> credits.
        <span v-if="showPlanetIntro == true">There are approximately <span class="game-info">{{ this.travelDaysRemaining }} days</span> left in your journey.</span>
        This space station offers the following services:
      </p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices" v-if="this.currentGame.status == 'start'">

          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('supplies')">Purchase Supplies</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="startJourney()">Start Journey</a></li>
          </ul>
        </div>
        <div class="choices" v-else>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('refuel')">Refuel your ship</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('shiprepairs')">Ship Repairs</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('hire')">Hire Crew</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('supplies')">Purchase Supplies</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('sellsupplies')">Sell Supplies</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('infirmary')">Visit Infirmary</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('tradingpost')">Space Salvage</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('gambling')">Money Making Game</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoDisplay('cantina')">Cantina</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="continueJourney()">Continue Journey</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'
import { removeDeadCrew } from '../../utils/crew'
import { setNextOutpost } from '../../utils/game'
import settings from '../../utils/settings'

export default {
  name: "Outpost",

  mixins: [
    GameMixin
  ],

  created () {
    removeDeadCrew(this.currentShip)
  },

  computed: {
    showPlanetIntro () {
      return this.currentGame.difficulty != settings.GAME_DIFFICULTY_FOREVER
    },

    distance () {
      return this.currentGame.distance_to_planet.toLocaleString()
    },

    credits () {
      return this.currentShip.credits.toLocaleString()
    },
  },

  methods: {
    startJourney () {
      this.travel()
      this.changeDisplay("travel")
    },

    continueJourney () {
      setNextOutpost(this.currentGame)
      this.changeDisplay("travel")
    },

    gotoDisplay (location) {
      this.clearMessages()
      this.changeDisplay(location)
    },
  }
}
</script>
