<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="gauge" /> Adjust Speed
      </h3>

      <p>Increasing speed increases fuel consumption.</p>

      <div>Current Speed is {{ displaySpeed }}%</div>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <ul class="fa-ul">
          <li v-if="this.currentShip.weapon_status >= 20"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="changeSpeed(0.5)">Set speed to 25%</a></li>
          <li v-if="this.currentShip.weapon_status >= 20"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="changeSpeed(1.0)">Set speed to 50%</a></li>
          <li v-if="this.currentShip.weapon_status >= 20"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="changeSpeed(1.5)">Set speed to 75%</a></li>
          <li v-if="this.currentShip.weapon_status >= 20"><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="changeSpeed(2.0)">Set speed to 100%</a></li>
        </ul>

        <div><a href="#" @click.prevent="gotoRoom('bridge')">Return to Bridge</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateShip } from '../../../utils/ship'
import GameMixin from '../../../mixins/GameMixin'
import { addMessage, clearMessages } from '../../../utils/message'

export default {
  name: "AdjustSpeed",

  mixins: [
    GameMixin
  ],

  created () {
    this.clearMessages()
  },

  computed: {
    displaySpeed () {
      return (this.currentShip.speed * 50)
    }
  },

  methods: {
    gotoRoom (room) {
      clearMessages()
      this.changeDisplay(room)
    },

    changeSpeed (newSpeed) {
      clearMessages()
      updateShip(this.currentShip, { speed: newSpeed })
      addMessage(`Speed set to ${this.displaySpeed}%`, '')
      this.changeDisplay("bridge")
    }
  }
}
</script>
