<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="shop" />
        Space Salvage
      </h3>

      <p>Stacks of unlabeled supply crates fill this small warehouse.</p>

      <p>
        You have <span class="game-info">{{ credits }}</span> credits.
      </p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        <span v-html="message.text"></span>
      </div>

      <div id="output">
        <div class="choices">
          <h3 class="supply-heading">
            <font-awesome-icon icon="boxes-packing" /> Supply Crates <span style="color: var(--green)">(</span>You have
            <span class="supply-level">{{ currentShip.crates }}</span
            ><span style="color: var(--green)">)</span>
          </h3>
          <ul class="fa-ul">
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span
              ><a href="#" @click.prevent="purchase('crate', 1)">Purchase 1 supply crate [{{ getItemPrice('crate', 1) }} credits]</a>
            </li>
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span
              ><a href="#" @click.prevent="purchase('crate', 5)">Purchase 5 supply crates [{{ getItemPrice('crate', 5) }} credits]</a>
            </li>
            <li>
              <span class="fa-li"><i class="fas fa-square"></i></span
              ><a href="#" @click.prevent="purchase('crate', 10)">Purchase 10 supply crates [{{ getItemPrice('crate', 10) }} credits]</a>
            </li>
          </ul>
        </div>

        <div><a href="#" @click.prevent="returnToOutpost()">Return to Space Station</a></div>
      </div>
    </div>
  </div>
</template>

<script>
  import GameMixin from '../../mixins/GameMixin'
  import TradeMixin from '../../mixins/TradeMixin'
  import { itemPurchasePriceCalculation } from '../../utils/formulas'
  import { addMessage } from '../../utils/message'
  import { getRandomMerchantGreeting } from '../../utils/phrases'

  export default {
    name: 'TradingPost',

    mixins: [GameMixin, TradeMixin],

    computed: {
      credits() {
        return this.currentShip.credits.toLocaleString()
      }
    },

    created() {
      addMessage(getRandomMerchantGreeting('The warehouse worker'), '')
    },
    methods: {
      returnToOutpost() {
        this.clearMessages()
        this.changeDisplay('outpost')
      },

      purchase(item, quantity) {
        this.clearMessages()
        const cost = -this.getItemPrice(item, quantity)

        switch (item) {
          case 'repairkit':
            this.purchaseRepairKits(quantity, cost)
            break
          case 'medkit':
            this.purchaseMedKits(quantity, cost)
            break
          case 'food':
            this.purchaseFood(quantity, cost)
            break
          case 'crate':
            this.purchaseCrates(quantity, cost)
            break
        }
      },

      getItemPrice(item, quantity) {
        return itemPurchasePriceCalculation(item) * quantity
      }
    }
  }
</script>
