
import Message from '../store/models/Message'

export function clearMessages () {
  Message.deleteAll()
}


export function addMessage (message, style) {
  Message.insert({
    data: { text: message, style: style, type: 'default' }
  })
}

export function addStatusMessage (message, style, preventDuplicate = false) {
  if (preventDuplicate) {
    const oldMessages = Message.query().where('text', message).get()

    if (oldMessages.length === 0) {
      Message.insert({
        data: { text: message, style: style, type: 'status' }
      })
    }
  } else {
    Message.insert({
      data: { text: message, style: style, type: 'status' }
    })
  }
}

export function addCommMessage (message, style) {
  Message.insert({
    data: { text: message, style: style, type: 'comm' }
  })
}

export function getMessagesByType (messageType) {
  return Message.query().where((message) => {
    return message.type === messageType
  }).all()
}

export function clearMessagesByType (messageType) {
  getMessagesByType(messageType).forEach(message => {
    message.$delete()
  })
}
