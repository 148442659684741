<template>

  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="computer-classic" /> Medical Terminal
      </h3>

      <div class="row">
        <medical-menu />
      </div>

      <div class="row">
        <div class="column-5">
          <div id="output">
            <div class="choices">
              <div><a href="#" @click.prevent="returnToSickBay()">Return to Sick Bay</a></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import MedicalMenu from '../../../components/Terminal/MedicalMenu.vue'
import { getCrewMemberByPosition } from '../../../utils/crew'

export default {
  name: "SickBay",

  components: {
    MedicalMenu
  },

  mixins: [
    GameMixin
  ],

  computed: {
    crewDoctor () {
      return getCrewMemberByPosition('Doctor', this.currentShip)
    },
  },

  methods: {
    returnToSickBay () {
      this.changeDisplay("sickbay")
    }
  }
}
</script>
