<template>
  <div>
    <h3 class="terminal-heading">Alien Database</h3>

    <div v-if="this.selectedAlien == null">
      <ul class="fa-ul">
        <li v-for="alien in aliens" :key="alien.id">
          <span class="fa-li"><i class="fas fa-file"></i></span>
          <a href="#" @click.prevent="viewItem(alien)" class="terminal-menu">{{ alien.name }}</a>
        </li>
      </ul>

      <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Menu
      </a>
    </div>

    <div v-else class="column-12">
      <div class="row align-middle" style="padding-top: 10px;">
        <div>
          <font-awesome-icon class="fa-fw" :icon="this.selectedAlien.icon" style="font-size: 60px;" />
        </div>
        <div>
          <h2 class="alien-name">{{ selectedAlien.name }}</h2>
          <span class="alien-hostility">Hostility Level: <span style="color: var(--purple);">{{ selectedAlien.challenge_rating }}</span></span>
        </div>
      </div>
      <div class="alien-description" style="padding: 16px 0px 20px 16px;">{{ selectedAlien.description }}</div>

      <a href="#" @click.prevent="returnToList()" class="terminal-menu">
        <i class="fas fa-left"></i> Return to List
      </a>
    </div>
  </div>
</template>

<script>
  import { getEnemies } from '../../utils/enemy'

  export default {
    name: "AlienDatabase",

    data () {
      return {
        selectedAlien: null
      }
    },

    computed: {
      aliens () {
        return getEnemies()
      },

      hostilityLevel () {
        if (this.selectedAlien) {
          return this.selectedAlien.challenge_rating
        }
        return ''
      }
    },

    methods: {
      viewItem (alien) {
        this.selectedAlien = alien
      },

      returnToList () {
        this.selectedAlien = null
      }
    }
  }
</script>