<template>
  <div class="wrap-crew-icon">
    <font-awesome-icon
      v-if="crew.status == 'dead' && crew.species == 'robot'"
      class="crew-icon"
      :icon="['fad', 'user-robot-xmarks']"
      style="--fa-primary-color: white; --fa-secondary-color: gray"
    />

    <font-awesome-icon
      v-else-if="crew.status == 'dead'"
      class="fad crew-icon"
      icon="skull"
      style="--fa-primary-color: black; --fa-secondary-color: white"
    />
    <font-awesome-icon
      v-else-if="crew.status == 'rampage'"
      class="crew-icon fa-4x"
      :icon="['fad', crew.icon]"
      style="--fa-primary-color: var(--red)"
    />
    <font-awesome-icon v-else class="crew-icon" :icon="['fad', crew.icon]" />
  </div>
</template>

<script>
export default {
  name: "CrewIcon",

  props: {
    crew: Object,
  },
};
</script>
