<template>
  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="galaxy" />
         Cheats
      </h3>

      <p>If an opportunity arose for taking a small short-cut, you wouldn't be averse to taking it, would you?</p>

      <div v-for="message in this.currentMessages" :key="message.id" class="console-message" :class="message.style">
        {{message.text}}
      </div>

      <div id="output">
        <div class="choices">

          <h3 class="supply-heading">Crew Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="createMadScientist()">It's a mad mad world</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="inflictDisease()">Inflict Disease on random crew</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="panic()">Inflict Deep Space Panic on random crew</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="partyTime()">Send Crew member on Spring Break</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="moraleLoss()">Make Crew member sad</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="moraleGain()">Make Crew member happy</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="cloneCrew()">Clone Crew member</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="robotCrew()">Robot Overlords</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="murderBot()">Transform Crew member into a murderbot</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="aiCrew()">Artifical Intelligence </a></li>
          </ul>

          <h3 class="supply-heading">Ship and Resources</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="addCreditsCheat(5000)">Show me the money!</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="addCrystals()">Give me some Crystals</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="addArtifacts()">Give me some Alien artifacts</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('cheatcodes')">Rub the lamp and release the Djinn</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('secretroom')">Secret Room</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="damageShip()">Damage Ship</a></li>
          </ul>

          <h3 class="supply-heading">Game Options</h3>
          <ul class="fa-ul">
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="instantGameover(true, 'Cheaters never win... well almost.')">Winner Winner! Chicken Dinner!</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="instantGameover(false, 'You shouldn\'t have done that...')">Game Over, man! Game Over!</a></li>
            <li><span class="fa-li"><i class="fas fa-square"></i></span><a href="#" @click.prevent="gotoRoom('outpost')">Secret Outpost</a></li>
          </ul>

          <div><a href="#" @click.prevent="gotoRoom('shipmenu')">Return to Ship Menu</a></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GameMixin from '../mixins/GameMixin'
import { unlockAchievement } from '../utils/achievements'
import { getCrewMemberByPosition, updateCrew, getRandomCrewMember, changeToMadScientist, setCrewMurderousRampage, transformCrewIntoRobot, getLivingCrewMembers,
  transformCrewIntoAI, setCrewPanic, addCrew, cloneCrewMember } from '../utils/crew'
import { endGame } from '../utils/game'
import { addMessage } from '../utils/message'
import settings from '../utils/settings'
import { updateShip } from '../utils/ship'

export default {
  name: "Cheats",

  mixins: [
    GameMixin
  ],

  computed: {

  },

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    },

    addCreditsCheat (credits) {
      this.clearMessages()
      updateShip(this.currentShip, { credits: this.currentShip.credits + credits})
      addMessage(`You receive ${credits} credits. Cheater.`)
    },

    addCrystals () {
      this.clearMessages()
      updateShip(this.currentShip, { erbium_crystals: this.currentShip.erbium_crystals + 5})
      addMessage(`You receive 5 erbium crystals. Cheater.`)
    },

    addArtifacts () {
      this.clearMessages()
      updateShip(this.currentShip, { alien_artifacts: this.currentShip.alien_artifacts + 5})
      addMessage(`You receive 5 alien artifacts. Cheater.`)
    },

    instantGameover (win, message) {
      unlockAchievement('CHEATER')
      endGame(win, message)
    },

    createMadScientist () {
      this.clearMessages()
      const scientist = getCrewMemberByPosition(settings.POSITION_SCIENTIST, this.currentShip)

      changeToMadScientist(scientist)
    },

    inflictDisease () {
      this.clearMessages()
      const crew = getRandomCrewMember(this.currentShip)
      addMessage(`${crew.name} has ${this.setRandomCrewDisease(crew)}!`)
    },

    panic () {
      this.clearMessages()
      const crew = getRandomCrewMember(this.currentShip)
      setCrewPanic(crew)
      addMessage(`${crew.name} has Deep Space Panic!`)
    },

    damageShip () {
      this.clearMessages()
      const hull = this.currentShip.hull_status - 10
      const engines = this.currentShip.engine_status - 10

      updateShip(this.currentShip, {
        airlock_status: settings.AIRLOCK_STATUS_DAMAGED,
        hull_status: hull,
        engine_status: engines
      })
      addMessage(`Your ship has been damaged!`)
    },

    moraleLoss () {
      this.clearMessages()
      const crew = getRandomCrewMember(this.currentShip)
      updateCrew(crew, { morale: -5 })
      addMessage(`You made ${crew.name} very sad...`)
    },

    moraleGain () {
      this.clearMessages()
      const crew = getRandomCrewMember(this.currentShip)
      updateCrew(crew, { morale: 5 })
      addMessage(`You made ${crew.name} very happy!`)
    },

    partyTime () {
      this.clearMessages()
      const crew = getRandomCrewMember(this.currentShip)
      updateCrew(crew, { drunkenness: 60 })
      addMessage(`${crew.name} has had a few too many!`)
    },

    robotCrew () {
      const crewMembers = getLivingCrewMembers(0, this.currentShip)
      crewMembers.forEach(crew => transformCrewIntoRobot(crew))
    },

    murderBot () {
      const crew = getRandomCrewMember(this.currentShip)
      transformCrewIntoRobot(crew)
      updateCrew(crew, { morale: -5 })
      setCrewMurderousRampage(crew)
      addMessage(`${crew.name} became a murder bot!`)
    },

    aiCrew () {
      const crew = getRandomCrewMember(this.currentShip)
      transformCrewIntoAI(crew)
      addMessage(`${crew.name} has entered the electric dimension!`)
    },

    cloneCrew () {
      const crew = getRandomCrewMember(this.currentShip)
      addCrew(cloneCrewMember(crew, this.currentShip.id))
    }
  }
}
</script>
